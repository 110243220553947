import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { UncontrolledPopover, PopoverBody } from 'reactstrap';

import deezerIcon from "../assets/images/deezer_icon.png";
import spotifyIcon from "../assets/images/spotify_icon.png";
import amazonMusicIcon from "../assets/images/amazonmusic_icon.png";
import appleMusicIcon from "../assets/images/applemusic_icon.png";

import './releaseStoreUrls.css';

const getStoreIcon = (storeName) =>
{
  switch(storeName) {
      case 'deezer':
        return deezerIcon;
      case 'spotify':
        return spotifyIcon;
      case 'amazon-music':
        return amazonMusicIcon;
      case 'apple-music':
        return appleMusicIcon;
      default:
        return '';
    }
}

const getStoreUrlPrefix = (storeName) =>
{
  switch(storeName) {
      case 'deezer':
        return 'https://www.deezer.com/en/artist/';
      case 'spotify':
        return 'https://open.spotify.com/artist/';
      case 'amazon-music':
        return 'https://music.amazon.de/artists/';
      case 'apple-music':
        return 'https://music.apple.com/en/artist/';
      default:
        return '';
    }
}

const ArtistStoreLink = ({
  id,
  store_name,
  store_id,
}) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const storeIcon = getStoreIcon(store_name);
  const storeUrlPrefix = getStoreUrlPrefix(store_name);
  const storeUrl = `${storeUrlPrefix}${store_id}`;

  const rowId = "artist-store-link-row-" + id.toString();

  return (
    <div>
        <img src={storeIcon} width="35px" height="35px" alt="store-icon" className={"fa fa-link fa-2x store-link-icon"} id={rowId} aria-hidden="true" />
        <UncontrolledPopover trigger="legacy" placement="bottom" target={rowId}>
            <PopoverBody>
            <table>
                <tbody>
                {
                    <tr>
                        <td className="p-2">
                        <i className="fa fa-clone fa-lg store-link-icon"
                            style={{color: linkCopied? "#000a60": ""}}
                            aria-hidden="true"
                            onClick={() => {
                                navigator.clipboard.writeText(storeUrl);
                                setLinkCopied(true);
                                setTimeout(() => {
                                    setLinkCopied(false);
                                }, 1000);
                            }}>
                        </i>
                        </td>
                        <td className="p-2">
                        <Link to={{pathname: storeUrl}} target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-external-link-alt fa-lg store-link-icon" style={{color: '#4f5467'}} aria-hidden="true"></i>
                        </Link>
                        </td>
                    </tr>
                }
                </tbody>
            </table>
            </PopoverBody>
        </UncontrolledPopover>
    </div>
  );
};

export default ArtistStoreLink;