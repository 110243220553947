import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import axios from "axios";
import { getCookie } from "../jwt/_helpers/cookie";
import LoaderInner from "./LoaderInner";

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const SubscriptionUpgradeModal = ({
  openModal,
  setToggleModal,
  values,
  title,
  apiURL,
  newTier,
}) => {
  const tokenAPI = getCookie("token");

  const updateData = {
    "action": "upgrade",
    "upgraded_tier": newTier.id,
  }

  const [inProgress, setInProgress] = useState(false);

  const handleUpdate = () => {
    setInProgress(true);
    const options = {
      method: "POST",
      data: updateData,
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${tokenAPI}`,
        "x-api-key": X_API_KEY,
        "Content-Type": "application/json"
      }
    };

    axios(`${API_URL}${apiURL}/`, options)
      .then(res => {
        setToggleModal(false);
        setInProgress(false);
        window.location.assign('/subscription/upgradeSuccessfull');
      })
      .catch(err => {
        setInProgress(false);
        window.location.assign('/subscription/upgradeFailure');
      });
  };
  return (
    <Modal isOpen={openModal} centered={true}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
          <LoaderInner show={inProgress} />
          Are you sure that you want to upgrade your subscription plan to <b>{newTier.name}</b>.<br/><br/>
          <b>Note: The billing period for subscription will remain unchanged and you will only be invoiced for the amount excluding what you have already paid for existing plan.</b>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => setToggleModal(false)}>
          Cancel
        </Button>
        <Button color="success" onClick={() => handleUpdate()} disabled={inProgress}>
            Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SubscriptionUpgradeModal;
