import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Alert,
  Button,
  FormText,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import ModalConfirm from "../components/modalConfirm";

const Schema = Yup.object().shape({
  password: Yup.string().required("Your old password is required"),
  newpassword: Yup.string()
    .min(10, "Your password should be at least 10 characters long")
    .required("New password is required")
    .test(
      "different-from-old",
      "New password must be different from the old password",
      function (newpassword) {
        return newpassword !== this.parent.password;
      }
    ),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('newpassword'), null], 'New password must match')
});

const PasswordResetModal = ({ isPasswordResetModalOpen, setIsPasswordResetModalOpen, currentUser }) => {

    const [success, setSuccess] = useState(false);
    const [errorPut, setErrorPut] = useState(false);
  
    const [openModal, setToggleModal] = useState(false);
    const [dataModal, setDataModal] = useState(null);

    const toggleModals = () => {
        setIsPasswordResetModalOpen(!isPasswordResetModalOpen);
        setToggleModal(!openModal);
    };

  return (
    <>
      <Modal isOpen={isPasswordResetModalOpen} backdrop="static" keyboard={false}>
        <ModalHeader>
          Change Your Password
        </ModalHeader>
        <ModalBody>
            <Formik
                initialValues={{password:'', newpassword: '', passwordConfirmation: '',}}
                validationSchema={Schema}
                onSubmit={({password, newpassword, passwordConfirmation},{ setStatus, setSubmitting }) => {
                    setStatus();
                    setToggleModal(true);
                    setDataModal({
                        old_password: password,
                        new_password: newpassword
                    });
                }}
                render={({ errors, status, touched, isSubmitting, setFieldValue }) => {
                return (
                    <Form>
                        <FormGroup>
                            <Label>Current password</Label>
                            <Field
                            name="password"
                            type="password"
                            className={
                                "form-control" +
                                (errors.password && touched.password ? " is-invalid" : "")
                            }
                            />
                            <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>New password</Label>
                            <Field
                            name="newpassword"
                            type="password"
                            className={
                                "form-control" +
                                (errors.newpassword && touched.newpassword
                                ? " is-invalid"
                                : "")
                            }
                            />
                            <ErrorMessage
                            name="newpassword"
                            component="div"
                            className="invalid-feedback"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Repeat new password</Label>
                            <Field
                            name="passwordConfirmation"
                            type="password"
                            className={
                                "form-control" +
                                (errors.passwordConfirmation && touched.passwordConfirmation
                                ? " is-invalid"
                                : "")
                            }
                            />
                            <ErrorMessage
                                name="passwordConfirmation"
                                component="div"
                                className="invalid-feedback"
                            />
                        </FormGroup>
                        <Button type="submit" color="success" className="float-right">
                            Submit
                        </Button>
                    </Form>
                );
                }}
            />
            <div style={{paddingTop: 20}}>
            {success && <Alert color="success">Your password has been changed!</Alert>}
            {errorPut && <Alert color="danger">Something went wrong! Please refresh page and try again!</Alert>}
            </div>
            {openModal && (
                <ModalConfirm
                    openModal={openModal}
                    setToggleModal={toggleModals}
                    dataModal={dataModal}
                    title={`Are you sure you would like change your password?`}
                    setSuccess={setSuccess}
                    setError={setErrorPut}
                    method={"POST"}
                    body={`Please confirm`}
                    apiURL={`users/${currentUser.id}/change-password`}
                    setRedirect={false}
                />
            )}
        </ModalBody>
        <ModalFooter>
            <FormText color="muted">
                <b>Note: </b><br/>
                It's been a while since you last updated your password. For your security, please reset your password.
            </FormText>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PasswordResetModal;
