import React, { useState, useEffect, useRef } from "react";
import {
    Form,
    FormGroup,
    Label,
    Button,
    Input,
    Row,
    Col,
    FormText
} from "reactstrap";
import Select from "react-select"
import Countries from "../../utils/countriesExtended.json";
import { optStepOne } from "./userDataFormOpt";
import formValidation from "../../validations/es6/core/Core";

const StepOne = ({formData, setFormData, jumpToStep }) => {

    const fvRef = useRef();

    const [countrySelected, setCountrySelected] = useState("");
    const [countryFieldTouched, setCountryFieldTouched] = useState(false);
    const [isCountryFieldValid, setIsCountryFieldValid] = useState(false);

    const revalidateField = (name) => {
        if(fvRef.current) {
            let fv = fvRef.current;
            clearValidationErrors("personalInfoForm", name);
            fv.revalidateField(name);
        }
    }

    const clearValidationErrors = (formName, validatorName) => {
        let formID = document.getElementById(formName);
        if(formID) {
        const messages = [].slice.call(formID.querySelectorAll('[data-field="' + validatorName + '"][data-validator]'));
        messages.forEach((messageEle) => {
            messageEle.style.display = 'none';
        });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
    
      const handleCountryChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            personalCountry: e
        }));
        revalidateField("personalCountry");
      }


      useEffect(() => {
        let formID = document.getElementById("personalInfoForm");
    
        let fv = formValidation(formID, optStepOne);
        fvRef.current = fv;
        
        fv.on("core.form.validating", function(e) {
            if(!countryFieldTouched) {
                setCountryFieldTouched(true)
            }
          })
          .on("core.element.validated", function(e) {
            const item = e.field;
    
            if(item === 'personalCountry') {
              if(!e.valid) {
                setIsCountryFieldValid(false)
              } else {
                setIsCountryFieldValid(true)
              }
            }
        
            if (e.valid) {
                const messages = [].slice.call(formID.querySelectorAll('[data-field="' + e.field + '"][data-validator]'));
                messages.forEach((messageEle) => {
                  messageEle.style.display = 'none';
                });
                return;
              }
            }
          )
        .on('core.form.valid', e => {
            jumpToStep(2)
        });
      }, [formData])
   
      useEffect(() => {
        if(formData.personalCountry === null) {
            setCountrySelected('');
        } else {
            setCountrySelected('yes');
        }

        if(countryFieldTouched) {
            revalidateField("personalCountry")
        }
      }, [formData])
    
  
    return (
        <div className="step step1 mt-5 ">
            <Row className="justify-content-md-center">
                <Col lg="6">
                    <Form id="personalInfoForm" className="form-horizontal mt-2">
                        <FormGroup className="content form-block-holder">
                            <Label className="control-label"><b>First Name</b></Label>
                            <Input type="text" name="firstName" value={formData.firstName} onChange={handleChange} onFocus={() => {revalidateField("firstName")}} />
                            <FormText color="muted">
                                If registering as an LLC or other business entity, this field will be used for the <b>CEO's First Name</b>.
                            </FormText>
                        </FormGroup>

                        <FormGroup className="content form-block-holder">
                            <Label className="control-label"><b>Last Name</b></Label>
                            <Input type="text" name="lastName" value={formData.lastName} onChange={handleChange} onFocus={() => {revalidateField("lastName")}} />
                            <FormText color="muted">
                                If registering as an LLC or other business entity, this field will be used for the <b>CEO's Last Name</b>.
                            </FormText>
                        </FormGroup>

                        <FormGroup className="content form-block-holder">
                            <Label className="control-label"><b>Street and Number</b></Label>
                            <Input type="text" name="personalStreet" value={formData.personalStreet} onChange={handleChange} onFocus={() => {revalidateField("personalStreet")}} />
                        </FormGroup>

                        <FormGroup className="content form-block-holder">
                            <Label className="control-label"><b>ZIP Code</b></Label>
                            <Input type="text" name="personalZip" value={formData.personalZip} onChange={handleChange} onFocus={() => {revalidateField("personalZip")}} />
                        </FormGroup>

                        <FormGroup className="content form-block-holder">
                            <Label className="control-label"><b>City</b></Label>
                            <Input type="text" name="personalCity" value={formData.personalCity} onChange={handleChange} onFocus={() => {revalidateField("personalCity")}} />
                        </FormGroup>

                        <FormGroup className="content form-block-holder">
                            <Label className="control-label"><b>Country</b></Label>
                            <Select
                                components={{ IndicatorSeparator:() => null }}
                                options={Countries}
                                id="country"
                                name="country"
                                placeholder="Select Country"
                                styles={{
                                menu: styles => ({ ...styles, zIndex: 10 }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: countryFieldTouched ? (isCountryFieldValid ? "#2dce89": "#f62d51"): baseStyles.borderColor,
                                    boxShadow: state.isFocused || state.isHovered 
                                    ? (isCountryFieldValid ? "0 0 0 0.2rem rgba(45, 206, 137, 0.25)" : "0 0 0 0.2rem rgba(246, 45, 81, 0.25)") 
                                    : "0 0 0 1px #e9ecef",
                                    borderRadius: "2px",
                                    }),
                                }}
                                onFocus={e => {
                                    setCountryFieldTouched(true)
                                    revalidateField("personalCountry");
                                }}
                                onChange={handleCountryChange}
                                value={formData.personalCountry}
                            />
                            <input className="hiddenInput" type="text" id="personalCountry" name="personalCountry" value={countrySelected} readOnly/>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
            <Button
                className="btn btn-next btn-primary pull-right"
                style={{color: "#ffffff", backgroundColor: "#000a60"}}
                type="submit"
                onClick={() => {
                    if(fvRef.current) {
                        fvRef.current.validate();
                    }
                }}
            >
                Next
            </Button>
        </div>
    );
};

export default StepOne;