import React from "react";
import { Spinner } from "reactstrap";


const PreviewLoader = ({ show = false }) => {
  return (
    <div className="loaderPreview" style={{display: show ? 'block': 'none'}}>
      <Spinner size="xl" color="#ffffff" className="releaseSpinner"/>
    </div>
  );
};

export default PreviewLoader;