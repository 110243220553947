import React, { useState } from 'react';
import axios from "axios";
import { Container, Card, CardText, CardBody, Alert, CardTitle, Button, Input, Form, FormGroup, Label } from "reactstrap";
import { useLocation, Redirect } from "react-router-dom";

const style = {
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}

const API_URL = process.env.REACT_APP_API_URL_BASE;

const ForgotPassword_step2 = props => {
  const location = useLocation();
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorText, setErrorText] = useState([]);

  const validatePassword = (password) => {
    const errors = [];

    if (password.length < 10) {
      errors.push('Password is too short. It must be at least 10 characters.');
    }

    if (!/\d/.test(password)) {
      errors.push('Password must contain at least one numeric character.');
    }

    if (!/[a-zA-Z]/.test(password)) {
      errors.push('Password must contain at least one letter.');
    }

    return errors;
  }

  const submit = (event) => {
    event.preventDefault();
    setError(false);
    setErrorText([]);

    let errors = validatePassword(pass);

    if (pass.length === 0) {
      errors.push('Password is required.');
    }

    if (pass !== confirmPass) {
      errors.push('Passwords do not match.');
    }

    if (errors.length > 0) {
      setError(true);
      setErrorText(errors);
      return;
    }

    const path = location.pathname.split('/confirm/')[1];
    const resetToken = path.slice(0, -1);

    const payload = {
      "password": pass,
      "token": resetToken
    };

    axios.post(API_URL + 'password-reset/confirm/', payload)
      .then(response => {
        if (response.status === 200) {
          setSuccess(true);
        } else {
          setErrorText([response.statusText]);
          setError(true);
        }
      })
      .catch(error => {
        setErrorText([error.response.statusText]);
        setError(true);
      });
  }

  if (success) {
    setTimeout(() => {
      return <Redirect to="/authentication/login" />
    }, 3000);
  }

  return (
    <Container fluid={true} style={style}>
      <Card>
        <CardBody>
          <CardTitle>Set new password</CardTitle>
          <CardText>Your password should be at least 10 characters long and it should consist of a combination of letters and numbers.</CardText>
          <Form onSubmit={submit}>
            <FormGroup>
              <Label for="passwordNew">New password</Label>
              <Input type="password" name="passwordNew" id="passwordNew" placeholder="Your new password..." value={pass} onChange={(e) => setPass(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label for="passwordNew2">Confirm new password</Label>
              <Input type="password" name="passwordNew2" id="passwordNew2" placeholder="Repeat your new password..." value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)} />
            </FormGroup>
            <Button color="primary" type="submit">Submit</Button>
          </Form>
          {error && (
            <Alert style={{ margin: '30px 0 0 0' }} color="danger">
              <ul>
                {errorText.map((msg, idx) => (
                  <li key={idx}>{msg}</li>
                ))}
              </ul>
            </Alert>
          )}
        </CardBody>
        <div style={{ padding: "0 20px" }}>
          {success && (
            <Alert color="success">
              Your password has been updated! Please <b>proceed to the <a href="/">start page</a></b> and log in with your new credentials.
            </Alert>
          )}
        </div>
      </Card>
    </Container>
  );
};

export default ForgotPassword_step2;