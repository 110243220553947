import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import reducers from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension'

const logger = createLogger();

function configureStore(initialState) {

    const middlewares = [];

    const store = createStore(
        reducers,
        initialState,
        composeWithDevTools(
          applyMiddleware(...middlewares)
        )
    );

    return store;
}

export default configureStore();
