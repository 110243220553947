import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UncontrolledPopover, PopoverBody, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, FormGroup, Input, CustomInput, FormText } from 'reactstrap';
import { getCookie } from '../jwt/_helpers/cookie';
import LoaderInner from '../components/LoaderInner';
import BootstrapTable from "react-bootstrap-table-next";
import { releaseLicenseUpload } from "../views/releases/helperFunctions";
import './acrAlert.css';

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const ACRAlert = ({
  id,
  name,
  has_acr_alert,
  acr_alert,
  release_user_declaration,
  action,
}) => {

  const tokenAPI = getCookie("token");
  const [modal, setModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [declaration, setDeclaration] = useState(1);
  const [declarationSubmitted, setDeclarationSubmitted] = useState(false);
  const [releaseLicense, setReleaseLicense] = useState(null);
  const [bellColor, setBellColor] = useState('red');
  const [messageColor, setMessageColor] = useState('danger');

  const [fileUploadDone, fileUploadDoneSET] = useState(false);
  const [fileUploadInProgress, fileUploadInProgressSET] = useState(false);

  const { setDeclarationSignError, setDeclarationSignSuccess, forceUpdate, setForceUpdate } = action;

  useEffect(() => {
    if (release_user_declaration) {
      if(acr_alert.user_declaration === 1) {
        setBellColor('#ff0b4a');
        setMessageColor('danger');
      } else {
        if (release_user_declaration.status === "approved") {
          setBellColor('green');
          setMessageColor('success');
        }
        if (release_user_declaration.status === "rejected") {
          setBellColor('#ff0b4a');
          setMessageColor('danger');
        }
        if (release_user_declaration.status === "pending") {
          setBellColor('#f0c231');
          setMessageColor('warning');
        }
      }
    } else {
      if(has_acr_alert) {
        setBellColor('#ff0b4a');
        setMessageColor('danger');
      } else {
        setBellColor('#4f5467');
        setMessageColor('warning');
      }
    }
  }, [has_acr_alert, release_user_declaration]);

  const toggleModal = () => setModal(!modal);
  const toggleConfirmationModal = () => setConfirmationModal(!confirmationModal);

  const handleSubmit = async () => {
    if (declaration === 1) {
      toggleConfirmationModal();
      return;
    }
    await submitDeclaration("accepted");
  };

  const submitDeclaration = async (submissionType) => {
    setSubmissionInProgress(true);
    try {
      const response = await axios.post(
        `${API_URL}release-acr-scan/`,
        {
          id: acr_alert.id,
          user_declaration: declaration,
          release_license: releaseLicense,
        },
        {
          headers: {
            "Authorization": `Bearer ${tokenAPI}`,
            "x-api-key": X_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        setDeclarationSubmitted(true);
        setDeclarationSignSuccess(true);
        if(submissionType === "accepted") {
          toggleModal();
        }
        if (submissionType === "rejected") {
          toggleConfirmationModal();
        }
        setTimeout(() => {
          setForceUpdate(forceUpdate + 1);
        }, (3000));
      } else {
        setDeclarationSignError(true);
        console.log("Error occurred while submitting user declaration");
      }
    } catch (error) {
      setDeclarationSignError(true);
      console.log("Error occurred while submitting user declaration, Error: ", error);
    } finally {
      setSubmissionInProgress(false);
    }
  };

  const rowId = "row-acr-alert-" + id.toString();
  const showStatusNote = has_acr_alert;

  const statusNoteText = (acr_alert && acr_alert.user_declaration === 1)? 
    <>
      You have cancelled the distribution of the release. If you change your mind, you’d need to make changes to the audio file and re-submit for distribution.
    </>
  : release_user_declaration ? (
    <>
      {release_user_declaration.admin_message}
    </>
  ) : (
    <>
      Release {name} has a Copyright Alert. Kindly sign the{' '}
      <span
        onClick={() => {
          if(!declarationSubmitted) {
            toggleModal()
          }
        }}
        style={{ textDecoration: 'underline', cursor: 'pointer', color: bellColor }}>
        Declaration
      </span>{' '}
      to proceed further.
    </>
  );

  return (
    <div>
      <i 
        className={"fas fa-bell fa-2x acr-note-icon" + (showStatusNote ? " acr-note-avl-icon" : "")}
        id={rowId} 
        aria-hidden="true" 
        disabled={!showStatusNote}
        style={{ color: bellColor }}
      ></i>
      <UncontrolledPopover trigger="legacy" id="qc-popover" placement="bottom" target={rowId} disabled={!showStatusNote}>
        <PopoverBody style={{ padding: "0px" }}>
          { showStatusNote &&
            <Alert 
              color={messageColor}
              style={{ fontSize: "14px", fontWeight: "bolder", margin: "0px", cursor: "pointer" }}
              fade={true} 
              isOpen={showStatusNote}
            >
              <b>{statusNoteText}</b>
            </Alert>
          }
        </PopoverBody>
      </UncontrolledPopover>

      <Modal isOpen={modal} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>Release Copyright Declaration</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            There is a Copyright Alert for this Release. The flagged tracks/content is as follows:
          </div>

          {acr_alert && acr_alert.scanned_tracks && (
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <BootstrapTable
                keyField="id"
                data={acr_alert.scanned_tracks.flatMap((track) =>
                  track.alert ? [
                    ...track.music_results.map((musicResult, index) => ({
                      id: `${track.id}-music-${index}`,
                      track_name: track.track_name,
                      type: "Music",
                      title: musicResult.result.title,
                      artists: musicResult.result.artists.map(artist => artist.name).join(', '),
                      album: musicResult.result.album.name,
                      label: musicResult.result.label,
                      release_date: musicResult.result.release_date
                    })),
                    ...track.cover_results.map((coverSongResult, index) => ({
                      id: `${track.id}-cover-${index}`,
                      track_name: track.track_name,
                      type: "Cover",
                      title: coverSongResult.result.title,
                      artists: coverSongResult.result.artists.map(artist => artist.name).join(', '),
                      album: coverSongResult.result.album.name,
                      label: coverSongResult.result.label,
                      release_date: coverSongResult.result.release_date
                    }))
                  ] : []
                )}
                columns={[
                  {
                    dataField: "track_name",
                    text: "Track Name"
                  },
                  {
                    dataField: "type",
                    text: "Type"
                  },
                  {
                    dataField: "title",
                    text: "Title"
                  },
                  {
                    dataField: "artists",
                    text: "Artists"
                  },
                  {
                    dataField: "album",
                    text: "Album"
                  },
                  {
                    dataField: "label",
                    text: "Label"
                  },
                  {
                    dataField: "release_date",
                    text: "Release Date"
                  }
                ]}
                bordered={false}
              />
            </div>
          )}

          <div className="mt-4 mb-4">
            Kindly sign the declaration in order to proceed further.
          </div>
  
          <FormGroup check className="">
            <Label check>
              <Input type="radio" id="cancel" name="declaration_cancel" value={1} onChange={() => {setDeclaration(1)}} checked={declaration===1} />
              <span className="ml-2">I want to cancel this release.</span>
            </Label>
          </FormGroup>
          <FormGroup check className="mt-3">
            <Label check>
              <Input type="radio" id="approve" name="declaration_approve" value={2} onChange={() => {setDeclaration(2)}} checked={declaration===2} />
              <span className="ml-2">I want to approve this release and understand and fully accept the legal ramifications of this action.</span>
            </Label>
          </FormGroup>
          <FormGroup check className="mt-3">
            <Label check>
              <Input type="radio" id="approve_all" name="declaration_approve_all" value={3} onChange={() => {setDeclaration(3)}} checked={declaration===3} />
              <span className="ml-2">I want to approve this release to ALL stores; found results are 100% identical with our artist and the content belongs to our artist. We understand and fully accept the legal ramifications of this action.</span>
            </Label>
          </FormGroup>
          <FormGroup check className="mt-3">
            <Label check>
              <Input type="radio" id="approve_all_license" name="declaration_approve_all_license" value={4} onChange={() => {setDeclaration(4)}} checked={declaration===4} />
              <span className="ml-2">I want to approve this release to ALL stores; this is a COVER release for which I have purchased LICENSE.</span>
            </Label>
          </FormGroup>
          {declaration === 4 && (
            <FormGroup className="mt-3">
              <Label for="releaseLicenseFile"><b>Release License</b></Label>
              <CustomInput
                type="file"
                id="releaseLicense" 
                name="releaseLicense" 
                accept=".pdf"
                required={declaration === 4}
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file && !fileUploadDone) {
                    fileUploadInProgressSET(true);
                    releaseLicenseUpload(file, tokenAPI)
                    .then(releaseLicenseURL => setReleaseLicense(releaseLicenseURL))
                    .finally(() => {
                      fileUploadInProgressSET(false);
                      fileUploadDoneSET(true);
                    })
                  }
                }}
              />
              <div className="releaseLicenseUploadContainer">
                  <div className="releaseLicenseUpload" id={`uploadProgress_rl`}></div>
                  <div className="releaseLicenseUploadProgressDiv">
                  <span id={`uploadProgress_text_rl`}></span>
                  </div>
              </div>
              <FormText color="muted">
                Please upload the Release License
              </FormText>
          </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit} disabled={submissionInProgress || fileUploadInProgress || (declaration===4 && !fileUploadDone) || declarationSubmitted}>Submit</Button>
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={confirmationModal} toggle={toggleConfirmationModal}>
        <ModalHeader toggle={toggleConfirmationModal}>Confirm Rejection</ModalHeader>
        <ModalBody>
          By rejecting this release, your release will go OFFLINE and you’d have to adjust it before re-submitting it for distribution again. Are you sure you want to do this?
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() => {
              toggleModal()
              submitDeclaration("rejected")
            }}
            disabled={submissionInProgress || declarationSubmitted}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={toggleConfirmationModal}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      <LoaderInner show={submissionInProgress} />
    </div>
  );
};

export default ACRAlert;