import React, { useState, useEffect, useContext } from 'react';
import {
    Row,
    Col,
    Button,
    Table,
    Alert
} from 'reactstrap';
import axios from 'axios';
import { getCookie } from "../../jwt/_helpers";
import { StateContext } from '../../utils/context';

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const StepFive = ({ jumpToStep }) => {
    const token = getCookie().token;
    const { currentUser } = useContext(StateContext);
    const [verificationUrl, setVerificationUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const initiateVerification = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_URL}users/initiate-identity-verification/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "x-api-key": X_API_KEY,
                }
            });
            setVerificationUrl(response.data.url);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Verification initiation failed:', error);
        }
    };


    useEffect(() => {
        if(currentUser && currentUser.identity_verification_attempts<2 && verificationUrl === "") {
            initiateVerification();
        }
      }, [currentUser, verificationUrl])

    return (
        <div className="step step5 mt-5">
            {
                (currentUser && !currentUser.identity_verified) &&
                <Row className="justify-content-md-center">
                    <Col lg="6">
                        <center>
                            <br/><br/>
                            Please click on the link below to start Identity Verification.
                            <br/><br/>
                            <Button
                                className="btn btn-primary"
                                style={{color: "#ffffff", backgroundColor: "#000a60"}}
                                disabled={isLoading || verificationUrl === "" || currentUser.identity_verification_attempts>=2}
                                onClick={() => {
                                    window.location.href = verificationUrl;
                                }}
                            >
                                {isLoading ? 'Loading...' : 'Verify Identity'}
                            </Button>
                        </center>
                    </Col>
                </Row>
            }

            {
                (currentUser && currentUser.identity_info_submitted) &&
                <Row className="justify-content-md-center">
                    <Col lg="6">
                        <div className="results-container p-4">
                            <h3 className="text-center mb-4">Identity Verification Results</h3>
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Status</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Selfie Verification</td>
                                        <td className={currentUser.identity_verification_results.selfie_verified ? 'text-success' : 'text-danger'}>
                                            {currentUser.identity_verification_results.selfie_verified ? 'Verified' : 'Not Verified'}
                                        </td>
                                        <td>
                                            {currentUser.identity_verification_results.selfie_error_reason || "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Document Verification</td>
                                        <td className={currentUser.identity_verification_results.document_verified ? 'text-success' : 'text-danger'}>
                                            {currentUser.identity_verification_results.document_verified ? 'Verified' : 'Not Verified'}
                                        </td>
                                        <td>
                                            {currentUser.identity_verification_results.document_error_reason || "N/A"}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>

                            {
                                (currentUser && currentUser.identity_info_submitted && currentUser.identity_verified) &&
                                <Alert color="success">
                                    Your identity information has been verified, our team will review the identity information and give the final approval in 2-3 business days.
                                </Alert>
                            }

                            {
                                (currentUser && currentUser.identity_info_submitted && currentUser.identity_verification_attempts<2 && !currentUser.identity_verified) &&
                                <Alert color="danger">
                                    Your identity information could not be verified, kindly submit the information again! You only have <b>{currentUser.identity_verification_attempts}</b> attempt remaining to verify your identity.
                                </Alert>
                            }

                            {
                                (currentUser && !currentUser.identity_verified && currentUser.identity_verification_attempts>=2) &&
                                <Alert color="danger">
                                    You have exhausted the maximum number of attempts for Identity Verification, please contact support for further information.
                                </Alert>
                            }
                        </div>
                    </Col>
                </Row>
            }
        </div>
    );
};

export default StepFive;