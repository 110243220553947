import React, { useState, useEffect } from 'react';
import TenantContext from './tenantContext';
import { useBasicFetch } from './fetchHookNoToken';
import LoaderInner from '../components/LoaderInner';

const TenantProvider = ({ children }) => {
  const [tenant, setTenant] = useState({});    
  const [tenantInfo] = useBasicFetch("GET", "tenants/");
  const [isLoading, setIsLoading] = useState(true);
  const tenantValue = { tenant, setTenant };

    useEffect(() => {
        if (tenantInfo.count > 0) {
            const currentTenant = tenantInfo.results[0];
            setTenant(currentTenant);
        }
        setIsLoading(false);
    }, [tenantInfo]);

  if (isLoading) {
    return <LoaderInner show={isLoading} />;
  }

  return (
    <TenantContext.Provider value={tenantValue}>
      {children}
    </TenantContext.Provider>
  );
};

export default TenantProvider;