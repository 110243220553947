import React from 'react';

const TriangleIcon = ({ fillColor = "#000a60", onClick, className }) => (
  <svg
    viewBox="0 0 767.5 1024"
    height="1024"
    width="767.5"
    onClick={onClick}
    className={className}
    xmlSpace="preserve"
  >
    <path
      fill={fillColor}
      d="M0 384l383.75 383.75L767.5 384H0z"
    />
  </svg>
);

export default TriangleIcon;