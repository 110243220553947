import React, { useState, useEffect, useContext } from "react";
import { getCookie } from "../../jwt/_helpers/cookie";
import { StateContext } from "../../utils/context";
import TableHelper from "../../components/tableHelper";
import { Button, Alert } from "reactstrap";
import SubscriptionUpgradeModal from "../../components/subscriptionUpgradeModal";
import InfoMessageTooltip from "./infoMessageTooltip";
import "./subscriptionUpgrade.css";

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const SubscriptionUpgrade = () => {
  const token = getCookie("token");
  const {currentUser} = useContext(StateContext);

  const [currentTierName, setCurrentTierName] = useState("");
  const [currentTierOrder, setCurrentTierOrder] = useState(0);
  const [tiers, setTiers] = useState([]);
  const [newTier, setNewTier] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [openModal, setToggleModal] = useState(false);


  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": X_API_KEY,
      "Content-Type": "application/json"
    }
  };

  useEffect(() => {
    if(currentUser && currentUser.subscription_active) {
      setCurrentTierName(currentUser.subscription_tier_name)
      setCurrentTierOrder(currentUser.subscription_order)
    }
  }, [currentUser]);

  useEffect(() => {
    if(newTier!==null) {
      setToggleModal(true);
    }
  }, [newTier]);

  useEffect(() => {
    fetch(`${API_URL}subscription-tiers/`, options)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        let subTiers = data.results;
        subTiers = subTiers.sort((a, b) => a.order - b.order);
        setTiers(subTiers);
        setLoading(false);
      })
      .catch(error => {
        console.error('An error occured while fetching subscription tiers:', error);
        setError('Failed to load subscription tiers');
        setLoading(false);
      });
  }, []);

  if (loading) return <TableHelper loading />;
  if (error) return <Alert color="danger">Something went wrong, please try again later!</Alert>;

  return (
    <>
    {
      (currentUser && currentUser.has_subscription && currentUser.subscription_active) ?
      <div className="subscription-upgrade">
      <h2>Upgrade Your Subscription</h2>
      <div className="pricing-cards mt-5">
        {tiers.map((tier) => (
          <div key={tier.id} className="subscription-card" style={{border: (currentTierName === tier.name)? `4px solid ${tier.primary_color}`: `1px solid ${tier.secondary_color}`}}>
            <div className="subscription-card-ribbon-container">
                <div className="subscription-card-ribbon" style={{background: tier.ribbon_color, color: tier.ribbon_text_color}}>{tier.ribbon_text}</div>
            </div>
            <h3>{tier.name}</h3>
            <p className="tier-price-container"><span className="price" style={{color: tier.primary_color}}>{tier.price}</span><br/>{tier.price_info_message}</p>
            <ul>
              {(tier?.stitems || []).map((tierItem) => (
                <li style={{ lineHeight: "20px" }}>
                  <strong>
                    {tierItem.name}
                    {tierItem.info_message.length > 0 && (
                      <>
                        <i className="ml-1 tooltip-icon mdi mdi-18px mdi-help-circle" style={{ verticalAlign: "middle"}} id={`ti-${tier.id}-${tierItem.order}`}></i>
                        <InfoMessageTooltip target={`ti-${tier.id}-${tierItem.order}`} infoMessage={tierItem.info_message} />
                      </>
                    )}
                  </strong>
                  {tierItem.enabled ?
                    <i className="fas fa-check-circle float-right" style={{color: tier.primary_color, fontSize: "15px", marginTop: "2px" }}></i> :
                    <i className="fas fa-times-circle float-right" style={{color: tier.secondary_color, fontSize: "15px", marginTop: "2px" }}></i>
                  }
                </li>
              ))}
              <li><strong>Track Allowance</strong> <strong style={{color: tier.primary_color}} className="float-right">{tier.track_allowance}</strong></li>
              <li><strong>Music Link Allowance</strong> <strong style={{color: tier.primary_color}} className="float-right">{tier.music_link_allowance}</strong></li>
              <li><strong>Link Landing Page Allowance</strong> <strong style={{color: tier.primary_color}} className="float-right">{tier.link_landing_page_allowance}</strong></li>
            </ul>
            {
              (currentTierName === tier.name)? 
              <Button
                className="btn btn-primary"
                style={{
                  backgroundColor: tier.primary_color,
                  '--hover-bg-color': tier.primary_color,
                }}
              >
                <b>Current Plan</b>
              </Button>:
              <Button
                className="btn btn-primary"
                style={{
                  backgroundColor: currentTierOrder>=tier.order? "#6c757d": tier.primary_color,
                  '--hover-bg-color': currentTierOrder>=tier.order? "#6c757d": tier.secondary_color,
                }}
                disabled={currentTierOrder>=tier.order}
                onClick={() => {
                  setNewTier(tier);
                }}
              >
                Upgrade
              </Button>
            }
          </div>
        ))}
      </div>
      {openModal && (
        <SubscriptionUpgradeModal
          openModal={openModal}
          setToggleModal={setToggleModal}
          title="Upgrade Subscription"
          apiURL={'subscriptions/update'}
          newTier={newTier}
        />
      )}
      </div>:
      <Alert color="danger">
        You don't have access to this module, please contact the support team for further information.
      </Alert>
    }
  </>
  );
}

export default SubscriptionUpgrade;