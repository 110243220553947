import React from 'react';

const ControlNextIcon = ({ fillColor = "#FFFFFF", onClick, className }) => (
  <svg
    viewBox="0 0 60 60"
    width="60px"
    height="60px"
    onClick={onClick}
    className={className}
    xmlSpace="preserve"
    style={{ enableBackground: 'new 0 0 60 60' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor}
      d="M7.6,1.719l15.126,8.791c3.373,1.965,8.893,5.171,12.268,7.133
        l9.844,5.724V6.239c0-1.949,1.6-3.538,3.551-3.538h6.595c1.952,0,3.552,1.589,3.552,3.538v47.523c0,1.95-1.6,3.54-3.552,3.54h-6.595
        c-1.951,0-3.551-1.59-3.551-3.54V36.636l-9.844,5.724c-3.375,1.962-8.895,5.17-12.268,7.132L7.6,58.282
        c-3.373,1.964-6.134,0.375-6.134-3.51V5.23C1.466,1.333,4.227-0.243,7.6,1.719z"
    />
  </svg>
);

export default ControlNextIcon;