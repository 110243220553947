// Convert hex to RGB
const hexToRgb = (hex) => {
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return [r, g, b];
}

// Convert RGB to hex
const rgbToHex = (rgb) => {
    return "#" + rgb.map(x => x.toString(16).padStart(2, '0')).join('');
}

// Interpolate between two colors
const interpolateColor = (rgb1, rgb2, factor = 0.5) => {
    return rgb1.map((val, index) => Math.round(val + factor * (rgb2[index] - val)));
}

// Parse degrees string and convert to a factor between 0 and 1
const degreesToFactor = (degrees) => {
    const degreeValue = parseInt(degrees.replace("deg", ""), 10);
    return (degreeValue % 360) / 360;
}

const calculateMiddleColor = (color1, color2, degrees) => {  
    const rgb1 = hexToRgb(color1);
    const rgb2 = hexToRgb(color2);
    const factor = degreesToFactor(degrees);
    const middleRgb = interpolateColor(rgb1, rgb2, factor);
    return rgbToHex(middleRgb);
}

export default calculateMiddleColor;