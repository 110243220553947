import React, {useContext} from "react";
import { Link } from "react-router-dom";
import {DropdownMenu,DropdownItem, DropdownToggle } from "reactstrap";
import { authenticationService } from "../../../jwt/_services";
import profilephoto from "../../../assets/images/users/1.jpg";
import {StateContext} from "../../../utils/context";

const MenuAbout = props => {
  const {currentUser, setCurrentUser} = useContext(StateContext);
  const {first_name, last_name, profile_image, email} = currentUser;

  return (
    <>
    <DropdownToggle nav caret className="pro-pic">
      <img
        src={profile_image ? Object.keys(profile_image).length > 0 ? profile_image.thumb_small : profilephoto : profilephoto}
        alt="user"
        className="rounded-circle"
        width="31"
      />
    </DropdownToggle>
    <DropdownMenu right className="user-dd">
      <span className="with-arrow">
        <span className="bg-primary" />
      </span>
      <div className="d-flex no-block align-items-center p-3 mb-2 border-bottom">
        <div className="">
          <img
            src={profile_image ? Object.keys(profile_image).length > 0 ? profile_image.thumb_small : profilephoto : profilephoto}
            alt="user"
            className="rounded-circle"
            width="60"
          />
        </div>
        <div className="ml-2">
          <h4 className="mb-0">{first_name} {last_name}</h4>
          <p className=" mb-0">{email}</p>
          <Link
            to="/profile"
            className="btn btn-sm btn-mmdelectricpurple text-white mt-2 btn-rounded"
          >
            My Profile
          </Link>
        </div>
      </div>

      <DropdownItem>
        <Link to="/2fa">
          <i className="mdi mdi-key mr-1 ml-1" /> Two-Factor Authentication
        </Link>
      </DropdownItem>

      {
        currentUser.has_subscription &&
        <DropdownItem>
          <Link to="/subscription">
            <i className="ti-id-badge mr-1 ml-1" /> My Subscription
          </Link>
        </DropdownItem>
      }

      {
        currentUser.is_premium_user &&
          <DropdownItem>
            <Link to="/sub-users">
              <i className="ti-user mr-1 ml-1" /> My Sub-Users
            </Link>
          </DropdownItem>
      }

      <DropdownItem>
        <Link to="/labels">
          <i className="ti-list mr-1 ml-1" /> My Labels
        </Link>
      </DropdownItem>

      {
        !currentUser.is_sub_user &&
        <DropdownItem>
          <Link to="/statements">
            <i className="ti-wallet mr-1 ml-1" /> Financial Statements
          </Link>
        </DropdownItem>
      }

      {
        currentUser.is_sub_user &&
        <DropdownItem>
          <Link to="/user-statements">
            <i className="ti-wallet mr-1 ml-1" /> User Statements
          </Link>
        </DropdownItem>
      }

      {
        currentUser.is_premium_user &&
        <DropdownItem>
          <Link to="/subuser-statements">
            <i className="ti-wallet mr-1 ml-1" /> Subuser Statements
          </Link>
        </DropdownItem>
      }

      {
        !currentUser.is_sub_user &&
        <DropdownItem>
          <Link to="/invoices">
            <i className="ti-receipt mr-1 ml-1" /> Invoices
          </Link>
        </DropdownItem>
      }

      <DropdownItem>
        <Link to="/payment-methods">
          <i className="ti-credit-card mr-1 ml-1" /> Payment Methods
        </Link>
      </DropdownItem>

      <DropdownItem>
        <Link to="/purchase-history">
          <i className="ti-shopping-cart-full mr-1 ml-1" /> Purchase History
        </Link>
      </DropdownItem>

      {
        currentUser.bulk_upload &&
        <DropdownItem>
          <Link to="/tokens">
            <i className="mdi mdi-key-variant mr-1 ml-1" /> Tokens
          </Link>
        </DropdownItem>
      }

      <DropdownItem onClick={() => {
        authenticationService.logout();
        setTimeout(() => {
          setCurrentUser({});
        }, 1000)
      }}>
        <i className="fa fa-power-off mr-1 ml-1" /> Logout
      </DropdownItem>
      </DropdownMenu>
      </>
  );
};

export default MenuAbout;
