import React from "react";
import { Row, Col } from "reactstrap";
import './signResponse.css';

const ContractSignCompleted = () => {

    return (
        <div className='bl-back-image'>
            <Row>
                <Col xl="12" lg="12" md="12" sm="12" xm="12" style={{marginTop: '10%'}}>
                    <div>
                        <h1 className="text-center text-success text-congrats">
                            Contract Sign Completed :)
                        </h1>

                        <div className="text-center pl-2 pr-2" style={{fontSize: '20px', marginTop: '40px'}}>
                            <p>
                                Your contract has been finalized, the status will be updated in your account shortly, please refresh the page in few seconds.<br/> 
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ContractSignCompleted;