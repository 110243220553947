import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import TenantContext from '../utils/tenantContext';


const IubendaCookieConsent = () => {
    const [isMoveMusicGmbH, isMoveMusicGmbhSET] = useState(false);
    const [isMoveMusicDoo, isMoveMusicDooSET] = useState(false);

    const {tenant} = useContext(TenantContext);

    useEffect(() => {
        if (tenant && tenant.contract_owner) {
          let contractOwner = tenant.contract_owner;    
          if (contractOwner === 1) {
            isMoveMusicGmbhSET(true);
          } else if (contractOwner === 2) {
            isMoveMusicDooSET(true);
          }    
        }
    }, [tenant]);

  return (
    <div>
        {
        isMoveMusicDoo && <Helmet>
            <script type="text/javascript" src="https://cs.iubenda.com/autoblocking/3737734.js"></script>
            <script type="text/javascript" src="//cdn.iubenda.com/cs/tcf/stub-v2.js"></script>
            <script type="text/javascript" src="//cdn.iubenda.com/cs/tcf/safe-tcf-v2.js"></script>
            <script type="text/javascript" src="//cdn.iubenda.com/cs/gpp/stub.js"></script>
            <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>
            <script type="text/javascript">
            {
              `window._iub.csConfiguration = {"siteId":3737734,"cookiePolicyId":80028717,"lang":"en"};`
            }
            </script>            
        </Helmet>
        }
        {
          isMoveMusicGmbH && <Helmet>
            <script type="text/javascript">
              {`
                window._iub.csConfiguration = {
                  "askConsentAtCookiePolicyUpdate": true,
                  "countryDetection": true,
                  "enableFadp": true,
                  "enableLgpd": true,
                  "enableUspr": true,
                  "floatingPreferencesButtonCaptionColor": "#ffffff",
                  "floatingPreferencesButtonColor": "#FFFFFF",
                  "floatingPreferencesButtonDisplay": "anchored-bottom-left",
                  "lang": "en",
                  "lgpdAppliesGlobally": false,
                  "perPurposeConsent": true,
                  "siteId": 1981537,
                  "cookiePolicyId": 51840490,
                  "i18n": {
                    "en": {
                      "banner": {
                        "title": "",
                        "dynamic": {
                          "body": "<div id='iubenda-cs-title'>Notice</div><div id='iubenda-cs-paragraph'><p class='iub-p'>We and selected third parties use cookies or similar technologies for technical purposes and, with your consent, for other purposes as specified in the <a href='/privacy-policy/51840490/cookie-policy?an=no&s_ck=false&newmarkup=yes' class='iubenda-cs-cookie-policy-lnk'>cookie policy</a>.</p><p class='iub-p'>You can freely give, deny, or withdraw your consent at any time.</p><p class='iub-p'></p><p class='iub-p'>To find out more about the categories of personal information collected and the purposes for which such information will be used, please refer to our %{privacy_policy}.</p><br /></div>"
                        }
                      }
                    }
                  },
                  "banner": {
                    "acceptButtonCaptionColor": "white",
                    "acceptButtonColor": "#000A60",
                    "acceptButtonDisplay": true,
                    "backgroundColor": "white",
                    "closeButtonRejects": true,
                    "customizeButtonCaptionColor": "#4D4D4D",
                    "customizeButtonColor": "rgba(218.16, 218.16, 218.16, 0.41)",
                    "customizeButtonDisplay": true,
                    "explicitWithdrawal": true,
                    "fontSizeBody": "11px",
                    "listPurposes": true,
                    "logo": null,
                    "position": "float-center",
                    "rejectButtonCaptionColor": "white",
                    "rejectButtonColor": "rgba(215.8, 215.8, 215.8, 0.3686274509803922)",
                    "rejectButtonDisplay": true,
                    "showPurposesToggles": true,
                    "textColor": "#3e5569"
                  }
                };
              `}
            </script>
            <script type="text/javascript" src="//cs.iubenda.com/sync/1981537.js"></script>
            <script type="text/javascript" src="//cdn.iubenda.com/cs/gpp/stub.js"></script>
            <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>
          </Helmet>
        }
    </div>
  );
};

export default IubendaCookieConsent;