import Trigger from "../../validations/es6/plugins/Trigger";
import Bootstrap from "../../validations/es6/plugins/Bootstrap";
import SubmitButton from "../../validations/es6/plugins/SubmitButton";

const euCountryCodes = ["AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "EL", "ES", "FI", "FR", "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PL", "PT", "RO", "SE", "SI", "SK", "GB", "GR"];

const optStepOne = {
  fields: {
    firstName: {
        validators: {
            notEmpty: {
                message: 'First Name is required'
            },
        }
    },
    lastName: {
        validators: {
            notEmpty: {
               message: 'Last Name is required'
            },
        }
    },
    personalStreet: {
        validators: {
            notEmpty: {
               message: 'Street And Number is required'
            },
        }
    },
    personalZip: {
        validators: {
            notEmpty: {
               message: 'Zip Code is required'
            },
        }
    },
    personalCity: {
        validators: {
            notEmpty: {
               message: 'City is required'
            },
        }
    },
    personalCountry: {
        validators: {
          notEmpty: {
            message: 'Country is required'
          },
        }
    },
  },
  plugins: {
    trigger: new Trigger(),
    bootstrap: new Bootstrap(),
    submitButton: new SubmitButton(),
  },
};

const optStepTwo = {
    fields: {
        businessType: {
            validators: {
                notEmpty: {
                    message: 'Business Type is required'
                },
            }
        },
        vatNumber: {
            validators: {
                vat: {
                    isSoleProprietor: function() {
                        return document
                        .getElementById("businessInfoForm")
                        .querySelector('[name="isSoleProprietor"]').value === "yes";
                    },
                    isAnEUCountry: function() {
                        let countryCode = document
                        .getElementById("businessInfoForm")
                        .querySelector('[name="countryCode"]').value.toUpperCase();
                        return euCountryCodes.includes(countryCode)
                    },
                    country: function() {
                        let countryCode = document
                        .getElementById("businessInfoForm")
                        .querySelector('[name="countryCode"]').value;
                        return countryCode
                    },
                    message: "The value is not a valid VAT number"
                }
            }
        },
        taxNumber: {
            validators: {
            }
        },
    },
    plugins: {
      trigger: new Trigger(),
      bootstrap: new Bootstrap(),
      submitButton: new SubmitButton(),
    },
  };

export {optStepOne, optStepTwo};