import React, { useState, useEffect, useContext } from 'react';
import {
    Row,
    Col,
} from 'reactstrap';
import axios from 'axios';
import { getCookie } from "../../jwt/_helpers";
import { StateContext } from '../../utils/context';
import LoaderInner from '../../components/LoaderInner';

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const StepSix = ({ jumpToStep }) => {
    const token = getCookie().token;
    const { currentUser } = useContext(StateContext);
    const [signingUrl, setSigningUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const signContract = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_URL}users/sign-contract/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "x-api-key": X_API_KEY,
                }
            });
            setSigningUrl(response.data.link);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Verification initiation failed:', error);
        }
    };


    useEffect(() => {
        if(currentUser && signingUrl === "") {
            signContract();
        }
      }, [currentUser, signingUrl])

    return (
        <div className="step step6 mt-5">
            {
                (currentUser && !currentUser.contract_signed) &&
                <Row className="justify-content-md-center">
                    <Col lg="6">
                        <center>
                            <br/><br/>
                            <div>
                                {isLoading ? (
                                    <LoaderInner show={isLoading} />
                                ) : (
                                    <iframe
                                        src={signingUrl}
                                        width="100%"
                                        height="800"
                                        style={{ border: '2px solid black' }}
                                        title="Sign The Contract"
                                    ></iframe>
                                )}
                            </div>
                        </center>
                    </Col>
                </Row>
            }
        </div>
    );
};

export default StepSix;