import React from 'react';

const ControlPreviousIcon = ({ fillColor = "#FFFFFF", onClick, className }) => (
  <svg
    viewBox="0 0 60 60"
    width="60px"
    height="60px"
    onClick={onClick}
    className={className}
    xmlSpace="preserve"
    style={{ enableBackground: 'new 0 0 60 60' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor}
      d="M52.4,58.281L37.274,49.49c-3.373-1.965-8.893-5.171-12.268-7.133
        l-9.844-5.724v17.127c0,1.949-1.6,3.538-3.551,3.538H5.018c-1.952,0-3.552-1.589-3.552-3.538V6.237c0-1.95,1.6-3.54,3.552-3.54
        h6.595c1.951,0,3.551,1.59,3.551,3.54v17.127l9.844-5.724c3.375-1.962,8.895-5.17,12.268-7.132L52.4,1.718
        c3.373-1.964,6.134-0.375,6.134,3.51V54.77C58.534,58.667,55.773,60.243,52.4,58.281z"
    />
  </svg>
);

export default ControlPreviousIcon;