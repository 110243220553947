export const types =  {
    GET_LANDINGS: "GET_LANDINGS",
    ADD_NEW_LANDING: "ADD_NEW_LANDING",
};

export const setLandings = (payload) => {
    return {
        type: types.GET_LANDINGS,
        payload
    }
}

export const addNewLanding= (payload) => {
    return {
        type: types.ADD_NEW_LANDING,
        payload
    }
}
