import { types } from './action'

export default (state = [], action) => {
  switch (action.type) {
    case types.GET_MUSIC_LINKS:
      return action.payload
    case types.ADD_NEW_MUSIC_LINK:
      return [
        ...state,
        action.payload
      ]
    default:
      return state;
  }
};
