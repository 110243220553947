import React from 'react';
import Select from 'react-select';
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import './storeLinksRow.css'
import AddStoreModal from './addStoreModal';

// const arrowStyleWrap = {
//   display: 'flex'
// }
// const arrowStyle = {
//   padding: 3,
//   cursor: 'pointer'
// }

const RowSelect = ({ values }) => {
  const { store_link, index,  manageStoreLinksData, listOfStoreLinks, listOfStoreLinksSET, linkOrderChange, removeStoreLink, blockedValeues, disabled } = values;
  const key = store_link.key;
  const store = store_link.store.value;
  const storeOptions = [store_link.store];

  return (
    <div className="artistWrap" style={{flexDirection: "column"}}>
      <div className="artistWrap" style={{margin: "0", alignItems: 'flex-start'}}>
      {/* <Col xs="1" style={arrowStyleWrap}>
        <div style={arrowStyle} onClick={() => linkOrderChange('up', key, index, store_link.order, listOfStoreLinks, listOfStoreLinksSET)}>▲</div>
        <div style={arrowStyle} onClick={() => linkOrderChange('down', key, index, store_link.order, listOfStoreLinks, listOfStoreLinksSET)}>▼</div>
      </Col> */}
       <Col xs="5">
          <Select
            components={{ IndicatorSeparator:() => null }}
            options={storeOptions}
            value={store_link.store}
            onChange={(e) => {
              if(blockedValeues !== null && blockedValeues.blockedSubmit) {
                blockedValeues.setBlockedSubmit(false);
              }
              manageStoreLinksData(index, 'store', e)
            }}
            className="store"
          />
        </Col>
        <Col xs="5">
          <Input
              type="text"
              name={`${store}_url`}
              defaultValue={store_link.link}
              disabled={disabled}
              onChange={(e) => {
                if (blockedValeues !== null && blockedValeues.blockedSubmit) {
                  blockedValeues.setBlockedSubmit(false);
                }
                manageStoreLinksData(index, 'link', e.target.value);
              }}
              placeholder=""
          />
        </Col>
        <Col xs="2">
          {
            !disabled &&
            <Button
                className="btn btn-outline-danger"
                style={{width: '100%', minWidth: "80px"}}
                onClick={() => {
                    removeStoreLink(index);
                    if (blockedValeues !== null && blockedValeues.blockedSubmit) {
                      blockedValeues.setBlockedSubmit(false);
                    }
                }}
            >
                Remove
            </Button>
          }
        </Col>
      </div>
      <div style={{width: '100%', display: 'flex'}}>
        {/* <Col xs="1"></Col> */}
        <Col xs="5"></Col>
        <Col xs="5"></Col>
        <Col xs="2"></Col>
      </div>
      <div style={{width: '100%', display: 'flex'}}>
        {/* <Col xs="1"></Col> */}
        <Col xs="4"></Col>
        <Col xs="5"></Col>
        <Col xs="2"></Col>
      </div>
    </div>
  )
}


const RowLabel = () => {
  return (
    <Row className="artistWrap labelRow">
      {/* <Col xs="1">
        Order
      </Col> */}
      <Col xs="5">
        Store
      </Col>
      <Col xs="5">
        Link
      </Col>
      <Col xs="2">
      </Col>
    </Row>
  )
}


const StoreLinksRow = ({ values, disabled = false, dataView = null, blockedValeues = null }) => {
  const { listOfStoreLinks, manageStoreLinksData, listOfStoreLinksSET, linkOrderChange, addNewStoreLink, removeStoreLink } = values;
  
  return (
    <>
      <FormGroup style={{ paddingBottom: 30 }}>
      <Label><b>Store Links</b></Label>
        <Row>
          <RowLabel />
          {
            listOfStoreLinks.map((store_link, index) => {
              const itemVal = { index, store_link, manageStoreLinksData, listOfStoreLinks, listOfStoreLinksSET, linkOrderChange, removeStoreLink, blockedValeues, disabled }
              return <RowSelect values={itemVal} key={store_link.key} />
            })
          }
        </Row>

        <AddStoreModal listOfStoreLinks={listOfStoreLinks} addNewStoreLink={addNewStoreLink} />
        {' '}
      </FormGroup>
    </>
  );
};

export default StoreLinksRow;