import React, { useState } from 'react';
import axios from "axios";
import {
  Alert, Button, Card, CardBody, CardText, CardTitle,
  Container, Form, Row, Col, Label, Input
} from "reactstrap";
import { Redirect, useLocation } from "react-router-dom";
import './signUpSuccessfull.css'; // Ensure this CSS is relevant and correctly named

const VerifyAccount = () => {
  const location = useLocation();
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [pass, setPass] = useState('');
  const [pass2, setPass2] = useState('');

  const submit = async () => {
    if (pass.length < 10) {
      setError(true);
      setErrorText(['Password is required and must be at least 10 characters.']);
      return;
    }

    if (pass !== pass2) {
      setError(true);
      setErrorText(['Passwords should match.']);
      return;
    }

    const token = location.pathname.split('/verify/')[1]?.slice(0, -1);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL_BASE}users/activate-account/`, {
        password: pass,
        token: token
      });

      if (response.status === 200) {
        setSuccess(true);
        setTimeout(() => setRedirect(true), 3000);
      } else {
        let errorText = response.data.status;
        setErrorText([errorText]);
        setError(true);
      }
    } catch (error) {
      let errorText = error.response.data.status;
      setErrorText([errorText || 'An error occurred. Please try again.']);
      setError(true);
    }
  };

  if (redirect) {
    return <Redirect to="/authentication/login" />;
  }

  return (
    <Container className="bl-back-image" fluid={true} style={{
      height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"
    }}>
      <Card>
        <CardBody className="text-center">
          <CardTitle tag="h1" style={{ fontSize: '50px' }}>
            Just One More Step...
          </CardTitle>
          <CardText>
            <p style={{ fontSize: '20px', marginTop: '40px' }}>
              Please set your password to confirm your account. Password should be at least 10 characters long.
            </p>
              <Form  className='p-2'>
                <Row form className='mb-4'>
                  <Col md={3}>
                    <Label for="passwordNew" className="float-md-left"><b>Password</b></Label>
                  </Col>
                  <Col md={9}>
                    <Input type="password" name="passwordNew" id="passwordNew" placeholder="Password..." value={pass} onChange={e => setPass(e.target.value)} />
                  </Col>
                </Row>
                <Row form className="mt-2">
                  <Col md={3}>
                    <Label for="passwordConfirm" className="float-md-left"><b>Confirm Password</b></Label>
                  </Col>
                  <Col md={9}>
                    <Input type="password" name="passwordConfirm" id="passwordConfirm" placeholder="Confirm your password..." value={pass2} onChange={e => setPass2(e.target.value)} />
                  </Col>
                </Row>
              </Form>
            {error && errorText.map((text, index) => (
              <Alert key={index} color="danger">{text}</Alert>
            ))}
          </CardText>
          <Button color="primary" onClick={submit} style={{ fontSize: '20px' }}>
            Confirm Your Account
          </Button>
        </CardBody>
        {success && (
          <Alert color="success" style={{ margin: '20px' }}>
            Your account has been verified! Redirecting to login page...
          </Alert>
        )}
      </Card>
    </Container>
  );
};

export default VerifyAccount;