import r from "../../validations/es6/utils/format";
import a from "../../validations/es6/validators/vat/arVat";
import e from "../../validations/es6/validators/vat/atVat";
import t from "../../validations/es6/validators/vat/beVat";
import o from "../../validations/es6/validators/vat/bgVat";
import m from "../../validations/es6/validators/vat/brVat";
import s from "../../validations/es6/validators/vat/chVat";
import i from "../../validations/es6/validators/vat/cyVat";
import c from "../../validations/es6/validators/vat/czVat";
import f from "../../validations/es6/validators/vat/deVat";
import b from "../../validations/es6/validators/vat/dkVat";
import p from "../../validations/es6/validators/vat/eeVat";
import k from "../../validations/es6/validators/vat/esVat";
import V from "../../validations/es6/validators/vat/fiVat";
import n from "../../validations/es6/validators/vat/frVat";
import l from "../../validations/es6/validators/vat/gbVat";
import u from "../../validations/es6/validators/vat/grVat";
import v from "../../validations/es6/validators/vat/hrVat";
import g from "../../validations/es6/validators/vat/huVat";
import d from "../../validations/es6/validators/vat/ieVat";
import E from "../../validations/es6/validators/vat/isVat";
import h from "../../validations/es6/validators/vat/itVat";
import R from "../../validations/es6/validators/vat/ltVat";
import y from "../../validations/es6/validators/vat/luVat";
import L from "../../validations/es6/validators/vat/lvVat";
import C from "../../validations/es6/validators/vat/mtVat";
import S from "../../validations/es6/validators/vat/nlVat";
import I from "../../validations/es6/validators/vat/noVat";
import O from "../../validations/es6/validators/vat/plVat";
import T from "../../validations/es6/validators/vat/ptVat";
import U from "../../validations/es6/validators/vat/roVat";
import z from "../../validations/es6/validators/vat/rsVat";
import B from "../../validations/es6/validators/vat/ruVat";
import A from "../../validations/es6/validators/vat/seVat";
import G from "../../validations/es6/validators/vat/siVat";
import H from "../../validations/es6/validators/vat/skVat";
import j from "../../validations/es6/validators/vat/veVat";
import w from "../../validations/es6/validators/vat/zaVat";
export default function x() {
    const x = ["AR", "AT", "BE", "BG", "BR", "CH", "CY", "CZ", "DE", "DK", "EE", "EL", "ES", "FI", "FR", "GR", "HR", "HU", "IE", "IS", "IT", "LT", "LU", "LV", "MT", "NL", "NO", "PL", "PT", "RO", "RU", "RS", "SE", "SK", "SI", "VE", "ZA"];
    return {
        validate(D) {
            const F = D.value;
            const isAnEUCountry = D.options.isAnEUCountry;

            let isValidationRequired = false;
            if(isAnEUCountry() && F !== "") {
                isValidationRequired = true;
            }

            if (!isValidationRequired) {
                return {
                    meta: {},
                    valid: true
                };
            }
            
            if (F === "" || F.length < 3 || !isNaN(F[0]) || !isNaN(F[1])) {
                return {
                    valid: false,
                    message: "Invalid VAT number. It should start with a country code."
                }
            }
            const K = Object.assign({}, {
                message: ""
            }, D.options);
            let N = F.substr(0, 2).toUpperCase();
            if ("function" === typeof K.country) {
                N = K.country.call(this)
            } else if (K.country) {
                N = K.country
            }
            if (x.indexOf(N) === -1) {
                return {
                    valid: false,
                    message: "Invalid VAT number. It should start with a valid country code."
                }
            }
            let P = {
                meta: {},
                valid: true
            };
            switch (N.toLowerCase()) {
                case "ar":
                    P = a(F);
                    break;
                case "at":
                    P = e(F);
                    break;
                case "be":
                    P = t(F);
                    break;
                case "bg":
                    P = o(F);
                    break;
                case "br":
                    P = m(F);
                    break;
                case "ch":
                    P = s(F);
                    break;
                case "cy":
                    P = i(F);
                    break;
                case "cz":
                    P = c(F);
                    break;
                case "de":
                    P = f(F);
                    break;
                case "dk":
                    P = b(F);
                    break;
                case "ee":
                    P = p(F);
                    break;
                case "el":
                    P = u(F);
                    break;
                case "es":
                    P = k(F);
                    break;
                case "fi":
                    P = V(F);
                    break;
                case "fr":
                    P = n(F);
                    break;
                case "gb":
                    P = l(F);
                    break;
                case "gr":
                    P = u(F);
                    break;
                case "hr":
                    P = v(F);
                    break;
                case "hu":
                    P = g(F);
                    break;
                case "ie":
                    P = d(F);
                    break;
                case "is":
                    P = E(F);
                    break;
                case "it":
                    P = h(F);
                    break;
                case "lt":
                    P = R(F);
                    break;
                case "lu":
                    P = y(F);
                    break;
                case "lv":
                    P = L(F);
                    break;
                case "mt":
                    P = C(F);
                    break;
                case "nl":
                    P = S(F);
                    break;
                case "no":
                    P = I(F);
                    break;
                case "pl":
                    P = O(F);
                    break;
                case "pt":
                    P = T(F);
                    break;
                case "ro":
                    P = U(F);
                    break;
                case "rs":
                    P = z(F);
                    break;
                case "ru":
                    P = B(F);
                    break;
                case "se":
                    P = A(F);
                    break;
                case "si":
                    P = G(F);
                    break;
                case "sk":
                    P = H(F);
                    break;
                case "ve":
                    P = j(F);
                    break;
                case "za":
                    P = w(F);
                    break;
                default:
                    break
            }
            const Z = r(D.l10n && D.l10n.vat ? K.message || D.l10n.vat.country : K.message, D.l10n && D.l10n.vat && D.l10n.vat.countries ? D.l10n.vat.countries[N.toUpperCase()] : N.toUpperCase());
            return Object.assign({}, {
                message: Z
            }, P)
        }
    }
}