import React, { useState, useContext } from 'react';
import axios from 'axios';
import { getCookie } from '../jwt/_helpers';
import { StateContext } from '../utils/context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

function BackupCodes() {
  const [codes, setCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currentUser } = useContext(StateContext);
  const token = getCookie("token");

  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": X_API_KEY,
      "Content-Type": "application/json"
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const generateBackupCodes = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_URL}generate-2fa-backup-codes/`, {}, options);
      setCodes(response.data.backup_codes);
      setIsLoading(false);
      toggleModal();
    } catch (error) {
      console.error("Error generating backup codes", error);
      setIsLoading(false);
    }
  };

  const downloadCodes = () => {
    const blob = new Blob([codes.join('\n')], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "mmd_backup_codes.txt";
    link.click();
  };

  return (
    <div>
      <Button className="btn btn-outline-mmddarkblue" onClick={generateBackupCodes}>
        {isLoading ? <Spinner size="sm" /> : "Generate Backup Codes"}
      </Button>

      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Your Backup Codes</ModalHeader>
        <ModalBody>
          {codes.length > 0 ? (
            <ul className="backup-codes-list">
              {codes.map((code, index) => (
                <li key={index}>{code}</li>
              ))}
            </ul>
          ) : (
            <p>No backup codes available</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-success float-left" onClick={downloadCodes}>Download</Button>{' '}
        </ModalFooter>
      </Modal>

      <style jsx>{`
        .backup-codes-list {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
          list-style-type: none;
          padding: 0;
        }

        .backup-codes-list li {
          padding: 5px 0;
        }
      `}</style>
    </div>
  );
}

export default BackupCodes;