import React from 'react';

const PlayIcon = ({ fillColorOne, fillColorTwo, onClick, className }) => (
  <svg
    version="1.1"
    width="81"
    height="81"
    id="Layer_1"
    viewBox="0 0 512 512"
    style={{ enableBackground: 'new 0 0 512 512' }}
    onClick={onClick}
    className={className}
  >
    <circle style={{ fill: fillColorOne }} cx="256" cy="256" r="245.801" />
    <polygon style={{ fill: fillColorTwo }} points="195.825,391.629 376.351,256 195.825,120.371 " />
    <g>
      <path
        style={{ fill: fillColorTwo }}
        d="M256,512c-68.381,0-132.667-26.628-181.019-74.98C26.628,388.667,0,324.38,0,256
        S26.628,123.333,74.981,74.98C123.333,26.628,187.619,0,256,0s132.667,26.628,181.019,74.98C485.372,123.333,512,187.62,512,256
        s-26.628,132.667-74.981,181.02C388.667,485.372,324.381,512,256,512z M256,20.398C126.089,20.398,20.398,126.089,20.398,256
        S126.089,491.602,256,491.602S491.602,385.911,491.602,256S385.911,20.398,256,20.398z"
      />
      <path
        style={{ fill: fillColorTwo }}
        d="M195.824,401.828c-1.553,0-3.115-0.355-4.557-1.075c-3.458-1.727-5.641-5.26-5.641-9.124V120.371
        c0-3.864,2.185-7.397,5.641-9.124c3.458-1.726,7.593-1.351,10.685,0.97l180.526,135.629c2.564,1.927,4.073,4.948,4.073,8.154
        s-1.508,6.228-4.073,8.154L201.951,399.783C200.15,401.137,197.994,401.828,195.824,401.828z M206.024,140.791v230.418L359.371,256
        L206.024,140.791z"
      />
    </g>
  </svg>
);

export default PlayIcon;