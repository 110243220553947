import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { StateContext } from '../../utils/context';
import { getCookie } from '../../jwt/_helpers';
import Select from 'react-select';
import { getUser } from '../../utils/getUser';
import { FormText, Label, Card, CardBody, CardTitle, Button } from 'reactstrap';
import BackupCodes from '../../components/backupCodes';

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const TWO_FACTOR_METHOD_OPTIONS = [
    { value: "email", label: "Email" },
    { value: "auth", label: "Authenticator" },
];

const TwoFactorAuth = () => {
  const token = getCookie("token");
  const { currentUser, setCurrentUser } = useContext(StateContext);
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": X_API_KEY,
      "Content-Type": "application/json"
    }
  };

  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [currentTwoFactorMethod, currentTwoFactorMethodSET] = useState({ value: "", label: "Select 2FA Method" });
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasChangesSaved, setHasChangesSaved] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.two_factor_method) {
      const selectedMethod = TWO_FACTOR_METHOD_OPTIONS.find(
        option => option.value === currentUser.two_factor_method
      );
      currentTwoFactorMethodSET(selectedMethod);
      setSuccess(true);
    }
  }, [currentUser]);

  useEffect(() => {
    getUser(token, currentUser, setCurrentUser);
  }, [success]);

  useEffect(() => {
    if (currentTwoFactorMethod.value === "auth") {
      axios.get(`${API_URL}generate-2fa-qr-code/`, { ...options, responseType: "arraybuffer" })
        .then(response => {
          const qrCodeBuffer = response.data;
          const base64QrCode = Buffer.from(qrCodeBuffer, "binary").toString("base64");
          setQrCodeUrl(`data:image/png;base64,${base64QrCode}`);
        })
        .catch(error => {
          console.error('Error fetching QR code:', error);
        });
    }
  }, [currentTwoFactorMethod]);

  const handleSave = () => {
    setIsSaving(true);
    axios({
      method: "PATCH",
      url: `${API_URL}users/${currentUser.id}/`,
      data: { "two_factor_method": selectedMethod.value },
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": X_API_KEY,
      }
    })
      .then(response => {
        if (response.status === 200) {
          axios({
            method: "GET",
            url: `${API_URL}users/${currentUser.id}/`,
            mode: 'cors',
            headers: {
              Authorization: `Bearer ${token}`,
              "x-api-key": X_API_KEY,
            }
          }).then(response => {
            if (response.status === 200) {
              setCurrentUser(response.data);
              setSuccess(true);
              currentTwoFactorMethodSET(selectedMethod);
              setSelectedMethod(null);
              setHasChangesSaved(true);
            }
            setIsSaving(false);
          });
        }
      })
      .catch(error => {
        console.error('Error updating Two-Factor Method:', error);
        setIsSaving(false);
      });
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="d-flex justify-content-between align-items-center">
          <h2 className="mb-0">Two-Factor Authentication</h2>
          <div className="mt-4">
            <BackupCodes />
          </div>
        </CardTitle>
        <div>
          <Label>Current Two-Factor Method</Label>
          <Select
            components={{ IndicatorSeparator: () => null }}
            name="twoFactorMethod"
            options={TWO_FACTOR_METHOD_OPTIONS}
            value={selectedMethod || currentTwoFactorMethod}
            onChange={(e) => {
              setSelectedMethod(e);
              setHasChangesSaved(false);
            }}
          />
          {
            currentTwoFactorMethod.value === "email" &&
            <FormText color="muted">
                Email is currently set as the default method for Two-Factor Authentication. However, we recommend using the Authenticator method for enhanced security.
            </FormText>
          }
        </div>

        {selectedMethod && selectedMethod.value !== currentTwoFactorMethod.value && (
          <Button className="mt-2 btn btn-success float-right" style={{width: "100px"}} onClick={handleSave} disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        )}

        {(hasChangesSaved || selectedMethod?.value === currentTwoFactorMethod.value || success) && (
          <div className="mt-3">
            {currentTwoFactorMethod.value === "auth" ? (
              <div>
                {qrCodeUrl ? (
                  <div>
                    <h6>Set up your Authenticator App</h6>
                    <p>Scan the QR code below using any TOTP Authenticator app (like Authy, Google Authenticator, Microsoft Authenticator etc).</p>
                    <img src={qrCodeUrl} alt="QR Code for authenticator setup" />
                    <ol>
                      <li>Open your authenticator app.</li>
                      <li>Select the option to add a new account.</li>
                      <li>Choose the "Scan a QR code" option in the app.</li>
                      <li>Point your phone's camera at the QR code above.</li>
                      <li>Once scanned, authenticator will start generating 6-digit OTP Code that refresh every 30 seconds. This OTP Code can be used to complete the login process.</li>
                    </ol>
                    <p>
                      Here are the links for some common authenticator apps:
                      <ul>
                        <li>
                          <b>Authy</b><br/>
                          <a href="https://play.google.com/store/apps/details?id=com.authy.authy" target="_blank" rel="noopener noreferrer">Google Play Store</a> | <a href="https://apps.apple.com/us/app/twilio-authy/id494168017" target="_blank" rel="noopener noreferrer">Apple App Store</a>
                        </li>
                        <li>
                          <b>Google Authenticator</b><br/>
                          <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noopener noreferrer">Google Play Store</a> | <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer">Apple App Store</a>
                        </li>
                        <li>
                          <b>Microsoft Authenticator</b><br/>
                          <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator" target="_blank" rel="noopener noreferrer">Google Play Store</a> | <a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank" rel="noopener noreferrer">Apple App Store</a>
                        </li>
                      </ul>
                    </p>
                  </div>
                ) : (
                  <p>Loading QR code...</p>
                )}
              </div>
            ) : (
              <div className="mt-3">
                <h6>Email Two-Factor Authentication</h6>
                <p>
                  You will receive a One-Time Password (OTP) on your registered email address, which can be used to complete the login process.
                  Make sure to check your email inbox or spam folder for the OTP when logging in.
                </p>
              </div>
            )}
          </div>
        )}        
      </CardBody>
    </Card>
  );
};

export default TwoFactorAuth;