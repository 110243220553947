import React from "react";
import { Row, Col } from "reactstrap";
import './signResponse.css';

const ContractSignLater = () => {

    return (
        <div className='bl-back-image'>
            <Row>
                <Col xl="12" lg="12" md="12" sm="12" xm="12" style={{marginTop: '10%'}}>
                    <div>
                        <h1 className="text-center text-warning text-congrats">
                            Contract Sign Later ...
                        </h1>

                        <div className="text-center pl-2 pr-2" style={{fontSize: '20px', marginTop: '40px'}}>
                            <p>
                                You have chosen to sign the contract later, please complete this step at the earliest to avail distribution.<br/> 
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ContractSignLater;