import React, { useState, useCallback, useMemo, useEffect } from "react";
import AsyncSelect from "react-select/async";
import ProfileImg from "../assets/images/users/1.jpg";

const API_URL = process.env.REACT_APP_API_URL_BASE;

const AsyncDropdownAmazon = ({
  value,
  onChange,
  placeholder = "Search...",
  fetchOptions = {},
  fieldName,
  defaultQuery,
  ...props
}) => {
  const [options, setOptions] = useState([]);


  const CustomOption = useMemo(() => {
    return React.memo(({ innerProps, data, isFocused }) => (
      <div {...innerProps} style={{ 
          padding: '3px',
          backgroundColor: isFocused ? '#f5f5f5' : 'transparent',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}>
        <img
          src={data.image_small || ProfileImg}
          alt={data.label}
          style={{
            padding: "5px",
            height: '65px',
            width: '65px',
            borderRadius: '30%',
            marginRight: "7px",
          }}
        />
        <b>{data.label}</b>
      </div>
    ));
  }, []);

  const isAmazonId = (str) => {
    return /^[A-Z0-9]{10}$/.test(str);
  };

  const loadOptions = (query, callback) => {
    let queryType = isAmazonId(query)? 'artist_id': 'artist_name';
    fetch(`${API_URL}artists/amazon-artist-details/?${queryType}=${query}`, fetchOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if(queryType === "artist_id") {
          let artist = data["data"]["artist"];
          let images = artist["images"] ? artist["images"].filter(image => 
            image.imageType === 'PROFILE'
          ) : [];
          images.sort((a, b) => (b.height * b.width) - (a.height * a.width));
          let image_big = images[0]?.url || null;
          let image_small = images.length === 1 ? image_big : images[images.length - 1]?.url || null;

          let formattedData = [{
            value: artist["id"],
            label: artist["name"],
            image_big: image_big,
            image_small: image_small,
          }];
          setOptions(formattedData);
          callback(formattedData);
        } else {
          let artists = data["searchArtists"]["edges"];  
          if (artists.length > 0) {
            let formattedData = artists.map(item => {
              let images = item["node"]["images"] ? item["node"]["images"].filter(image => 
                  image.imageType === 'PROFILE'
              ) : [];
              images.sort((a, b) => (b.height * b.width) - (a.height * a.width));
              let image_big = images[0]?.url || null;
              let image_small = images.length === 1 ? image_big : images[images.length - 1]?.url || null;              
              return {
                value: item["node"]["id"],
                label: item["node"]["name"],
                image_big: image_big,
                image_small: image_small,
              };
            });  
            setOptions(formattedData);
            callback(formattedData);
          } else {
            setOptions([]);
            callback([]);
          }
        }
      })
      .catch(error => {
        console.error(error);
        setOptions([]);
        callback([]);
      });
  };

  useEffect(() => {
    if (defaultQuery) {
      loadOptions(defaultQuery, setOptions);
    }
  }, [defaultQuery, loadOptions]);


  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedLoadOptions = useCallback(debounce(loadOptions, 300), [loadOptions]);


  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={options}
      defaultValue={value}
      loadOptions={debouncedLoadOptions}
      onChange={onChange}
      placeholder={placeholder}
      components={{
        Option: CustomOption,
      }}
      styles={{
        menu: styles => ({ ...styles, zIndex: 10 }),
      }}
      {...props}
    />
  );
}

export default AsyncDropdownAmazon;