import React, { useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Select from 'react-select';

const AddStoreModal = ({ addNewStoreLink, listOfStoreLinks }) => {
  const [modal, setModal] = useState(false);
  const [store, setStore] = useState(null);

  const toggle = () => {
    setModal(!modal);
    setStore(null);
  }

  const storeOptions = useMemo(() => {
    const usedStoreValues = new Set(listOfStoreLinks.map(link => link.store.value));

    return [
      {
        label: "Main Stores",
        options: [
          { value: "amazonmusic", label: "Amazon Music" },
          { value: "applemusic", label: "Apple Music" },
          { value: "beatport", label: "Beatport" },
          { value: "deezer", label: "Deezer" },
          { value: "junodownload", label: "Junodownload" },
          { value: "soundcloud", label: "Soundcloud" },
          { value: "spotify", label: "Spotify" },
          { value: "tidal", label: "Tidal" },
          { value: "traxsource", label: "Traxsource" },
          { value: "youtube", label: "YouTube" },
        ]
      },
      {
        label: "Other Stores",
        options: [
          { value: "anghami", label: "Anghami" },
          { value: "bandcamp", label: "Bandcamp" },
          { value: "boomplay", label: "Boomplay" },
          { value: "iheartradio", label: "iHeartRadio" },
          { value: "jiosaavn", label: "JioSaavn" },
          { value: "sevendigital", label: "7digital" },
        ]
      }
    ].map(group => ({
      ...group,
      options: group.options.map(option => ({
        ...option,
        isDisabled: usedStoreValues.has(option.value)
      }))
    }));
  }, [listOfStoreLinks]);

  return (
    <>
      <Button color="primary" onClick={toggle}>
        Add Store
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Store</ModalHeader>
        <ModalBody>
          Please choose the desired store from the list below:
          <Select
            className="mt-4"
            options={storeOptions}
            value={store}
            onChange={(selectedOption) => setStore(selectedOption)}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="success"
            onClick={() => {
              addNewStoreLink(store);
              toggle();
            }}
            disabled={!store}
          >
            Add Store
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddStoreModal;