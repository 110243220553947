import React, { useState, useContext, useEffect } from 'react';
import StepZilla from "react-stepzilla";
import {
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";
import { StateContext } from '../../utils/context';
import StepZero from './stepZero';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import StepThree from './stepThree';
import StepFour from './stepFour';
import StepFive from './stepFive';
import StepSix from './stepSix';
import StepSeven from './stepSeven';
import LoaderInner from '../../components/LoaderInner';
import Countries from "../../utils/countriesExtended.json";

function UserDataForm() {
  const { currentUser } = useContext(StateContext);

  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    personalStreet: "",
    personalZip: "",
    personalCity: "",
    personalCountry: null,
  });

  const [businessInfo, setBusinessInfo] = useState({
    businessType: { label: "Select Business Type", value: -1},
    companyName: "",
    ceoFirstName: "",
    ceoLastName: "",
    businessStreet: "",
    businessZip: "",
    businessCity: "",
    businessCountry: null,
    tradeRegistryExport: null,
    tradeRegistryCompanyUrl: "",
    taxNumber: "",
    vatNumber: "",
  });

  const [startAtStep, setStartAtStep] = useState(0);
  const [showDataForm, setShowDataForm] = useState(false);

  useEffect(() => {
    if(currentUser && !currentUser.identity_checked) {
      let startingStep = 0;
      if (currentUser.identity_checked) {
        startingStep = 8;
      } else if (currentUser.contract_signed) {
        startingStep = 7;
      } else if (currentUser.identity_verified) {
        startingStep = 6;
      } else if (currentUser.mobile_verified) {
        startingStep = 5;
      } else if (currentUser.basic_info_submitted) {
        startingStep = 4;
      }
      setStartAtStep(startingStep);
    }

    if (currentUser && currentUser.hasOwnProperty('identity_checked') && !currentUser.identity_checked) {
      setShowDataForm(true);
    }

    if (currentUser) {
      setPersonalInfo(prevState => ({
        ...prevState,
        firstName: currentUser.first_name,
        lastName: currentUser.last_name,
        personalStreet: currentUser.street_and_number,
        personalZip: currentUser.postal_code,
        personalCity: currentUser.city,
      }));
      setBusinessInfo(prevState => ({
        ...prevState,
        ceoFirstName: currentUser.first_name,
        ceoLastName: currentUser.last_name,
        companyName: currentUser.company,
        vatNumber: currentUser.vat,
        businessStreet: currentUser.street_and_number,
        businessZip: currentUser.postal_code,
        businessCity: currentUser.city,
        tradeRegistryCompanyUrl: currentUser.trade_registry_company_url,
      }));
    }

    if (currentUser.country) {
      let currentUserCountry = Countries.filter(i => i.value === currentUser.country)[0];
      setPersonalInfo(prevState => ({
        ...prevState,
        personalCountry: currentUserCountry
      }));
      setBusinessInfo(prevState => ({
        ...prevState,
        businessCountry: currentUserCountry
      }));
    }

  }, [currentUser])

  const steps =
  [
    {
      name: 'Declaration',
      component: <StepZero />
    },
    {
      name: 'Personal Information',
      component: <StepOne formData={personalInfo} setFormData={setPersonalInfo} />
    },
    {
      name: 'Business Information',
      component: <StepTwo formData={businessInfo} setFormData={setBusinessInfo} personalCountry={personalInfo.personalCountry? personalInfo.personalCountry.value: null} ceoFirstName={personalInfo.firstName} ceoLastName={personalInfo.lastName} />
    },
    {
      name: 'Confirmation',
      component: <StepThree formData={{...personalInfo, ...businessInfo}} />
    },
    {
      name: 'Verify Mobile Number',
      component: <StepFour />
    },
    {
      name: 'Identity Verification',
      component: <StepFive />
    },
    {
      name: 'Sign Contract',
      component: <StepSix />
    },
    {
      name: 'Approval',
      component: <StepSeven />
    },
  ];

  return (
      <>
        <LoaderInner show={!showDataForm} />
        {showDataForm &&
          <Card>
            <CardBody className="p-4">
                <CardTitle tag="h4">Complete Your Information</CardTitle>
                <div className='step-progress'>
                  <StepZilla
                    key={startAtStep}
                    steps={steps}
                    preventEnterSubmission={true}
                    showNavigation={false}
                    stepsNavigation={false}
                    startAtStep={startAtStep}
                  />
                </div>
            </CardBody>
          </Card>
        }
      </>
  );
}

export default UserDataForm;