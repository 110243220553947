import React, { useContext, useState, useRef, useEffect } from "react";
import { StateContext } from "../utils/context";
import { useFetch } from "../utils/fetchHook";
import { getCookie } from "../jwt/_helpers";
import { Helmet } from "react-helmet";

function generateNonce(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const SubUserZohoASAPWidget = ({token}) => {
  const { currentUser } = useContext(StateContext);
  const userToken = getCookie("token");
  const nonce = generateNonce(16);
  const [zohoUserID, zohoUserIDSET] = useState(false);
  const [zohoUserIDPresent, zohoUserIDPresentSET] = useState(null);
  const [forceUpdate, setForce] = useState(0);
  const didEff = useRef(false);

  const [zohoUser, error, isloading] = useFetch("GET", "zoho_update_user_details/", userToken, false, forceUpdate);

  useEffect(() => {
    if (!didEff.current) {
      didEff.current = true;
      return;
    }
    setForce(prev => prev + 1);
  }, [zohoUserIDPresent]);

  useEffect(() => {
    zohoUserIDPresentSET(currentUser && currentUser.zoho_id);
  }, [currentUser]);

  useEffect(() => {
    let zohoUserDetails = zohoUser["user"];
    if (zohoUserDetails && zohoUserDetails.id) {
      zohoUserIDSET(zohoUserDetails.id);
    }
  }, [zohoUser]);

  return (
    <div id={`zoho-user-${zohoUserID}`}>
      <Helmet>
        <script type="text/javascript" nonce={nonce} src="https://desk.zoho.eu/portal/api/web/inapp/102884000000403041?orgId=20081914897" defer></script>
        <script type="text/javascript">
        {
          (token) &&
          `
          window.ZohoHCAsapSettings={
            ticketsSettings : {
              preFillFields : {
                "email" : {
                  defaultValue : "${currentUser.email}"
                },
                "departmentId" : {
                  defaultValue : "102884000000007061"
                },
                "contactId" : {
                  defaultValue : "${currentUser.first_name} ${currentUser.last_name}"
                }
              }
            },
            userInfo: {
              jwtToken : "${token}"
            }
          }
          `
        }
        </script>
      </Helmet>
    </div>
  );
};

export default SubUserZohoASAPWidget;