import React from 'react';

const ControlPauseIcon = ({ fillColor = "#FFFFFF", strokeColor = "#FFFFFF", onClick, className }) => (
  <svg
    viewBox="0 0 73 73"
    height="73px"
    width="73px"
    onClick={onClick}
    className={className}
    xmlSpace="preserve"
    style={{ enableBackground: 'new 0 0 73 73' }}
  >
    <path
      d="M36.501,6.778 c16.433,0,29.753,13.307,29.753,29.721s-13.32,29.724-29.753,29.724c-16.433,0-29.754-13.31-29.754-29.724 C6.747,20.084,20.068,6.778,36.501,6.778z"
      strokeMiterlimit="10"
      strokeWidth="4"
      stroke={strokeColor}
      fill="none"
    />
    <g>
      <path
        d="M29.564,22.629c2.188,0,3.963,1.657,3.963,3.699v20.343c0,2.043-1.774,3.699-3.963,3.699 s-3.963-1.656-3.963-3.699V26.328C25.602,24.286,27.376,22.629,29.564,22.629z"
        fill={fillColor}
      />
      <path
        d="M43.436,22.629c2.188,0,3.963,1.657,3.963,3.699v20.343c0,2.043-1.773,3.699-3.963,3.699 c-2.188,0-3.963-1.656-3.963-3.699V26.328C39.473,24.286,41.246,22.629,43.436,22.629z"
        fill={fillColor}
      />
    </g>
  </svg>
);

export default ControlPauseIcon;