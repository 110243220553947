import React, { useState, useEffect, useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Redirect } from "react-router-dom";

import {
  Card,
  Row,
  CardBody,
  Col,
  FormGroup,
  Label,
  Button,
  Alert,
  CardTitle,
  FormText,
} from "reactstrap";
import { getCookie } from "../../jwt/_helpers/cookie";
import { useFetch } from "../../utils/fetchHook";
import { StateContext } from "../../utils/context";
import TableHelper from "../../components/tableHelper";
import AsyncDropdownNormal from "../../components/asyncDropdownNormal";

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const Schema = Yup.object().shape({
  part: Yup.string()
    .required("Part is required")
    .max(16, "Must be exactly 16 characters"),
});

const MusicLinkUrlpartAdd = ({ match }) => {
  const token = getCookie("token");
  const options = {
    method: "GET",
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": X_API_KEY,
      "Content-Type": "application/json"
    }
  };
  const {currentUser} = useContext(StateContext);
  const currentID = match.url.split("/")[2] === "add" ? null : match.url.split("/")[2];
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorDupl, setErrorDupl] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [loadedAll, setLoadedAll] = useState(() => (currentID ? false : true));

  const [parentUserOnly, setParentUserOnly] = useState('');
  const [subUser, setSubUser] = useState('');
  const [subUserId, setSubUserId] = useState(null);
  const [subUserEndpoint, setSubUserEndpoint] = useState('');
  const [subUserUrlPartAllowance, setSubUserUrlPartAllowance] = useState(0);

  const [urlpartData, loadingurlpartData] = useFetch(
    "GET",
    `musiclinks_urlparts/${currentID}/`,
    token
  );

  const axiosOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": X_API_KEY,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    if (Array.isArray(urlpartData) && urlpartData.length === 0) {
      return;
    }
    setLoadedAll(true);
  }, [urlpartData]);

  const onSubmit = async (payload, { setStatus, setSubmitting }) => {
    setStatus();
    setErrorDupl(false);

    if (subUserId) {
      payload.sub_user_id = subUserId;
    }

    let result = null;
    try {
      if (currentID) {
        await axios({
          url: `${API_URL}musiclinks_urlparts/${currentID}`,
          data: payload,
          ...axiosOptions,
          method: "PUT",
        });
        setRedirect(true);
      } else {
        const isExist = await axios.get(
          `${API_URL}musiclinks_urlparts/?search=${payload.part}`,
          axiosOptions
        );
        if (isExist.status === 200) {
          if (isExist.data.count) {
            setErrorDupl(true);
          } else {
            await axios({
              url: `${API_URL}musiclinks_urlparts/`,
              data: payload,
              ...axiosOptions,
            });
            setRedirect(true);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {loadedAll === false ? (
        <TableHelper loading />
      ) : (
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <CardTitle tag="h4">
                  {currentID ? "Edit" : "Add"} Url Part
                </CardTitle>

                <Formik
                  initialValues={{
                    part: urlpartData.part || "",
                  }}
                  validationSchema={Schema}
                  onSubmit={onSubmit}
                  render={({
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    values,
                    ...rest
                  }) => {
                    return (
                      <Form className="mt-3">

                      { (!currentID && currentUser.is_premium_user)  &&
                        <FormGroup className="select-search-wrap" row>
                          <Label
                            sm={2}
                          >
                            <b>Sub-User</b>
                          </Label>
                          <Col>
                            {
                              subUser ?
                                <div className="releaseFileRow">
                                    <p className="releaseFileRowName"> {subUser}</p>
                                    <Button className="btn btn-outline-info"
                                      onClick={() => {
                                        setSubUser('');
                                        setSubUserId(null);
                                        setSubUserEndpoint('');
                                        setParentUserOnly('');
                                        setSubUserUrlPartAllowance(0);
                                      }}
                                    >
                                      Reset
                                    </Button>
                                </div>
                                :
                              <AsyncDropdownNormal
                                fetchOptions={options}
                                endpoint={`sub-users`}
                                subUserEndpoint={subUserEndpoint}
                                parentUserOnly={parentUserOnly}
                                labelField="username"
                                extraFields={["music_link_urlparts_allowance", "music_link"]}
                                onChange={e => {
                                  let subUserId = e.value;
                                  let username = e.label;
                                  let urlPartAllowance = e.music_link_urlparts_allowance;

                                  if(subUserId !== '') {
                                    setSubUser(username);
                                    setSubUserId(subUserId);
                                    setSubUserEndpoint(`sub-user/${subUserId}/`);
                                    setParentUserOnly('');
                                    setSubUserUrlPartAllowance(urlPartAllowance);
                                  } else {
                                    setSubUser('');
                                    setSubUserId(null);
                                    setSubUserEndpoint('');
                                    setParentUserOnly('');
                                    setSubUserUrlPartAllowance(0);
                                  }
                                }}
                                placeholder="Select Sub-user..."
                              />
                            }
                          <FormText color="muted">
                            Note: Only use this dropdown if you want to Add Music Link for any of your Sub-users otherwise leave un-selected.
                          </FormText>
                          {(subUser && subUserUrlPartAllowance<=0) && (
                            <p
                              style={{
                                fontSize: "12px",
                                color: "red",
                                margin: "2px 0 0 0",
                              }}
                            >
                              This sub-user has consumed the maximum limit of 10 urls parts.
                            </p>
                          )}
                          </Col>
                        </FormGroup>
                      }

                        <FormGroup row>
                          <Label for="part" sm={2}>
                            Part
                          </Label>
                          <Col>
                            <Field
                              name="part"
                              type="text"
                              className={
                                "form-control" +
                                (errors.part && touched.part
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="part"
                              component="div"
                              className="invalid-feedback"
                            />
                            {errorDupl && (
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                  margin: "2px 0 0 0",
                                }}
                              >
                                This Music Link URL is already taken by your account or another user account. Please try something else.
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col>
                            <Button color="success" disabled={subUser && subUserUrlPartAllowance<=0}>Submit</Button>
                          </Col>
                        </FormGroup>
                      </Form>
                    );
                  }}
                />
                {success && (
                  <Alert color="success">
                    Music link urlpart has been {currentID? "updated": "added"}!
                  </Alert>
                )}
                {redirect ? <Redirect to="/music-link-urlpart" /> : null}
                {error && (
                  <Alert color="danger">
                    Something went wrong! Please refresh page and try again!
                  </Alert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default MusicLinkUrlpartAdd;
