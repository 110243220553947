import React, { useState } from "react";
import GoogleLogin from "./socialOauth/googleLogin";

const SocialLogin = () => {

  const [authError, setAuthError] = useState(false);
  const [authErrorMsg, setAuthErrorMsg] = useState("");

  return (
    <>
        <div className="mt-3 text-center social-auth-container">
            <GoogleLogin setAuthError={setAuthError} setAuthErrorMsg={setAuthErrorMsg} />
            {authError &&
                <div className={'alert alert-danger mt-3'}>{authErrorMsg}</div>
            }
        </div>
    </>
  );
};

export default SocialLogin;