import React, { useState, useRef, useEffect } from 'react'


const OtpResendTimer = ({setOtpSendEnabled, secondsRemaining=60, setLastOtpSentOneMinuteAgo}) => {

	const Ref = useRef(null);
	const [timer, setTimer] = useState('00');

	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		return {
			total, seconds
		};
	}

	const startTimer = (e) => {
		let { total, seconds } = getTimeRemaining(e);

        if (seconds === 0) {
			setLastOtpSentOneMinuteAgo(false);
            setOtpSendEnabled(true);
            stopTimer();
        }

        else if (total >= 0) {
			setTimer(
				(seconds > 9 ? seconds : '0' + seconds)
			)
		}
	}

    const stopTimer = () => {
		setTimer('0');
		Ref.current = null;
	}

	const clearTimer = (e) => {
		setTimer('0');

		if (Ref.current) clearInterval(Ref.current);
		const id = setInterval(() => {
			startTimer(e);
		}, 1000)
		Ref.current = id;
	}

	const getDeadTime = () => {
		let deadline = new Date();
		deadline.setSeconds(deadline.getSeconds() + secondsRemaining);
		return deadline;
	}

	useEffect(() => {
		clearTimer(getDeadTime());
	}, []);

	return (
		<div>
			{(timer!=="00" && timer!=="0") && <p>You can request the OTP again in <b>{timer}</b> seconds</p>}
		</div>
	)
}

export default OtpResendTimer;