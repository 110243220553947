import React, { useContext } from "react";
import { Badge } from "reactstrap";
import { StateContext } from "../../../utils/context";

const SubscriptionTier = (props) => {
	const { currentUser } = useContext(StateContext);
	const { subscription_tier_name } = currentUser;

    const subTierBgColorDict = {
        'Standard': '#C0C0C0',
        'Premium': '#0F52BA',
        'Gold': '#FF5733',
    };
    
    const subTierTextColorDict = {
        'Standard': '#0A0A0A',
        'Premium': '#FFFFFF',
        'Gold': '#FFFFFF',
    };
    
    const subTierBgColor = subTierBgColorDict[subscription_tier_name];
    const subTierTextColor = subTierTextColorDict[subscription_tier_name];

	return (
		<a href="/subscription/upgrade/">
            {
                currentUser.subscription_active &&
                <Badge 
                    className="mt-3 mr-3 p-2"
                    style={{
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        backgroundColor: subTierBgColor,
                        color: subTierTextColor
                    }}
                >
                    {subscription_tier_name} 
                    {subscription_tier_name==='Standard' ? <i className="ml-1 mdi mdi-star"></i> : null }
                    {subscription_tier_name==='Premium' ? (<i className="ml-1 mdi mdi-trophy"></i>): null }
                    {subscription_tier_name==='Gold' ? (<i className="ml-1 mdi mdi-crown"></i>): null }
                </Badge>
            }

        </a>
    );
}

export default SubscriptionTier;