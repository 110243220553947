import React, { useState, useCallback, useMemo, useEffect } from "react";
import AsyncSelect from "react-select/async";
import ProfileImg from "../assets/images/users/1.jpg";

const API_URL = process.env.REACT_APP_API_URL_BASE;


const AsyncDropdownSpotify = ({
  value,
  onChange,
  placeholder = "Search...",
  fetchOptions = {},
  fieldName,
  defaultQuery,
  ...props
}) => {
  const [options, setOptions] = useState([]);

  const CustomOption = useMemo(() => {
    return React.memo(({ innerProps, data, isFocused }) => (
      <div {...innerProps} style={{ 
          padding: '3px',
          backgroundColor: isFocused ? '#f5f5f5' : 'transparent',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}>
        <img
          src={data.image_small || ProfileImg}
          alt={data.label}
          style={{
            padding: "5px",
            height: '65px',
            width: '65px',
            borderRadius: '30%',
            marginRight: "7px",
          }}
        />
        <b>{data.label}</b>
      </div>
    ));
  }, []);

  const isSpotifyId = (query) => {
    const spotifyIdPattern = /^[0-9a-zA-Z-_]{22}$/;
    return spotifyIdPattern.test(query);
  };

  const loadOptions = (query, callback) => {
    let queryType = isSpotifyId(query)? 'spotify_id': 'artist_name';
    fetch(`${API_URL}artists/spotify-artist-details/?${queryType}=${query}`, fetchOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if(queryType === "spotify_id") {
          let spotifyImageBig = null;
          let spotifyImageSmall = null;
          if (data.images && data.images.length > 0) {
              spotifyImageBig = data.images[0].url;
              if (data.images.length > 2) {
                spotifyImageSmall = data.images[2].url;
              }
              else if (data.images.length > 1) {
                spotifyImageSmall = data.images[1].url;
              }
              else {
                spotifyImageSmall = null;
              }
          }
          let formattedData = [{
            value: data["id"],
            label: data["name"],
            popularity: data["popularity"], 
            followers: data["followers"]["total"],
            image_big: spotifyImageBig,
            image_small: spotifyImageSmall,
          }];
          setOptions(formattedData);
          callback(formattedData);
        }
        else {
          let formattedData = data.map(item => ({ 
            value: item["id"],
            label: item["name"],
            popularity: item["popularity"], 
            followers: item["followers"]["total"],
            image_big: item["image_big"],
            image_small: item["image_small"],
          }));
          setOptions(formattedData);
          callback(formattedData);
        }
      })
      .catch(error => {
        console.error(error);
        setOptions([]);
        callback([]);
      });
  };

  useEffect(() => {
    if (defaultQuery) {
      loadOptions(defaultQuery, setOptions);
    }
  }, [defaultQuery, loadOptions]);

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedLoadOptions = useCallback(debounce(loadOptions, 300), [loadOptions]);

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={options}
      value={value}
      loadOptions={debouncedLoadOptions}
      onChange={onChange}
      placeholder={placeholder}
      components={{
        Option: CustomOption,
      }}
      styles={{
        menu: styles => ({ ...styles, zIndex: 10 }),
      }}
      {...props}
    />
  );
}

export default AsyncDropdownSpotify;