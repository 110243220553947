const validUrlRegex = /^(?:https?|http):\/\/(?:[^\s\/]+\.)?[^\s\/]+\.[^\s\/]+(?:\/[^\/\s]*)*$/;

const amazonUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?(?:amazon\.[a-z.]+|music\.amazon\.[a-z.]+)\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const appleMusicUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?music\.apple\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const beatportUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?beatport\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const deezerUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?deezer\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const spotifyUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?open\.spotify\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const traxsourceUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?traxsource\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const youtubeUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?youtube\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const tidalUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?(?:tidal\.com|listen\.tidal\.com)(?:\/[^\s\/]*)*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const soundCloudUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?soundcloud\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const junoDownloadUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?junodownload\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;

const getStoreRegex = (storeName) => {
  const regexMap = {
    "Amazon Music": amazonUrlRegex,
    "Apple Music": appleMusicUrlRegex,
    "Beatport": beatportUrlRegex,
    "Deezer": deezerUrlRegex,
    "Junodownload": junoDownloadUrlRegex,
    "Soundcloud": soundCloudUrlRegex,
    "Spotify": spotifyUrlRegex,
    "Tidal": tidalUrlRegex,
    "Traxsource": traxsourceUrlRegex,
    "YouTube": youtubeUrlRegex,
    "Anghami": validUrlRegex,
    "Bandcamp": validUrlRegex,
    "Boomplay": validUrlRegex,
    "iHeartRadio": validUrlRegex,
    "JioSaavn": validUrlRegex,
    "7digital": validUrlRegex,
  };

  return regexMap[storeName] || null;
};

export const getStoreValidators = (storeName) => {
  let storeValidators = {
    validators: {
      notEmpty: {
        message: `${storeName} Url is required.`
      },
      regexp: {
          regexp: getStoreRegex(storeName),
          message: `Please add a valid ${storeName} Url.`,
      },
    }
  };
  return storeValidators;
};


export const pvTrackNameValidators = {
    validators: {
      notEmpty: {
        message: "Track Name is required"
      },
    }
};

export const pvArtistNameValidators = {
    validators: {
      notEmpty: {
        message: "Artist Name is required"
      },
    }
};