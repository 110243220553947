export const spotifyMarketOps = [
   {
      "label":"Albania",
      "value":"AL"
   },
   {
      "label":"Algeria",
      "value":"DZ"
   },
   {
      "label":"Andorra",
      "value":"AD"
   },
   {
      "label":"Angola",
      "value":"AO"
   },
   {
      "label":"Antigua and Barbuda",
      "value":"AG"
   },
   {
      "label":"Azerbaijan",
      "value":"AZ"
   },
   {
      "label":"Argentina",
      "value":"AR"
   },
   {
      "label":"Australia",
      "value":"AU"
   },
   {
      "label":"Austria",
      "value":"AT"
   },
   {
      "label":"Bahamas",
      "value":"BS"
   },
   {
      "label":"Bahrain",
      "value":"BH"
   },
   {
      "label":"Bangladesh",
      "value":"BD"
   },
   {
      "label":"Armenia",
      "value":"AM"
   },
   {
      "label":"Barbados",
      "value":"BB"
   },
   {
      "label":"Belgium",
      "value":"BE"
   },
   {
      "label":"Bhutan",
      "value":"BT"
   },
   {
      "label":"Bolivia",
      "value":"BO"
   },
   {
      "label":"Bosnia and Herzegovina",
      "value":"BA"
   },
   {
      "label":"Botswana",
      "value":"BW"
   },
   {
      "label":"Brazil",
      "value":"BR"
   },
   {
      "label":"Belize",
      "value":"BZ"
   },
   {
      "label":"Solomon Islands",
      "value":"SB"
   },
   {
      "label":"Brunei Darussalam",
      "value":"BN"
   },
   {
      "label":"Bulgaria",
      "value":"BG"
   },
   {
      "label":"Burundi",
      "value":"BI"
   },
   {
      "label":"Belarus",
      "value":"BY"
   },
   {
      "label":"Cambodia",
      "value":"KH"
   },
   {
      "label":"Cameroon",
      "value":"CM"
   },
   {
      "label":"Canada",
      "value":"CA"
   },
   {
      "label":"Cape Verde",
      "value":"CV"
   },
   {
      "label":"Sri Lanka",
      "value":"LK"
   },
   {
      "label":"Chad",
      "value":"TD"
   },
   {
      "label":"Chile",
      "value":"CL"
   },
   {
      "label":"Taiwan",
      "value":"TW"
   },
   {
      "label":"Colombia",
      "value":"CO"
   },
   {
      "label":"Comoros",
      "value":"KM"
   },
   {
      "label":"Republic of the Congo",
      "value":"CG"
   },
   {
      "label":"Democratic Republic of the Congo",
      "value":"CD"
   },
   {
      "label":"Costa Rica",
      "value":"CR"
   },
   {
      "label":"Croatia",
      "value":"HR"
   },
   {
      "label":"Cyprus",
      "value":"CY"
   },
   {
      "label":"Czech Republic",
      "value":"CZ"
   },
   {
      "label":"Benin",
      "value":"BJ"
   },
   {
      "label":"Denmark",
      "value":"DK"
   },
   {
      "label":"Dominica",
      "value":"DM"
   },
   {
      "label":"Dominican Republic",
      "value":"DO"
   },
   {
      "label":"Ecuador",
      "value":"EC"
   },
   {
      "label":"El Salvador",
      "value":"SV"
   },
   {
      "label":"Equatorial Guinea",
      "value":"GQ"
   },
   {
      "label":"Estonia",
      "value":"EE"
   },
   {
      "label":"Fiji the Fiji Islands",
      "value":"FJ"
   },
   {
      "label":"Finland",
      "value":"FI"
   },
   {
      "label":"France",
      "value":"FR"
   },
   {
      "label":"Djibouti",
      "value":"DJ"
   },
   {
      "label":"Gabon",
      "value":"GA"
   },
   {
      "label":"Georgia",
      "value":"GE"
   },
   {
      "label":"Gambia the",
      "value":"GM"
   },
   {
      "label":"Palestinian Territory",
      "value":"PS"
   },
   {
      "label":"Germany",
      "value":"DE"
   },
   {
      "label":"Ghana",
      "value":"GH"
   },
   {
      "label":"Kiribati",
      "value":"KI"
   },
   {
      "label":"Greece",
      "value":"GR"
   },
   {
      "label":"Grenada",
      "value":"GD"
   },
   {
      "label":"Guatemala",
      "value":"GT"
   },
   {
      "label":"Guinea",
      "value":"GN"
   },
   {
      "label":"Guyana",
      "value":"GY"
   },
   {
      "label":"Haiti",
      "value":"HT"
   },
   {
      "label":"Honduras",
      "value":"HN"
   },
   {
      "label":"Hong Kong",
      "value":"HK"
   },
   {
      "label":"Hungary",
      "value":"HU"
   },
   {
      "label":"Iceland",
      "value":"IS"
   },
   {
      "label":"India",
      "value":"IN"
   },
   {
      "label":"Indonesia",
      "value":"ID"
   },
   {
      "label":"Iraq",
      "value":"IQ"
   },
   {
      "label":"Ireland",
      "value":"IE"
   },
   {
      "label":"Israel",
      "value":"IL"
   },
   {
      "label":"Italy",
      "value":"IT"
   },
   {
      "label":"Cote d'Ivoire",
      "value":"CI"
   },
   {
      "label":"Jamaica",
      "value":"JM"
   },
   {
      "label":"Japan",
      "value":"JP"
   },
   {
      "label":"Kazakhstan",
      "value":"KZ"
   },
   {
      "label":"Jordan",
      "value":"JO"
   },
   {
      "label":"Kenya",
      "value":"KE"
   },
   {
      "label":"Korea",
      "value":"KR"
   },
   {
      "label":"Kuwait",
      "value":"KW"
   },
   {
      "label":"Kyrgyz Republic",
      "value":"KG"
   },
   {
      "label":"Lao People's Democratic Republic",
      "value":"LA"
   },
   {
      "label":"Lebanon",
      "value":"LB"
   },
   {
      "label":"Lesotho",
      "value":"LS"
   },
   {
      "label":"Latvia",
      "value":"LV"
   },
   {
      "label":"Liberia",
      "value":"LR"
   },
   {
      "label":"Libyan Arab Jamahiriya",
      "value":"LY"
   },
   {
      "label":"Liechtenstein",
      "value":"LI"
   },
   {
      "label":"Lithuania",
      "value":"LT"
   },
   {
      "label":"Luxembourg",
      "value":"LU"
   },
   {
      "label":"Macao",
      "value":"MO"
   },
   {
      "label":"Madagascar",
      "value":"MG"
   },
   {
      "label":"Malawi",
      "value":"MW"
   },
   {
      "label":"Malaysia",
      "value":"MY"
   },
   {
      "label":"Maldives",
      "value":"MV"
   },
   {
      "label":"Mali",
      "value":"ML"
   },
   {
      "label":"Malta",
      "value":"MT"
   },
   {
      "label":"Mauritania",
      "value":"MR"
   },
   {
      "label":"Mauritius",
      "value":"MU"
   },
   {
      "label":"Mexico",
      "value":"MX"
   },
   {
      "label":"Monaco",
      "value":"MC"
   },
   {
      "label":"Mongolia",
      "value":"MN"
   },
   {
      "label":"Moldova",
      "value":"MD"
   },
   {
      "label":"Montenegro",
      "value":"ME"
   },
   {
      "label":"Morocco",
      "value":"MA"
   },
   {
      "label":"Mozambique",
      "value":"MZ"
   },
   {
      "label":"Oman",
      "value":"OM"
   },
   {
      "label":"Namibia",
      "value":"NA"
   },
   {
      "label":"Nauru",
      "value":"NR"
   },
   {
      "label":"Nepal",
      "value":"NP"
   },
   {
      "label":"Netherlands",
      "value":"NL"
   },
   {
      "label":"Curaçao",
      "value":"CW"
   },
   {
      "label":"Vanuatu",
      "value":"VU"
   },
   {
      "label":"New Zealand",
      "value":"NZ"
   },
   {
      "label":"Nicaragua",
      "value":"NI"
   },
   {
      "label":"Niger",
      "value":"NE"
   },
   {
      "label":"Nigeria",
      "value":"NG"
   },
   {
      "label":"Norway",
      "value":"NO"
   },
   {
      "label":"Micronesia",
      "value":"FM"
   },
   {
      "label":"Marshall Islands",
      "value":"MH"
   },
   {
      "label":"Palau",
      "value":"PW"
   },
   {
      "label":"Pakistan",
      "value":"PK"
   },
   {
      "label":"Panama",
      "value":"PA"
   },
   {
      "label":"Papua New Guinea",
      "value":"PG"
   },
   {
      "label":"Paraguay",
      "value":"PY"
   },
   {
      "label":"Peru",
      "value":"PE"
   },
   {
      "label":"Philippines",
      "value":"PH"
   },
   {
      "label":"Poland",
      "value":"PL"
   },
   {
      "label":"Portugal",
      "value":"PT"
   },
   {
      "label":"Guinea-Bissau",
      "value":"GW"
   },
   {
      "label":"Timor-Leste",
      "value":"TL"
   },
   {
      "label":"Qatar",
      "value":"QA"
   },
   {
      "label":"Romania",
      "value":"RO"
   },
   {
      "label":"Rwanda",
      "value":"RW"
   },
   {
      "label":"Saint Kitts and Nevis",
      "value":"KN"
   },
   {
      "label":"Saint Lucia",
      "value":"LC"
   },
   {
      "label":"Saint Vincent and the Grenadines",
      "value":"VC"
   },
   {
      "label":"San Marino",
      "value":"SM"
   },
   {
      "label":"Sao Tome and Principe",
      "value":"ST"
   },
   {
      "label":"Saudi Arabia",
      "value":"SA"
   },
   {
      "label":"Senegal",
      "value":"SN"
   },
   {
      "label":"Serbia",
      "value":"RS"
   },
   {
      "label":"Seychelles",
      "value":"SC"
   },
   {
      "label":"Sierra Leone",
      "value":"SL"
   },
   {
      "label":"Singapore",
      "value":"SG"
   },
   {
      "label":"Slovakia (Slovak Republic)",
      "value":"SK"
   },
   {
      "label":"Vietnam",
      "value":"VN"
   },
   {
      "label":"Slovenia",
      "value":"SI"
   },
   {
      "label":"South Africa",
      "value":"ZA"
   },
   {
      "label":"Zimbabwe",
      "value":"ZW"
   },
   {
      "label":"Spain",
      "value":"ES"
   },
   {
      "label":"Suriname",
      "value":"SR"
   },
   {
      "label":"Swaziland",
      "value":"SZ"
   },
   {
      "label":"Sweden",
      "value":"SE"
   },
   {
      "label":"Switzerland",
      "value":"CH"
   },
   {
      "label":"Tajikistan",
      "value":"TJ"
   },
   {
      "label":"Thailand",
      "value":"TH"
   },
   {
      "label":"Togo",
      "value":"TG"
   },
   {
      "label":"Tonga",
      "value":"TO"
   },
   {
      "label":"Trinidad and Tobago",
      "value":"TT"
   },
   {
      "label":"United Arab Emirates",
      "value":"AE"
   },
   {
      "label":"Tunisia",
      "value":"TN"
   },
   {
      "label":"Turkey",
      "value":"TR"
   },
   {
      "label":"Tuvalu",
      "value":"TV"
   },
   {
      "label":"Uganda",
      "value":"UG"
   },
   {
      "label":"Ukraine",
      "value":"UA"
   },
   {
      "label":"Macedonia",
      "value":"MK"
   },
   {
      "label":"Egypt",
      "value":"EG"
   },
   {
      "label":"United Kingdom of Great Britain & Northern Ireland",
      "value":"GB"
   },
   {
      "label":"Tanzania",
      "value":"TZ"
   },
   {
      "label":"United States of America",
      "value":"US"
   },
   {
      "label":"Burkina Faso",
      "value":"BF"
   },
   {
      "label":"Uruguay",
      "value":"UY"
   },
   {
      "label":"Uzbekistan",
      "value":"UZ"
   },
   {
      "label":"Venezuela",
      "value":"VE"
   },
   {
      "label":"Samoa",
      "value":"WS"
   },
   {
      "label":"Zambia",
      "value":"ZM"
   }
];