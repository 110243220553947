import React, { useState, useContext } from "react";
import {
    Row,
    Col,
    Button,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { StateContext } from "../../utils/context";
import axios from "axios";
import { getCookie } from "../../jwt/_helpers";
import LoaderInner from "../../components/LoaderInner";

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const euCountryCodes = ["AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "EL", "ES", "FI", "FR", "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PL", "PT", "RO", "SE", "SI", "SK", "GB", "GR"];

const isAnEuCountry = (countryCode) => {
  countryCode = countryCode.toUpperCase();
  return euCountryCodes.includes(countryCode);
};

const isFromGermany = (countryCode) => {
    countryCode = countryCode.toUpperCase();
    return countryCode === "DE";
};

const StepThree = ({formData, jumpToStep}) => {
    const token = getCookie().token;
    const { currentUser, setCurrentUser } = useContext(StateContext);

    const [success, setSuccess] = useState(false);
    const [errorPut, setError] = useState(false);
    const [openModal, setToggleModal] = useState(false);
    const [submissionInProgress, setSubmissionInProgress] = useState(false);

    const isSoleProprietor = formData.businessType.value === 0;
    const isEuCountry = isAnEuCountry(
        isSoleProprietor
            ? (formData.personalCountry ? formData.personalCountry.value : "")
            : (formData.businessCountry ? formData.businessCountry.value : "")
    );
    const isGermanCustomer = isFromGermany(
        isSoleProprietor
            ? (formData.personalCountry ? formData.personalCountry.value : "")
            : (formData.businessCountry ? formData.businessCountry.value : "")
    );
    const saveUserInfo = () => {

        const dataObject = {
            "first_name": formData.firstName,
            "last_name": formData.lastName,
            "street_and_number": isSoleProprietor? formData.personalStreet: formData.businessStreet,
            "postal_code": isSoleProprietor? formData.personalZip: formData.businessZip,
            "city": isSoleProprietor? formData.personalCity: formData.businessCity,
            "country": isSoleProprietor? formData.personalCountry.value: formData.businessCountry.value,
            "company": !isSoleProprietor? formData.companyName: `${formData.firstName} ${formData.lastName}`,
            "ceo_firstname": !isSoleProprietor? formData.firstName: "",
            "ceo_lastname": !isSoleProprietor? formData.lastName: "",
            "business_type": formData.businessType.value,
            "vat": isEuCountry? formData.vatNumber: "",
            "national_tax_number": formData.taxNumber,
            "user_input_business_certificate": !isSoleProprietor? formData.tradeRegistryExport: null,
            "trade_registry_company_url": !isSoleProprietor? formData.tradeRegistryCompanyUrl: "",
            ...(isSoleProprietor ? {
                "billing_first_name": formData.firstName,
                "billing_last_name": formData.lastName,
                "billing_company": ((isEuCountry && !isGermanCustomer && formData.vatNumber !== "") || isGermanCustomer ? `${formData.firstName} ${formData.lastName}` : ""),
                "billing_street_and_number": formData.personalStreet,
                "billing_postal_code": formData.personalZip,
                "billing_city": formData.personalCity,
                "billing_country": formData.personalCountry.value,
                "billing_vat": isEuCountry? formData.vatNumber: "",
            } : {
                "billing_first_name": formData.firstName,
                "billing_last_name": formData.lastName,
                "billing_company": ((isEuCountry && !isGermanCustomer && formData.vatNumber !== "") || isGermanCustomer ? formData.companyName : ""),
                "billing_street_and_number": ((isEuCountry && !isGermanCustomer && formData.vatNumber !== "") || isGermanCustomer) ? formData.businessStreet : formData.personalStreet,
                "billing_postal_code": ((isEuCountry && !isGermanCustomer && formData.vatNumber !== "") || isGermanCustomer) ? formData.businessZip : formData.personalZip,
                "billing_city": ((isEuCountry && !isGermanCustomer && formData.vatNumber !== "") || isGermanCustomer) ? formData.businessCity : formData.personalCity,
                "billing_country": ((isEuCountry && !isGermanCustomer && formData.vatNumber !== "") || isGermanCustomer) ? formData.businessCountry.value : formData.personalCountry.value,
                "billing_vat": isEuCountry ? formData.vatNumber : "",
            }),
            "basic_info_submitted": true,
        };
        
        setSubmissionInProgress(true);

        axios({
            method: "PATCH",
            url: `${API_URL}users/${currentUser.id}/`,
            data: dataObject,
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${token}`,
                "x-api-key": X_API_KEY,
            }
        }).then(function(response) {
            setSubmissionInProgress(false);
            if (response.status === 200) {
                setToggleModal(false);
                setCurrentUser(response.data);
                jumpToStep(4);
                setSuccess(true);
            } else {
                setError(true);   
            }
        }).catch(function(error) {
            setError(true);
        });
    }

    return (
        <div className="step step3 mt-5 ">
            <Row className="justify-content-md-center">
                <Col lg="6">
                    <div>
                        <h6>Personal Information:</h6>
                        <hr/>
                        <p>
                            <b>Name</b>
                            <br/>
                            {formData.firstName} {formData.lastName}
                        </p>

                        <p>
                            <b>Address</b>
                            <br/>
                            {formData.personalStreet}, {formData.personalCity}, {formData.personalZip}, {formData.personalCountry.label}
                        </p>
                    </div>
                    
                    <br/>

                    <div>
                        <h6>Business Information:</h6>
                        <hr/>
                        
                        {
                            formData.businessType.value !== 0 && <>
                            
                            <p>
                                <b>Company</b>
                                <br/>
                                {formData.companyName}
                            </p>

                            <p>
                                <b>CEO Name</b>
                                <br/>
                                {formData.ceoFirstName} {formData.ceoLastName}
                            </p>

                            </>
                        }

                        {
                            formData.businessType.value !== 0 && <>
                            
                            <p>
                                <b>Address</b>
                                <br/>
                                {formData.businessStreet}, {formData.businessCity}, {formData.businessZip}, {formData.businessCountry.label}
                            </p>

                            </>
                        }


                        <p>
                            <b>Business Type</b>
                            <br/>
                            {formData.businessType.label}
                        </p>

                        <p>
                            <b>Tax Number</b>
                            <br/>
                            {formData.taxNumber? formData.taxNumber: "N/A"}
                        </p>
                        

                        {
                            !isSoleProprietor && <>
                            <p>
                                <b>VAT Number</b>
                                <br/>
                                {formData.vatNumber? formData.vatNumber: "N/A"}
                            </p>

                            {
                                formData.tradeRegistryExport &&
                                <p>
                                    <b>Trade Registry Export Uploaded</b>&nbsp;
                                    <i className="fas fa-check-circle fa-lg text-success"></i>
                                </p>
                            }

                            <p>
                                <b>Trade Registry Company Url</b>
                                <br/>
                                {formData.tradeRegistryCompanyUrl? formData.tradeRegistryCompanyUrl: "N/A"}
                            </p>

                            </>
                        }
                    </div>
                
                    <div style={{ paddingTop: "30px" }}>
                        {success && (
                            <Alert color="success">Your information has been updated!</Alert>
                        )}
                        {errorPut && (
                            <Alert color="danger">
                                Something went wrong! Please refresh page and try again!
                            </Alert>
                        )}
                    </div>
                </Col>
            </Row>
            <Button
                className="btn btn-prev btn-primary pull-left prevButton"
                style={{color: "#ffffff", backgroundColor: "#000a60"}}
                onClick={() => {
                    jumpToStep(2)
                }}
            >
                Previous
            </Button>
            <Button
                className="btn btn-next btn-primary pull-right"
                style={{color: "#ffffff", backgroundColor: "#000a60"}}
                type="submit"
                disabled={success}
                onClick={setToggleModal}
            >
                Save
            </Button>
            {
                success &&
                <Button
                    className="btn btn-next btn-primary pull-right"
                    style={{color: "#ffffff", backgroundColor: "#000a60"}}
                    type="submit"
                    onClick={() => {
                        jumpToStep(4);
                    }}
                >
                    Next
                </Button>
            }
            <LoaderInner show={submissionInProgress} />
            <Modal isOpen={openModal} centered={true}>
                <ModalHeader>Submit Your Information</ModalHeader>
                <ModalBody>
                    By clicking <b>"Confirm"</b>, you are affirming that all the information you have provided is accurate and truthful to the best of your knowledge. Please be aware that the submission of false or misleading information may result in significant consequences, including but not limited to, service termination, legal actions, or penalties. It is your responsibility to ensure that all data submitted reflects your current and true circumstances.
                    <br/><br/>
                    Do you confirm that all the details provided are correct and acknowledge the potential consequences of submitting inaccurate information?
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setToggleModal(false)}>
                    Cancel
                    </Button>
                    <Button color="success" disabled={submissionInProgress} onClick={saveUserInfo}>
                    Confirm
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default StepThree;