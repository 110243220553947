import React, { useEffect, useState, useRef, useContext } from "react";
import Pagination from '../../components/pagination/pagination';
import SubscriptionTable from "../../components/subscriptionTable";
import ViewLayout from "../../components/viewLayout";
import { useFetch } from "../../utils/fetchHook";
import { getCookie } from "../../jwt/_helpers/cookie";
import TableHelper from "../../components/tableHelper";
import { Alert } from "reactstrap";
import SubscriptionTableModal from "../../components/subscriptionTableModal";
import SubscriptionTableModalResume from "../../components/subscriptionTableModalResume";
import SubscriptionTableModalRetryPayment from "../../components/subscriptionTableModalRetryPayment";
import {StateContext} from "../../utils/context";

const Subscription = () => {
  const token = getCookie("token");
  const {currentUser} = useContext(StateContext);

  const didEff1 = useRef(false);
  const didEff2 = useRef(false);
  const [forceUpdate, setForce] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [currrentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [query, setQuery] = useState('');
  const [ordering, setOrdering] = useState({order: '-', name: 'created', sort: 'created'});

  const [apiData, error, loading] = useFetch("GET", `subscriptions/?ordering=${ordering.order + ordering.name}&page=${currrentPage}&page_size=${pageSize}&search=${query}`, token, false, forceUpdate);
  const [tableData, setTableData] = useState([]);

  const [openModal, setToggleModal] = useState(false);
  const [openModalResume, setToggleModalResume] = useState(false);
  const [openModalRetryPayment, setToggleModalRetryPayment] = useState(false);
  const [dataModal, setDataModal] = useState(null);
  const [lastAction, setLastAction] = useState("");

  //ajax handlers
  const [success, setSuccess] = useState(false);
  const [errorPut, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handlerValues = { setSuccess, setError, setErrorMsg, forceUpdate, setForce };

  useEffect(() => {
    setTableData(apiData.results);
  }, [apiData]);

  useEffect(() => {
    setTableData(apiData.results);
    setDataCount(apiData.count);
  }, [apiData]);

  useEffect(() => {
    if (!didEff1.current) {
      didEff1.current = true;
      return;
    }
    setForce(prev => prev + 1);
  }, [currrentPage, pageSize, ordering.order, ordering.name]);


  useEffect(() => {
    if (!didEff2.current) {
      didEff2.current = true;
      return;
    }
    const timeout = setTimeout(() => {
      setForce(prev => prev + 1);
    }, 500)
    return () => clearTimeout(timeout)
  }, [query])


  const paginationProps = {
    pageSize, setPageSize, dataCount, setCurrentPage, currrentPage
  }

  const defaultSorted = [{
    dataField: ordering.sort,
    order: ordering.order === "-" ? "desc" : "asc"
  }] 

  const tableProps = {
    tableData,
    defaultSorted
  };

  return (
    <>
      {
        (currentUser && currentUser.has_subscription) ? <>
        {
        loading ? (
          <TableHelper loading />
        ) : error ? (
          <TableHelper error />
        ) : (
        <div>
          <ViewLayout title={"Your Subscription"}>
            <SubscriptionTable 
              {...tableProps} 
              searchQuery={{setQuery, query, ordering, setOrdering}}
              setToggleModal={setToggleModal}
              setToggleModalResume={setToggleModalResume}
              setToggleModalRetryPayment={setToggleModalRetryPayment}
              setDataModal={setDataModal}
              setSuccess={setSuccess}
              setError={setError}
              setLastAction={setLastAction}
            />
          </ViewLayout>
          <Pagination pagination={paginationProps}/>
        </div>
        )}

        {dataModal && (
          <>
            <SubscriptionTableModal
              openModal={openModal}
              setToggleModal={setToggleModal}
              values={handlerValues}
              title={
                (dataModal.cancel_at_period_end) ? `Enable Auto Renewal` : `Cancel Subscription`
              }
              body={
                (dataModal.cancel_at_period_end) ? `Are you sure that you want to enable auto-renewal for your subscription ?`
                : `Are you sure that you want to cancel your subscription?`
              }
              apiURL={'subscriptions/update'}
              status={dataModal.cancel_at_period_end}
              subID={dataModal.id}
            />
            <SubscriptionTableModalResume
              openModal={openModalResume}
              setToggleModal={setToggleModalResume}
              values={handlerValues}
              apiURL={'subscriptions/update'}
              subID={dataModal.id}
            />
            <SubscriptionTableModalRetryPayment
              openModal={openModalRetryPayment}
              setToggleModal={setToggleModalRetryPayment}
              values={handlerValues}
              apiURL={'subscriptions/update'}
              subID={dataModal.id}
            />
          </>
        )}
        {success && <Alert color="success">Your subscription has been {lastAction}!</Alert>}
        {errorPut && (
          <Alert color="danger">
            {(errorMsg === "Your card has expired.") ? (
              <span>
                Your card has expired. Please add a valid default <a href="/payment-methods">payment method</a> and retry payment again.
              </span>
            ): (errorMsg === "This customer has no attached payment source or default payment method. Please consider adding a default payment method. For more information, visit https://stripe.com/docs/billing/subscriptions/payment-methods-setting#payment-method-priority.") ? (
              <span>
                You do not have any <a href="/payment-methods">payment method</a> added to your profile. Please add a valid default <a href="/payment-methods">payment method</a> and retry payment again.
              </span> 
            ): (
              "Subscription payment failed please try again later!"
            )}
          </Alert>
        )}
      </> :
      <Alert color="danger">
        You don't have access to this module, please contact the support team for further information.
      </Alert>
      }
    </>
  );
};

export default Subscription;