import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Input, FormGroup, Label, Button, Spinner } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { authenticationService } from "../jwt/_services";

import "./otpModal.css";

const OtpModal = ({ isOpen, setOpen, user, defaultTwoFactorMethod, availableTwoFactorMethods }) => {
    const history = useHistory();
    const location = useLocation();

    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [resendTimer, setResendTimer] = useState(0);
    const [showAlternateMethods, setShowAlternateMethods] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState("");

    const RESEND_TIMEOUT = 60;

    useEffect(() => {
        const lastSentTime = localStorage.getItem("lastOtpSentTime");
        if (lastSentTime) {
            const timeElapsed = Math.floor((Date.now() - lastSentTime) / 1000);
            if (timeElapsed < RESEND_TIMEOUT) {
                setResendTimer(RESEND_TIMEOUT - timeElapsed);
            }
        }
    }, []);

    useEffect (() => {
        if(defaultTwoFactorMethod) {
            setSelectedMethod(defaultTwoFactorMethod);
        }
    }, [defaultTwoFactorMethod])

    useEffect(() => {
        let timer;
        if (resendTimer > 0) {
            timer = setTimeout(() => {
                setResendTimer(resendTimer - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [resendTimer]);

    const handleOtpVerification = async () => {
        if (!otp) {
            setError('OTP cannot be empty.');
            return;
        }

        const isDigitOnlyOtp = /^[0-9]+$/.test(otp);
        const isTenCharOtp = /^[A-Za-z0-9]{10}$/.test(otp);

        if (!isDigitOnlyOtp && !isTenCharOtp) {
            setError('Invalid OTP');
            return;
        }

        setLoading(true);
        setError('');
        setSuccess('');

        authenticationService.validateOTP(user, otp, selectedMethod).then(
            user => {
                setSuccess('OTP verified successfully!');
                setLoading(false);
                toggleModal();
                localStorage.removeItem("lastOtpSentTime");
                const { from } = location.state || { from: { pathname: "/" } };
                history.push(from);
            },
            error => {
                setError(error || 'Invalid OTP. Please try again.');
                setLoading(false);
            }
        );
    };

    const toggleModal = () => {
        setOpen(!isOpen);
    };

    const handleResendOtp = () => {
        setLoading(true);
        setError('');
        setSuccess('');

        authenticationService.request2FAToken(user, selectedMethod).then(
            response => {
                setSuccess('OTP resent successfully!');
                setLoading(false);
                setResendTimer(RESEND_TIMEOUT);
                localStorage.setItem("lastOtpSentTime", Date.now());
            },
            error => {
                setError(error || 'Failed to resend OTP. Please try again.');
                setLoading(false);
            }
        );
    };

    const handleMethodChange = (event) => {
        setError('');
        setSuccess('');
        let twoFactorMethod = event.target.value;
        if (twoFactorMethod === "email") {
            setLoading(true);
            authenticationService.request2FAToken(user, "email").then(
                response => {
                    setSelectedMethod(twoFactorMethod);
                    setSuccess('OTP sent on the Email!');
                    setLoading(false);
                    setResendTimer(RESEND_TIMEOUT);
                    localStorage.setItem("lastOtpSentTime", Date.now());
                },
                error => {
                    setError(error || 'Failed to send OTP. Please try again.');
                    setLoading(false);
                }
            );
        } else {
            setSelectedMethod(twoFactorMethod);
        }
    };

    const Alternate2FAMethods = () => {
        return (
            <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    {availableTwoFactorMethods.includes("auth") && (
                        <label style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <input
                                type="radio"
                                name="method"
                                value="auth"
                                checked={selectedMethod === "auth"}
                                onChange={handleMethodChange}
                                style={{ marginRight: "5px" }}
                            />
                            Authenticator App
                        </label>
                    )}

                    {availableTwoFactorMethods.includes("email") && (
                        <label style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <input
                                type="radio"
                                name="method"
                                value="email"
                                checked={selectedMethod === "email"}
                                onChange={handleMethodChange}
                                style={{ marginRight: "5px" }}
                            />
                            Email
                        </label>
                    )}

                    <label style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <input
                            type="radio"
                            name="method"
                            value="backup"
                            checked={selectedMethod === "backup"}
                            onChange={handleMethodChange}
                            style={{ marginRight: "5px" }}
                        />
                        Backup Code
                    </label>
                </div>
            </div>
        );
    };


    return (
        <Modal isOpen={isOpen} toggle={toggleModal} className="otp-modal-centered">
            <ModalHeader toggle={toggleModal}>Enter OTP</ModalHeader>
            <ModalBody>
                {loading && <Spinner />}
                {error && <p className="text-danger">{error}</p>}
                {success && <p className="text-success">{success}</p>}
                <p>
                    Please enter {" "}
                    {selectedMethod === "email" && "the OTP code sent on your email."}
                    {selectedMethod === "auth" && "the OTP code available on your Authenticator/TOTP app."}
                    {selectedMethod === "backup" && "your backup code."}
                </p>

                <FormGroup>
                    <Input
                        type="text"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        disabled={loading}
                    />
                </FormGroup>
                <Button
                    color="primary"
                    className="float-right"
                    onClick={handleOtpVerification}
                    disabled={loading || !otp}
                >
                    Verify OTP
                </Button>

                {
                    selectedMethod === "email" &&
                    <div className="mt-3">
                        <Button
                            color="secondary"
                            onClick={handleResendOtp}
                            disabled={resendTimer > 0 || loading}
                        >
                            {resendTimer > 0 ? `Resend OTP in ${resendTimer}s` : 'Resend OTP'}
                        </Button>
                    </div>
                }
                <div className="mt-3">
                    <p
                        className="link"
                        style={{ cursor: "pointer", color: "rgb(0, 10, 96)" }}
                        onClick={() => setShowAlternateMethods(!showAlternateMethods)}
                    >
                        Try Other Methods
                    </p>
                    {showAlternateMethods && Alternate2FAMethods()}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default OtpModal;