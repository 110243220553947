import React, { useState, useEffect } from "react";
import axios from "axios";
import { FormGroup, FormText, CustomInput, Button, Alert } from "reactstrap";
import { getCookie } from "../jwt/_helpers";
import { getUser } from "../utils/getUser";
import whtLicenceForm from "../assets/documents/obrazac-licence.pdf";
import './witholdingTax.css';

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const generateRandomHex = (length) => {
    return [...Array(length)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join('');
}

export const whtLicenseUpload = (file, token) => {
    const fileName = generateRandomHex(10);
    const fileType = "application/pdf";
    const fileExtension = "pdf";
  
    return axios({
      method: "GET",
      url: `${API_URL}obtain-signed-url-for-upload/?filename=media/users/wht_licences/${fileName}.${fileExtension}&filetype=${fileType}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": X_API_KEY,
      }
    }).then(response => {
      const URL = response.data.signed_url.url;
      const full_URL= response.data.signed_url.fields.key;
      let split = full_URL.split('/');
      split.shift();
      let transformedURL = split.join('/');
  
      const signedOpts = response.data.signed_url.fields;
      const options = {
        mode: 'cors',
        headers: {
          "Content-Type": fileType,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          document.getElementById("uploadProgress_whtl").style.width = percentCompleted + '%';
          document.getElementById("uploadProgress_text_whtl").innerHTML = percentCompleted + '%'; 
        },
      };
      const postData = new FormData();
      for (let i in signedOpts) {
        postData.append(i, signedOpts[i]);
      }
      postData.append("file", file);
      
      return axios
        .post(URL, postData, options)
        .then(() => transformedURL)
        .catch(() => null);
    });
}

const truncateFileName = (filename) => {
  return filename.length > 40 ? `...${filename.slice(-40)}` : filename;
};

const WithholdingTax = ({ currentUser, setCurrentUser }) => {
  const tokenAPI = getCookie("token");
  const [fileUploadDone, setFileUploadDone] = useState(false);
  const [fileUploadInProgress, setFileUploadInProgress] = useState(false);
  const [whtLicenseURL, whtLicenseURLSET] = useState(null);
  const [whtLicenseFilename, whtLicenseFilenameSET] = useState(null);
  const [whtRate, whtRateSET] = useState(null);
  const [whtMessage, whtMessageSET] = useState(null);
  const [whtLicenceRequired, whtLicenceRequiredSET] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmitVisible, setIsSubmitVisible] = useState(false);

  const handleSubmit = () => {
    const dataModal = { wht_licence: whtLicenseURL };

    axios({
      method: "PATCH",
      url: `${API_URL}users/${currentUser.id}/`,
      data: dataModal,
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${tokenAPI}`,
        "x-api-key": X_API_KEY,
      }
    }).then(response => {
      if (response.status === 200) {
        setSuccess(true);
        setIsSubmitVisible(false);
        axios({
          method: "GET",
          url: `${API_URL}users/${currentUser.id}/`,
          mode: 'cors',
          headers: {
            Authorization: `Bearer ${tokenAPI}`,
            "x-api-key": X_API_KEY,
          }
        }).then(response => {
          if (response.status === 200) {
            setCurrentUser(response.data);
          }
        });
      } else {
        setError(true);
      }
    });
  };

  const handleRemoveFile = () => {
    whtLicenseURLSET(null);
    whtLicenseFilenameSET(null);
    setFileUploadDone(false);
    setIsSubmitVisible(true);
  };

  useEffect(() => {
    getUser(tokenAPI, currentUser, setCurrentUser);
  }, [success]);

  useEffect(() => {
    if(currentUser && currentUser.user_wht_rate) {
        let whtDetails = currentUser.user_wht_rate;
        whtRateSET(whtDetails["wht_pct_str"]);
        whtMessageSET(whtDetails["wht_message"]);
        if(whtDetails["wht"] !== 0 || !currentUser.wht_reduced) {
            whtLicenceRequiredSET(true);
        }
    }

    if(currentUser.wht_licence) {
      let whtLicenceFilename = currentUser.wht_licence;
      whtLicenceFilename = whtLicenceFilename.split("?AWSAccessKeyId")[0];
      whtLicenceFilename = whtLicenceFilename.split('/').pop();
      whtLicenceFilename = truncateFileName(whtLicenceFilename);
      whtLicenseFilenameSET(whtLicenceFilename);
      setFileUploadDone(true);
      setIsSubmitVisible(false);
    }
  }, [currentUser]);

  return (
    <div>
       <p><strong>Tax Percentage:</strong> {whtRate}</p>
       {
            !whtLicenceRequired && <p>
                <strong>WHT Status:</strong><br/>{whtMessage}
            </p>
       }
    
        {
            whtLicenceRequired &&
            <>
                <p><strong>WHT Status:</strong><br/>According to Article 31 of the Croatian Profit Tax Law, a withholding tax of 15-25% is applied to non-residents. However, if you reside in a country that has an Agreement for the Avoidance of Double Taxation with Croatia, you may qualify for a reduced tax rate. You can check if your country is listed on <a href="https://www.porezna-uprava.hr/en/EN_porezni_sustav/Pages/double_taxation.aspx" target="_blank" rel="noopener noreferrer"><strong>this link</strong></a>.
                </p>
                <p>
                  To apply for the reduced rate, please click on <a href={whtLicenceForm} target="_blank" rel="noopener noreferrer"><strong>this document</strong></a>.
                  Download, complete, and submit the form to benefit from the rate specified in the agreement if eligible.
                </p>

                <FormGroup className="mt-3">
                    {whtLicenseFilename ? (
                      <div>
                        <p><strong>WHT License:</strong><br/>{whtLicenseFilename}{" "}<p style={{ fontWeight: 'bold', cursor: 'pointer', display: 'inline', float: 'right' }} onClick={handleRemoveFile}>Remove?</p></p>
                      </div>
                    ) : (
                        <>
                          <CustomInput
                            type="file"
                            id="whtLicense"
                            name="whtLicense"
                            accept=".pdf"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file && !fileUploadDone) {
                                    setFileUploadInProgress(true);
                                    whtLicenseUpload(file, tokenAPI)
                                    .then(whtLicenseURL => {
                                      let whtLicenceFilename = whtLicenseURL;
                                      whtLicenceFilename = whtLicenceFilename.replace("users/wht_licences/", "");
                                      whtLicenceFilename = truncateFileName(whtLicenceFilename);
                                      whtLicenseFilenameSET(whtLicenceFilename);
                                      whtLicenseURLSET(whtLicenseURL)
                                    })
                                    .finally(() => {
                                      setFileUploadInProgress(false);
                                      setFileUploadDone(true);
                                      setIsSubmitVisible(true);
                                    });
                                }
                            }}
                            disabled={fileUploadInProgress}
                          />
                          <div className="whtLicenseUploadContainer">
                              <div className="whtLicenseUpload" id="uploadProgress_whtl"></div>
                              <div className="whtLicenseUploadProgressDiv">
                                <span id="uploadProgress_text_whtl"></span>
                              </div>
                          </div>
                          <FormText color="muted">Please upload your withholding tax document.</FormText>
                        </>
                    )}

                    {isSubmitVisible && (
                      <Button
                        className="mt-3"
                        color="success"
                        onClick={handleSubmit}
                        disabled={!whtLicenseURL || fileUploadInProgress}
                      >
                        Submit
                      </Button>
                    )}
                      
                    <div className="mt-3">
                      {success && <Alert color="success">WHT Licence submitted successfully. It will be processed by our team.</Alert>}
                      {error && <Alert color="danger">An error occurred while updating. Please try again.</Alert>}
                    </div>
                </FormGroup>
            </>
        }
    </div>
  );
};

export default WithholdingTax;