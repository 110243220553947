import React, { useState, useContext, useEffect } from "react";
import {
    Row,
    Col,
    Button,
    Alert,
    FormGroup,
    FormText,
    Label,
    Input,
    CustomInput,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from "reactstrap";
import { StateContext } from "../../utils/context";
import axios from "axios";
import { getCookie } from "../../jwt/_helpers";
import { getUser } from "../../utils/getUser";
import OtpResendTimer from "../../components/otpResendTimer";

import PhoneInput from "react-phone-input-2";
import startsWith from 'lodash.startswith';
import 'react-phone-input-2/lib/style.css';

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const StepFour = ({jumpToStep}) => {
    const token = getCookie().token;
    const { currentUser, setCurrentUser } = useContext(StateContext);

    const isFormUSorCanada = (currentUser && (currentUser.country.toLowerCase() === "us" || currentUser.country.toLowerCase() === "ca"));

    const options = {
      method: "POST",
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": X_API_KEY,
        "Content-Type": "application/json"
      }
    };
  
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [otpSendEnabled, setOtpSendEnabled] = useState(true);
    const [otpSendLimitExhasuted, setOtpSendLimitExhasuted] = useState(false);
    const [mobileUpdateLimitExhasuted, setmobileUpdateLimitExhasuted] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [mobileNumberValid, setMobileNumberValid] = useState(false);
    const [mobileNumberDifferent, setMobileNumberDifferent] = useState(false);
    const [lastOtpSentOneMinuteAgo, setLastOtpSentOneMinuteAgo] = useState(false);
    const [secondsRemainingUntilNewOtp, setSecondsRemainingUntilNewOtp] = useState(60);
    const [messageMedium, setMessageMedium] = useState(isFormUSorCanada? "wa": "sms");
    const [modelOpen, setToggleModal] = useState(false);
    const [forceUpdate, setForce] = useState(1);
  
    let dataModal = {
      action: "validate",
      otp: otpCode,
      mobile: mobileNumber,
    };
  
    const handleSubmit = () => {
  
      setError(false);
      setSuccess(false);
      setErrorMsg("");
  
      if(otpCode==="") {
        document.getElementById('err_otp_code_empty').style.display="block";
        return;
      }
  
      axios
      .post(`${API_URL}users/verify-mobile-number/`, dataModal, options)
      .then(res => {
          if (res.status === 200) {
              setSuccess(true);
              jumpToStep(5);
          } else {
              setErrorMsg("Error in validating OTP!");
          }
      })
      .catch(err => {
          setError(true);
          setErrorMsg("Error in validating OTP!")
      });
    };
  
      useEffect(() => {
          getUser(token, currentUser, setCurrentUser);
          setForce( prev => prev + 1);
      }, [success, otpSent]);
  
      useEffect(() => {
        if(currentUser.otp_counter>=3)
        {
          setOtpSendLimitExhasuted(true);
          setOtpSendEnabled(false);
        }
        if(currentUser.mobile_update_counter>=3)
        {
          setmobileUpdateLimitExhasuted(true);
          setOtpSendEnabled(false);
        }
        if(currentUser.timestamp_last_otp_sent)
        {
          let currentTime = new Date();
          let lastOtpSentTimeStamp = new Date(currentUser.timestamp_last_otp_sent);
          let lastOtpSentTimeStampPlusOneMinute = new Date(lastOtpSentTimeStamp.getTime() + 1*60000);
  
          if (lastOtpSentTimeStampPlusOneMinute > currentTime) {
            setOtpSendEnabled(false);
            setLastOtpSentOneMinuteAgo(true);
            let remainingSeconds = (lastOtpSentTimeStampPlusOneMinute.getTime() - currentTime.getTime()) / 1000;
            setSecondsRemainingUntilNewOtp(remainingSeconds);
          }
        }
      }, [currentUser]);
  
      let sendOTP = () => {
        if(mobileNumber==="") {
          document.getElementById('err_mobile_number_empty').style.display="block";
          return;
        }
        setOtpSent(false);
        setOtpSendEnabled(false);
        var payload={
          action: "generate",
          mobile: mobileNumber,
          medium: messageMedium,
        };
    
        axios.post(`${API_URL}users/verify-mobile-number/`, payload, options)
        .then(function (r) {
          if (r.status === 200) {
            setOtpSent(true);
            setError(false);
            setErrorMsg("");
          } else {
            setErrorMsg("There was an issue in sending OTP code, please try again later!")
            setError(true);
          }
        })
        .catch(function (error) {
          setErrorMsg("There was an issue in sending OTP code, please try again later!")
          setError(true);
          console.log(error);
        });
      }

    return (
        <div className="step step4 mt-5 ">
            <Row className="justify-content-md-center">
                <Col lg="6">
                  <FormGroup>
                    <Label><b>Enter Your Mobile Number</b></Label>
                    <div style={{display: "flex", alignItems: "right", justifyContent: "space-between", width: "100%"}}>
                      <PhoneInput
                        country={currentUser.country? currentUser.country.toLowerCase(): 'de'}
                        value={mobileNumber}
                        onChange={mobile => {
                          let err_mobile = document.getElementById('err_mobile_number_empty').style.display;
                          if(err_mobile==="block") {
                            document.getElementById('err_mobile_number_empty').style.display = "none";
                          }
                          setMobileNumber("+" + mobile)
                        }}
                        inputProps={{
                          name: 'mobile',
                          required: true,
                          autoFocus: true,
                        }}
                        isValid={(inputNumber, country) => {
                          let numberToMatch = `+${inputNumber}`;
                          let regexPassing = numberToMatch.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && !(numberToMatch.match(/0{5,}/));
                          let numberValid = (startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber)) && regexPassing;
                          let numberDifferent = `+${inputNumber}`!==currentUser.mobile;
                          setMobileNumberValid(numberValid);
                          setMobileNumberDifferent(numberDifferent);
                          return numberValid;
                        }}
                        inputClass="form-control"
                        inputStyle={{width: "100%"}}
                        disabled={otpSendLimitExhasuted || mobileUpdateLimitExhasuted}
                        onlyCountries={[currentUser.country? currentUser.country.toLowerCase(): 'de']}
                        disableDropdown={true}
                      />
                      <Button className="ml-3 btn btn-primary" style={{color: "#ffffff", backgroundColor: "#000a60"}} onClick={sendOTP} disabled={!otpSendEnabled || !mobileNumberValid ||!mobileNumberDifferent}>Send OTP</Button> 
                    </div>
                    {
                      (!otpSendLimitExhasuted && !mobileUpdateLimitExhasuted) &&
                      <>
                        <div className="fv-help-block" style={{ display: 'none'}} id={'err_mobile_number_empty'}>Mobile Number is required.</div>
                        {!mobileNumberValid && <div className="fv-help-block" id={'err_mobile_number_invalid'}>Invalid mobile number.</div>}
                        {(!mobileNumberDifferent && !success) && <div className="fv-help-block" id={'err_mobile_number_same'}>This number is already added and verified with your account.</div>}
                      </>
                    }
                    <FormText text="mute">
                      Note: Mobile Number is required for verification as standard procedure because it can be used for login validation. You need to own the number and have access to it for smooth verification process.
                      {
                        isFormUSorCanada &&
                        <div className="text-danger">
                          <b>* If you are US or Canada based, please contact our support team after inserting your mobile number.</b>
                        </div>
                      }
                    </FormText>
                  </FormGroup>

                  <FormGroup>
                    <div style={{display: "flex", alignItems: "center", width: "100%", marginBottom: "10px"}}>
                      <Label className="mt-1"><b>Delivery Medium: </b></Label>
                      {
                        !isFormUSorCanada &&
                        <CustomInput
                          className="ml-2"
                          type="radio"
                          id="mediumSms"
                          value="sms"
                          label="SMS"
                          name="mediumSms"
                          checked={messageMedium==="sms"}
                          onChange={e => {
                            setMessageMedium("sms")
                          }}
                        />
                      }
                      <CustomInput
                        className="ml-2"
                        type="radio"
                        id="mediumWA"
                        value="sms"
                        label="WhatsApp"
                        name="mediumWA"
                        checked={messageMedium==="wa"}
                        onChange={e => {
                          setMessageMedium("wa")
                        }}
                      />
                  </div>
                  {
                    messageMedium==="wa" &&
                    <FormText color="info">
                      <b>
                        Note: Please ensure that you have a WhatsApp account on this number in order to receive the message.
                      </b>
                    </FormText>
                  }
                  </FormGroup>

                  { (otpSendLimitExhasuted && !otpSent) &&
                    <Alert color="danger">
                      You can not request any more OTP's as you have exhausted the <b>MAX limit (3)</b> for OTP requests. Please contact support for further information!
                    </Alert>
                  }

                  {
                    (mobileUpdateLimitExhasuted && !otpSent) &&
                    <Alert color="danger">
                      You can not update your mobile number as you have already updated it MAX (3) times. Please contact support for further information!
                    </Alert>
                  }

                  { (lastOtpSentOneMinuteAgo && (!success && !mobileUpdateLimitExhasuted && !otpSendLimitExhasuted)) && <OtpResendTimer setOtpSendEnabled={setOtpSendEnabled} secondsRemaining={secondsRemainingUntilNewOtp} setLastOtpSentOneMinuteAgo={setLastOtpSentOneMinuteAgo} /> }

                  { otpSent &&
                    <>
                      <FormGroup>
                        <Label className="text-bold">OTP</Label>
                        <Input
                          name="otp"
                          type="text"
                          className="form-control"
                          defaultValue={otpCode}
                          disabled={success}
                          onChange={(e) => {
                              let err_otp_empty = document.getElementById('err_otp_code_empty').style.display;
                              if(err_otp_empty==="block") {
                                document.getElementById('err_otp_code_empty').style.display = "none";
                              }
                              setOtpCode(e.target.value);
                          }}
                        />
                        <FormText color="muted">
                          Please enter the OTP sent to your mobile number.
                        </FormText>
                        {error && <Alert className="mt-2" color="danger">{errorMsg}</Alert>}
                        {success && <Alert className="mt-2" color="success">Mobile number verified!</Alert>}
                      </FormGroup>
                      <div className="fv-help-block" style={{ display: 'none'}} id={'err_otp_code_empty'}>OTP is required.</div>
                    </>
                  }

                  { (otpSent && !success) &&
                    <Button 
                      color="primary"
                      className="btn btn-primary pull-right"
                      style={{color: "#ffffff", backgroundColor: "#000a60"}}
                      onClick={() => handleSubmit()}
                    >
                      Confirm
                    </Button>
                  }

                </Col>
            </Row>

            {
              (otpSent && currentUser && currentUser.otp_counter>1) &&
              <center>
                <p style={{ color: "#000a60", cursor: "pointer" }} onClick={setToggleModal}><b>I am not getting the code</b></p>
              </center>
            }

            <Modal isOpen={modelOpen} centered={true}>
            <ModalHeader>I am not getting the code</ModalHeader>
            <ModalBody>
              <center>
                <i className="mdi mdi-alert-outline" style={{fontSize: "80px"}}></i><br/>
                Looks like there’s a problem sending you a verification code right now. Please try again later<br/><br/>
                For assistance, please reach out to our support via Help Center.
              </center>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setToggleModal(false)}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
    );
};

export default StepFour;