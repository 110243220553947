import React from 'react';
import {
    Row,
    Col,
    Alert
} from 'reactstrap';

const StepSeven = ({ jumpToStep }) => {

    return (
        <div className="step step7 mt-5">
            <Row className="justify-content-md-center">
                <Col lg="6">
                    <Alert color="info">
                        Our team will review all the information provided by you and give the final approval within 3 business days.
                    </Alert>
                </Col>
            </Row>
        </div>
    );
};

export default StepSeven;