import React, { useState, useContext } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import FeatureDisabledTooltip from "./featureDisabledTooltip";
import { Link } from "react-router-dom";
import { StateContext } from "../utils/context";

const SubscriptionTableDropdown = ({
  data,
  setSuccess,
  setError,
  setToggleModal,
  setToggleModalResume,
  setToggleModalRetryPayment,
  setDataModal,
  setLastAction
}) => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const {currentUser} = useContext(StateContext);
  
  const statusUpdateBtnText = (data.cancel_at_period_end) ? "Enable Auto Renewal" : "Cancel Subscription";
  const statusUpdateAction = (data.cancel_at_period_end) ? "set to be renewed automatically at period end" : "set to be cancelled at period end";

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret outline color="primary">
        Action
      </DropdownToggle>

      <DropdownMenu>        
        {
          (data.status === "active") &&
          <>
            <DropdownItem
              onClick={() => {
                setSuccess(false);
                setError(false);
                setDataModal(data);
                setToggleModal(true);
                setLastAction(statusUpdateAction);
              }}
              style={{ cursor: "pointer" }}
            >
              {statusUpdateBtnText}
            </DropdownItem>
            <DropdownItem>
              <Link to={`/subscription/upgrade/`}>Upgrade Subscription</Link>
            </DropdownItem>
          </>
        }
        
        {
          (data.status === "canceled") &&
          <>
            <DropdownItem
              id={`fdc-${data.id}`}
              disabled={currentUser && (currentUser.subscription_active || currentUser.has_incomplete_subscription)}
              onClick={() => {
                setSuccess(false);
                setError(false);
                setDataModal(data);
                setToggleModalResume(true);
                setLastAction("resumed, your account will be updated shortly");
              }}
              style={{ cursor: "pointer" }}
            >
              Resume Subscription
            </DropdownItem>
            {
              (currentUser && currentUser.subscription_active) && <FeatureDisabledTooltip target={`fdc-${data.id}`} msg="You already have an active subscription" />
            }
            {
              (currentUser && !currentUser.subscription_active && currentUser.has_incomplete_subscription) && <FeatureDisabledTooltip target={`fdc-${data.id}`} msg="You have a subscription which is Incomplete." />
            }
          </>
        }

        {
          (data.status === "incomplete") &&
          <>
            <DropdownItem
              id={`fdi-${data.id}`}
              disabled={currentUser && currentUser.subscription_active}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSuccess(false);
                setError(false);
                setDataModal(data);
                setToggleModalRetryPayment(true);
                setLastAction("resumed, your account will be updated shortly");
              }}
            >
              Retry Payment
            </DropdownItem>
            {
              (currentUser && currentUser.subscription_active) && <FeatureDisabledTooltip target={`fdi-${data.id}`} msg="You already have an active subscription" />
            }
          </>
        }
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default SubscriptionTableDropdown;