import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";

const API_URL = process.env.REACT_APP_API_URL_BASE;

const AsyncDropdownNormal = ({
  value,
  onChange,
  placeholder = "Search...",
  endpoint,
  subUserEndpoint,
  parentUserOnly = "",
  queryParams = "",
  fetchOptions = {},
  labelField,
  valueField = "id",
  fieldName,
  extraFields = [],
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchOptionsData = async (query = "") => {
    try {
      setLoading(true);
      const response = await fetch(
        `${API_URL}${endpoint}/${subUserEndpoint}?search=${query}${parentUserOnly}${queryParams}`,
        fetchOptions
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      const formattedData = data.results.map((item) => {
        const formattedItem = {
          value: item[valueField],
          label: item[labelField],
        };

        extraFields.forEach((field) => {
          if (item[field] !== undefined) {
            formattedItem[field] = item[field];
          }
        });

        return formattedItem;
      });

      setOptions(formattedData);
      return formattedData;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const loadOptions = (inputValue, callback) => {
    fetchOptionsData(inputValue).then((options) => callback(options));
  };

  useEffect(() => {
    fetchOptionsData();
  }, [subUserEndpoint]);

  return (
    <AsyncSelect
      key={subUserEndpoint}
      cacheOptions
      defaultOptions={options}
      value={value}
      loadOptions={loadOptions}
      onChange={onChange}
      placeholder={placeholder}
      isLoading={loading}
      loadingMessage={() => "Loading..."}
      styles={{
        menu: (styles) => ({ ...styles, zIndex: 10 }),
      }}
      {...props}
    />
  );
};

export default AsyncDropdownNormal;