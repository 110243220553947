import React, { useState } from "react";

const PlaySVG = ({fillColor="#3E5569"}) => (
    <>
      <svg
        width='10'
        height='10'
        viewBox='0 0 10 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.09594 4.3301L1.93665 0.136432C1.32607 -0.221821 0.5 0.168043 0.5 0.810791V9.18759C0.5 9.84088 1.32607 10.2202 1.93665 9.86195L9.09594 5.66828C9.63469 5.36271 9.63469 4.64621 9.09594 4.3301Z'
          fill={fillColor}
        />
      </svg>{" "}
      Play
    </>
);

const StoreLinkButtonPreview = ({ storeLink, storeName, musicLinkData, onButtonLink }) => {
  const [isHovered, setIsHovered] = useState(false);

  let btnStyle = {
    backgroundColor: "transparent",
    color: isHovered ? musicLinkData.button_text_color : musicLinkData.button_color,
    borderColor: isHovered ? musicLinkData.button_text_color : musicLinkData.button_color,
  };
  let playIconFillColor = isHovered ? musicLinkData.button_text_color : musicLinkData.button_color;

  if (musicLinkData.button_style === 2) {
    btnStyle = {
      backgroundColor: isHovered ? musicLinkData.button_text_color : musicLinkData.button_color,
      color: isHovered ? musicLinkData.button_color : musicLinkData.button_text_color,
      borderColor: isHovered ? musicLinkData.button_text_color : musicLinkData.button_color,
    };
    playIconFillColor = isHovered ? musicLinkData.button_color : musicLinkData.button_text_color;
  }

  return (
    <button
      className="btn downloadbnt"
      onClick={(e) => {
        onButtonLink(storeLink, storeName, musicLinkData? musicLinkData.id: null)
      }}
      style={btnStyle}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
        <PlaySVG fillColor={playIconFillColor} />
    </button>
  );
};

export default StoreLinkButtonPreview;