export const types =  {
    GET_MUSIC_LINKS: "GET_MUSIC_LINKS",
    ADD_NEW_MUSIC_LINK: "ADD_NEW_MUSIC_LINK",
};

export const setMusicLinks = (payload) => {
    return {
        type: types.GET_MUSIC_LINKS,
        payload
    }
}

export const addMusicLinks= (payload) => {
    return {
        type: types.ADD_NEW_MUSIC_LINK,
        payload
    }
}
