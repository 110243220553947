import React from "react";
import moment from "moment";
import { Col, Row, Button } from "reactstrap";
import ToolkitProvider, {
  CSVExport
} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SubscriptionTableDropdown from "../components/subscriptionTableDropdown";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";


const SubscriptionTable = ({ tableData, searchQuery, defaultSorted, setToggleModal, setToggleModalResume, setToggleModalRetryPayment, setDataModal, setSuccess, setError, setLastAction }) => {
  const { ExportCSVButton } = CSVExport;

  const dateFormatter = (cell, row) => {
    return (
      moment(cell).format('YYYY-MM-DD')
    );
  };

  const cancelAtPeriodEndFormatter = (cell, row) =>
    row.status !== "active" ?
    (
      <span className="p-4">
        <i className="fas fa-2x fa-times-circle text-danger" id={`${row.id}`}></i>
      </span>
    ) :
    !cell ? (
      <span className="p-4">
        <i className="fas fa-2x fa-check-circle text-success" id={`${row.id}`}></i>
      </span>
    ) :
    (
      <span className="p-4">
        <i className="fas fa-2x fa-times-circle text-danger" id={`${row.id}`}></i>
      </span>
    );
 
    
    const actionFormatter = (cell, row) => {
      return (
        <SubscriptionTableDropdown
          data={row}
          setSuccess={setSuccess}
          setError={setError}
          setToggleModal={setToggleModal}
          setToggleModalResume={setToggleModalResume}
          setToggleModalRetryPayment={setToggleModalRetryPayment}
          setDataModal={setDataModal}
          setLastAction={setLastAction}
        />
      );
    };

    const statusFormatter = cell => {
      return (
        <>
          {cell === "active" ? (
            <Button className="btn-status" disabled color="success">
              Active
            </Button>
          ) : cell === "incomplete" ? (
            <Button className="btn-status" disabled color="primary">
              Incomplete
            </Button>
          ) : cell === "incomplete_expired" ? (
            <Button className="btn-status" disabled color="warning">
              Incomplete Expired
            </Button>
          ) : cell === "unpaid" ? (
            <Button className="btn-status" disabled color="warning">
              Unpaid
            </Button>
          ) : cell === "past_due" ? (
            <Button className="btn-status" disabled color="danger">
              Past Due
            </Button>
          ) : cell === "cancelled" ? (
            <Button className="btn-status" disabled color="danger">
              Cancelled
            </Button>
          ) : (
            <Button className="btn-status" disabled color="danger">
              Expired
            </Button>
          )}
        </>
      );
    };


  const columns = [    
    {
      dataField: "tier_name",
      text: "Subscription Tier",
      sort: false,
    },
    {
      dataField: "start_date",
      text: "Start Date",
      sort: false,
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
    },
    {
      dataField: "current_cycle_start",
      text: "Current Period Start",
      sort: false,
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
    },
    {
      dataField: "current_cycle_end",
      text: "Current Period End",
      sort: false,
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
    },
    {
      dataField: "cancel_at_period_end",
      text: "Auto Renewal",
      sort: false,
      formatter: cancelAtPeriodEndFormatter,
      csvFormatter: cancelAtPeriodEndFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: statusFormatter,
      sort: false,
      csvExport: false,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
      sort: false,
      csvExport: false,
      headerStyle: (colum, colIndex) => {
        return { width: "111px" };
      }
    }
  ];

  const handleTableChange = (type, { sortField, sortOrder, data }) => {
    const ascDesc = sortOrder === "desc" ? "-" : "";
    if (sortField === "start_date" || sortField === "current_cycle_start" || sortField === "current_cycle_end") {
      searchQuery.setOrdering({order: ascDesc, name: sortField, sort: sortField});
    }
  }

  return (
    <ToolkitProvider
      keyField="id"
      data={tableData}
      columns={columns}
      search={{
        searchFormatted: true
      }}
      exportCSV={ {
        fileName: 'my-subscriptions.csv',
      } }
    >
      {props => (
        <div>
          <Row>
            <Col>
              <div className="customSearch">
                {/* <Input placeholder="Search..." value={searchQuery.query} style={{marginBottom: 10}} onChange={e => searchQuery.setQuery(e.target.value)}/> */}
              </div>
            </Col>
            <Col>
              <div className="flexEndContainer">
                {/* <ExportCSVButton
                  className="btn btn-outline-info"
                  {...props.csvProps}
                >
                  Export CSV
                </ExportCSVButton> */}
              </div>
            </Col>
          </Row>
          <BootstrapTable
            {...props.baseProps}
            bordered={ false }
            remote={ { sort: true } }
            defaultSorted={defaultSorted}
            onTableChange={handleTableChange}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default SubscriptionTable;