const helpItemsData = [
  {
    id: 1,
    heading: "I have some questions, how to get help?",
    text:
      "The first line of help is our rich document base called Help Center. If you have any questions regarding your music, project, label, or in general about workflow in Move Music app, please visit our Help Center by clicking on the icon in the upper and lower right corner. Search the terms in the search bar or navigate through the sections. If you still have questions and you are not able to find the answer to your questions, feel free to send us a request via Help Center."
  },
  {
    id: 2,
    heading:
      "I can't find answers to my questions in Help Center, how to contact Move Music?",
    text:
      "Simply click on plus button in the Help Center and send us a request. Alternatively, you can send us a request via icon in lower right corner of the Move Music app. If you prefer direct e-mail sending, please use your working e-mail (address which you use for login into your account) and send us e-mail to labels@movemusic.io"
  },
  {
    id: 3,
    heading: "Can I contact Move Music via social networks?",
    text:
      "In order to ensure the best and quickest help to our customers, we reply only to the requests sent via the official Help Center. If you have any questions, our staff will gladly help you and ensure the prompt answer to your questions. All requests sent via social networks or privately to our staff members shall be ignored."
  },
  {
    id: 4,
    heading: "How long do I have to wait for a support staff answer?",
    text:
      "Usually, we do reply within 48 h from the initial request if a request is sent on working days. Please note that we are able to reply on working days MONDAY - FRIDAY between 09:00h and 17:00h CET (Amsterdam-Berlin) time. All requests received on weekends and public holidays shall be processed the next working day."
  },
  {
    id: 5,
    heading: "I don't see any articles in the Help Center, what is happening?",
    text:
      "In order to see articles in our Help Center, you need to be logged into the Move Music app. If you do not see articles, please return to the Move Music app, log in and then visit the Help Center via direct link or via Help icon in the lower right corner."
  }
];
export default helpItemsData;
