import React, { useState, useContext, useEffect, useMemo } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FormText } from "reactstrap";
import * as Yup from "yup";
import axios from "axios";
import Select from "react-select";
import { Redirect } from "react-router-dom";
import AsyncDropdownNormal from "../../components/asyncDropdownNormal";
import AsyncDropdownSpotify from "../../components/asyncDropdownSpotify";
import AsyncDropdownDeezer from "../../components/asyncDropdownDeezer";
import AsyncDropdownAmazon from "../../components/asyncDropdownAmazon";
import {
  Input,
  Card,
  Row,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  Button,
  Alert,
  CardTitle
} from "reactstrap";
import { getCookie } from "../../jwt/_helpers/cookie";
import { StateContext } from "../../utils/context";
import AsyncSelect from "react-select/async";
import ProfileImg from "../../assets/images/users/1.jpg";

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const isNewArtistOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" }
];

const ArtistsAdd = () => {
  const token = getCookie("token");
  const options = {
    method: "GET",
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": X_API_KEY,
      "Content-Type": "application/json"
    }
  };
  const {currentUser} = useContext(StateContext);
  const [success, setSuccess] = useState(false);
  const [errorDupl, setErrorDupl] = useState(false);
  const [errorNameMismatch, setErrorNameMismatch] = useState(false);
  const [errorProfileUnmatched, setErrorProfileUnmatched] = useState(false);
  
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [parentUserOnly, setParentUserOnly] = useState('&parent_user_only=true');
  const [subUser, setSubUser] = useState('');
  const [subUserId, setSubUserId] = useState(null);
  const [subUserEndpoint, setSubUserEndpoint] = useState('');

  const [profileMissingModalVisible, setProfileMissingModalVisible] = useState(false);
  const [newArtistModalVisible, setNewArtistModalVisible] = useState(false);
  const [isNewArtist, setIsNewArtist] = useState(null);
  const [hasSpotifyProfile, setHasSpotifyProfile] = useState(true);
  const [hasDeezerProfile, setHasDeezerProfile] = useState(true);
  const [hasAmazonProfile, setHasAmazonProfile] = useState(true);
  const [hasAppleProfile, setHasAppleProfile] = useState(true);
  const [spotifyArtist, setSpotifyArtist] = useState(null);
  const [deezerArtist, setDeezerArtist] = useState(null);
  const [amazonArtist, setAmazonArtist] = useState(null);
  const [defaultQuery, setDefaultQuery] = useState("");
  const [missingStoreName, setMissingStoreName] = useState("");

  const [imageBig, setImageBig] = useState("");
  const [imageSmall, setImageSmall] = useState("");

  const [namePreference, setNamePreference] = useState("spotify");

  const enabledProfilesCount = [hasSpotifyProfile, hasDeezerProfile, hasAmazonProfile].filter(Boolean).length;
  const selectedArtistsCount = [spotifyArtist, deezerArtist, amazonArtist].filter(Boolean).length;

  const CustomOption = useMemo(() => {
    return React.memo(({ innerProps, data, isFocused }) => (
      <div {...innerProps} style={{ 
          padding: '3px',
          backgroundColor: isFocused ? '#f5f5f5' : 'transparent',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}>
        <img
          src={data.image_small || ProfileImg}
          alt={data.label}
          style={{
            padding: "5px",
            height: '65px',
            width: '65px',
            borderRadius: '30%',
            marginRight: "7px",
          }}
        />
        <b>{data.label}</b>
      </div>
    ));
  }, []);

  useEffect(() => {
    if(missingStoreName) {
      setProfileMissingModalVisible(true);
    }
  }, [missingStoreName])

  useEffect(() => {
    if(!isNewArtist) {
      let amazonArtistName = amazonArtist ? amazonArtist.name.toLowerCase() : null;
      let deezerArtistName = deezerArtist ? deezerArtist.name.toLowerCase() : null;
      let spotifyArtistName = spotifyArtist ? spotifyArtist.name.toLowerCase() : null;

      const artistNames = [amazonArtistName, deezerArtistName, spotifyArtistName].filter(name => name !== null);
      const allNamesMatch = artistNames.every(name => name === artistNames[0]);

      if (artistNames.length > 0) {
        setErrorNameMismatch(!allNamesMatch);
      }

      if (
        (hasSpotifyProfile && !spotifyArtist) ||
        (hasDeezerProfile && !deezerArtist) ||
        (hasAmazonProfile && !amazonArtist)
      ) {
        setErrorProfileUnmatched(true);
      } else {
        setErrorProfileUnmatched(false);
      }
    }
  }, [amazonArtist, deezerArtist, spotifyArtist, hasSpotifyProfile, hasDeezerProfile, hasAmazonProfile]);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
    .required("Name is required")
    .test('is-dj-correct', 'Artist name must contain "DJ" in uppercase only', function(value) {
      if (isNewArtist && value && value.toLowerCase().includes('dj') && !value.includes('DJ')) {
        return false;
      }
      return true;
    }),
    email: Yup.string().email().required("Email is required"),
    spotifyId: Yup.string()
      .matches(
        /^[0-9A-Za-z_-]{22}$/,
        "Please add a valid Spotify ID. Example: 6eUKZXaKkcviH0Ku9w2n3B . Check our Help Center for more info."
      ),
    appleId: (!isNewArtist && hasAppleProfile)
      ? Yup.string()
          .required("Apple ID is required")
          .matches(
            /^[0-9]*$/,
            "Please add a valid Apple ID. Example: 635806095 . Check our Help Center for more info."
          )
      : Yup.string().notRequired(),
  });
    
  return (
    <div>
      <Row>
        <Col sm={12}>
          <Card>
            <CardBody>
            <CardTitle tag="h4">Add Artist</CardTitle>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  spotifyId: "",
                  deezerId: "",
                  amazonId: "",
                  appleId: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(
                  { name, email, amazonId, appleId, deezerId, spotifyId },
                  { setStatus, setSubmitting }
                ) => {
                  setStatus();
                  setErrorDupl(false);
                  setErrorNameMismatch(false);

                  const options = {
                    method: "POST",
                    mode: 'cors',
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "x-api-key": X_API_KEY,
                      "Content-Type": "application/json"
                    }
                  };

                  axios.get(`${API_URL}artists/${subUserEndpoint}?search=${name}${parentUserOnly}`, options).then(res => {
                    if (res.status === 200){
                      const list = res.data.results.length > 0 ? res.data.results : [];

                      const getOne = list.filter(i => i.name === name);

                      if (getOne.length > 0) {
                        setErrorDupl(true)
                      } else {
                        axios
                        .post(
                          `${API_URL}artists/`,
                          {
                            "name": name,
                            "email": email,
                            "spotify_identifier": spotifyId,
                            "apple_identifier": appleId,
                            "deezer_identifier": deezerId,
                            "amazon_music_identifier": amazonId,
                            "image_big": imageBig,
                            "image_small": imageSmall,
                            "followers": spotifyArtist ? spotifyArtist.followers : 0,
                            "popularity": spotifyArtist ? spotifyArtist.popularity : 0,
                            "sub_user_id": subUserId ?  subUserId: null,
                          },
                          options
                        )
                        .then(res => {
                          setSuccess(true);
                          setTimeout(() => setRedirect(true), 1000);
                        })
                        .catch(err => {
                          setError(true);
                        });
                      }
                    }
                  })
                }}
                render={({
                  errors,
                  status,
                  touched,
                  isSubmitting,
                  setFieldValue,
                }) => {
                  return (
                    <Form className="mt-3" id="addArtistForm">

                      <FormGroup row>
                        <Label sm={2}>Is New Artist</Label>
                        <Col sm={10}>
                          <Select
                            id="isNewArtist"
                            name="isNewArtist"
                            value={isNewArtist? { label: "Yes", value: "yes" }: { label: "No", value: "no" }}
                            components={{ IndicatorSeparator: () => null }}
                            options={isNewArtistOptions}
                            onChange={option => {
                              if(option.value === "yes") {
                                if(!isNewArtist) {
                                  setNewArtistModalVisible(true)
                                }
                              } else {
                                if(isNewArtist) {
                                  setIsNewArtist(false)
                                }
                              }
                            }}
                          />
                          <FormText color="muted">
                            Are you adding a new artist i.e. If your artist has releases on Spotify/Deezer/Amazon Music/Apple Music then select <b>No</b> otherwise select <b>Yes</b>.
                          </FormText>
                        </Col>
                      </FormGroup>
                      
                        { (!isNewArtist && hasSpotifyProfile) &&
                        <>
                          <FormGroup className="select-search-wrap" row>
                            <Label for="Spotify Profile" sm={2}>
                              Spotify Profile
                            </Label>
                            <Col sm={8}>
                              {spotifyArtist?
                                <AsyncSelect
                                  defaultOptions={[spotifyArtist]}
                                  value={spotifyArtist}
                                  onChange={()=> {}}
                                  components={{
                                    Option: CustomOption,
                                  }}
                                  styles={{
                                    menu: (styles) => ({ ...styles, zIndex: 10 }),
                                  }}
                                />:
                                <>
                                  <AsyncDropdownSpotify
                                    fetchOptions={options}
                                    labelField="name"
                                    onChange={e => {
                                      let artistId = e.value;
                                      let artistObj = e;
                                      artistObj.name = e.label;
                                      setSpotifyArtist(artistObj);
                                      setFieldValue("spotifyId", artistId);
                                      setImageBig(artistObj.image_big);
                                      setImageSmall(artistObj.image_small);
                                      if(defaultQuery !== artistObj.name) {
                                        setDefaultQuery(artistObj.name);
                                      }
                                      if(!deezerArtist && !amazonArtist) {
                                        setNamePreference("spotify");
                                        setFieldValue("name", artistObj.name);
                                      }
                                    }}
                                    defaultQuery={defaultQuery}
                                    isDisabled={spotifyArtist? true: false}
                                    placeholder="Enter Artist Name i.e. The Weekend or Artist's Spotify ID i.e. 144HzhpLjcR9k37w5Ico9B"
                                  />
                                  <FormText color="muted">
                                    Note: Only use this dropdown if you want to add an Artist who is already on Spotify otherwise click on Not Found button.
                                  </FormText>
                                </>
                              }
                            </Col>
                            <Col sm={2}>
                              {
                                spotifyArtist? 
                                <Button
                                  className="btn btn-outline-info"
                                  style={{width: '100%'}}
                                  onClick={() => {
                                    setSpotifyArtist(null);
                                    setErrorNameMismatch(false);
                                    setErrorDupl(false);
                                    setFieldValue("spotifyId", "");
                                    if(!deezerArtist && !amazonArtist) {
                                      setFieldValue("name", "");
                                      setImageBig("");
                                      setImageSmall("");
                                    } else if(namePreference === "spotify") {
                                      if(deezerArtist) {
                                        setNamePreference("deezer");
                                        setFieldValue("name", deezerArtist.name);
                                        setImageBig(deezerArtist.image_big);
                                        setImageSmall(deezerArtist.image_small);
                                      } else if (amazonArtist) {
                                        setNamePreference("amazon");
                                        setFieldValue("name", amazonArtist.name);
                                        setImageBig(amazonArtist.image_big);
                                        setImageSmall(amazonArtist.image_small);
                                      }
                                    }
                                  }}
                                >
                                  Reset
                                </Button>:
                                <Button
                                  className="btn btn-outline-danger"
                                  style={{width: '100%'}}
                                  onClick={() => {
                                    if(enabledProfilesCount>=2) {
                                      setMissingStoreName("Spotify");
                                    }
                                  }}
                                >
                                  Not Found?
                                </Button>
                              }
                            </Col>
                          </FormGroup>

                          {
                            spotifyArtist &&
                            <FormGroup row>
                              <Label for="spotifyId" sm={2}>
                                Spotify ID
                              </Label>
                              <Col>
                                <Field
                                  name="spotifyId"
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                />
                              </Col>
                            </FormGroup>
                          }
                          {
                            (spotifyArtist && spotifyArtist.image_big) && 
                            <FormGroup row>
                              <Label sm={2}>Spotify Image</Label>
                              <Col xs={2}>
                                <img
                                  src={spotifyArtist.image_big}
                                  style={{ width: "150px", padding: "10px 0" }}
                                  alt={"Spotify Artist DP"}
                                />
                              </Col>
                            </FormGroup>
                          }
                        </>
                        }

                        { (!isNewArtist && hasDeezerProfile) &&
                          <>
                            <FormGroup className="select-search-wrap" row>
                              <Label for="Deezer Profile" sm={2}>
                                Deezer Profile
                              </Label>
                              <Col sm={8}>
                                {deezerArtist?
                                <AsyncSelect
                                  defaultOptions={[deezerArtist]}
                                  value={deezerArtist}
                                  onChange={()=> {}}
                                  components={{
                                    Option: CustomOption,
                                  }}
                                  styles={{
                                    menu: (styles) => ({ ...styles, zIndex: 10 }),
                                  }}
                                />:
                                <>
                                  <AsyncDropdownDeezer
                                    fetchOptions={options}
                                    labelField="name"
                                    onChange={e => {
                                      let artistId = e.value;
                                      let artistObj = e;
                                      artistObj.name = e.label;
                                      setDeezerArtist(artistObj);
                                      setFieldValue("deezerId", artistId);
                                      if(defaultQuery !== artistObj.name) {
                                        setDefaultQuery(artistObj.name);
                                      }
                                      if(!spotifyArtist && !amazonArtist) {
                                        setNamePreference("deezer");
                                        setFieldValue("name", artistObj.name);
                                        setImageBig(artistObj.image_big);
                                        setImageSmall(artistObj.image_small);
                                      }
                                    }}
                                    defaultQuery={defaultQuery}
                                    isDisabled={deezerArtist? true: false}
                                    placeholder="Enter Artist Name i.e. The Weekend or Artist's Deezer ID i.e. 1406580"
                                  />
                                  <FormText color="muted">
                                    Note: Only use this dropdown if you want to add an Artist who is already on Deezer otherwise click on Not Found button.
                                  </FormText>
                                </>
                                }
                              </Col>
                              <Col sm={2}>
                                {
                                  deezerArtist? 
                                  <Button
                                    className="btn btn-outline-info"
                                    style={{width: '100%'}}
                                    onClick={() => {
                                      setDeezerArtist(null);
                                      setErrorNameMismatch(false);
                                      setErrorDupl(false);
                                      setFieldValue("deezerId", "");
                                      if(!spotifyArtist && !amazonArtist) {
                                        setFieldValue("name", "");
                                        setImageBig("");
                                        setImageSmall("");
                                      } else if(namePreference === "deezer") {
                                        if(spotifyArtist) {
                                          setNamePreference("spotify");
                                          setFieldValue("name", spotifyArtist.name);
                                          setImageBig(spotifyArtist.image_big);
                                          setImageSmall(spotifyArtist.image_small);
                                        } else if (amazonArtist) {
                                          setNamePreference("amazon");
                                          setFieldValue("name", amazonArtist.name);
                                          setImageBig(amazonArtist.image_big);
                                          setImageSmall(amazonArtist.image_small);
                                        }
                                      }
                                    }}
                                  >
                                    Reset
                                  </Button>:
                                  <Button
                                    className="btn btn-outline-danger"
                                    style={{width: '100%'}}
                                    onClick={() => {
                                      if(enabledProfilesCount>=2) {
                                        setMissingStoreName("Deezer");
                                      }
                                    }}
                                  >
                                    Not Found?
                                  </Button>
                                }
                              </Col>
                            </FormGroup>

                            {
                              deezerArtist &&
                              <FormGroup row>
                                <Label for="deezerId" sm={2}>
                                  Deezer ID
                                </Label>
                                <Col>
                                  <Field
                                    name="deezerId"
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                  />
                                </Col>
                              </FormGroup>
                            }
                            {
                              (deezerArtist && deezerArtist.image_big) && 
                              <FormGroup row>
                                <Label sm={2}>Deezer Image</Label>
                                <Col xs={2}>
                                  <img
                                    src={deezerArtist.image_big}
                                    style={{ width: "150px", padding: "10px 0" }}
                                    alt={"Deezer Artist DP"}
                                  />
                                </Col>
                              </FormGroup>
                            }
                          </>
                        }

                        { (!isNewArtist && hasAmazonProfile) &&
                          <>
                            <FormGroup className="select-search-wrap" row>
                              <Label for="Amazon Music Profile" sm={2}>
                                Amazon Music Profile
                              </Label>
                              <Col>
                                {amazonArtist?
                                <AsyncSelect
                                  defaultOptions={[amazonArtist]}
                                  value={amazonArtist}
                                  onChange={()=> {}}
                                  components={{
                                    Option: CustomOption,
                                  }}
                                  styles={{
                                    menu: (styles) => ({ ...styles, zIndex: 10 }),
                                  }}
                                />:
                                <>
                                  <AsyncDropdownAmazon
                                    fetchOptions={options}
                                    labelField="name"
                                    onChange={e => {
                                      let artistId = e.value;
                                      let artistObj = e;
                                      artistObj.name = e.label;
                                      setAmazonArtist(artistObj);
                                      setFieldValue("amazonId", artistId);
                                      if(defaultQuery !== artistObj.name) {
                                        setDefaultQuery(artistObj.name);
                                      }
                                      if(!spotifyArtist && !deezerArtist) {
                                        setNamePreference("amazon");
                                        setFieldValue("name", artistObj.name);
                                        setImageBig(artistObj.image_big);
                                        setImageSmall(artistObj.image_small);
                                      }
                                    }}
                                    defaultQuery={defaultQuery}
                                    isDisabled={amazonArtist? true: false}
                                    placeholder="Enter Artist Name i.e. The Weekend or Artist's Amazon ASIN i.e. B005GI0T2U"
                                  />
                                  <FormText color="muted">
                                    Note: Only use this dropdown if you want to add an Artist who is already on Amazon Music otherwise click on Not Found button.
                                  </FormText>
                                </>
                                }
                              </Col>
                              <Col sm={2}>
                                {
                                  amazonArtist? 
                                  <Button
                                    className="btn btn-outline-info"
                                    style={{width: '100%'}}
                                    onClick={() => {
                                      setAmazonArtist(null);
                                      setErrorNameMismatch(false);
                                      setErrorDupl(false);
                                      setFieldValue("amazonId", "");
                                      if(!spotifyArtist && !deezerArtist) {
                                        setFieldValue("name", "");
                                        setImageBig("");
                                        setImageSmall("");
                                      } else if(namePreference === "amazon") {
                                        if(spotifyArtist) {
                                          setNamePreference("spotify");
                                          setFieldValue("name", spotifyArtist.name);
                                          setImageBig(spotifyArtist.image_big);
                                          setImageSmall(spotifyArtist.image_small);
                                        } else if (deezerArtist) {
                                          setNamePreference("deezer");
                                          setFieldValue("name", deezerArtist.name);
                                          setImageBig(deezerArtist.image_big);
                                          setImageSmall(deezerArtist.image_small);
                                        }
                                      }
                                    }}
                                  >
                                    Reset
                                  </Button>:
                                  <Button
                                    className="btn btn-outline-danger"
                                    style={{width: '100%'}}
                                    onClick={() => {
                                      if(enabledProfilesCount>=2) {
                                        setMissingStoreName("Amazon Music");
                                      }
                                    }}
                                  >
                                    Not Found?
                                  </Button>
                                }
                              </Col>
                            </FormGroup>

                            {
                              amazonArtist &&
                              <FormGroup row>
                                <Label for="amazonId" sm={2}>
                                  Amazon ID
                                </Label>
                                <Col>
                                  <Field
                                    name="amazonId"
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                  />
                                </Col>
                              </FormGroup>
                            }
                            {
                              (amazonArtist && amazonArtist.image_big) && 
                              <FormGroup row>
                                <Label sm={2}>Amazon Music Image</Label>
                                <Col xs={2}>
                                  <img
                                    src={amazonArtist.image_big}
                                    style={{ width: "150px", padding: "10px 0" }}
                                    alt={"Amazon Music Artist DP"}
                                  />
                                </Col>
                              </FormGroup>
                            }
                          </>
                        }

                        { (!isNewArtist && hasAppleProfile) &&
                          <FormGroup row>
                            <Label for="appleId" sm={2}>
                              Apple ID
                            </Label>
                            <Col sm={8}>
                              <Field
                                name="appleId"
                                type="text"
                                placeholder="Please enter a valid Apple ID. Example: 635806095"
                                className={
                                  "form-control" +
                                  (errors.appleId && touched.appleId
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <FormText color="muted">
                                Note: Only use this field if you want to add an Artist who is already on Apple Music otherwise click on Not Found button.
                              </FormText>
                              <ErrorMessage
                                name="appleId"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                            <Col sm={2}>
                                <Button
                                  className="btn btn-outline-danger"
                                  style={{width: '100%'}}
                                  onClick={() => {
                                    setMissingStoreName("Apple Music");
                                  }}
                                >
                                  Not Found?
                                </Button>
                            </Col>
                          </FormGroup>
                        }

                        <FormGroup row>
                          <Label for="name" sm={2}>
                            Name
                          </Label>
                          <Col>
                            <Field
                              name="name"
                              type="text"
                              disabled={!isNewArtist}
                              className={
                                "form-control" +
                                ((errorNameMismatch || errors.name)  && touched.name ? " is-invalid" : "")
                              }
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="invalid-feedback"
                            />
                            {
                              errorDupl && <p style={{fontSize: "12px",color: "red",margin: "2px 0 0 0"}}>Artist already exists</p>
                            }
                            {
                              errorNameMismatch && <p style={{fontSize: "12px",color: "red",margin: "2px 0 0 0"}}>Artist name must match for all stores</p>
                            }
                          </Col>
                        </FormGroup>

                        
                        { selectedArtistsCount>1 &&
                          <FormGroup row>
                            <Label for="Name Preference" sm={2}>
                              Name Preference
                            </Label>
                            <Col>
                              <div style={{ display: "flex", justifyContent: "normal" }} >
                                {
                                  spotifyArtist &&
                                  <FormGroup check className="mr-4">
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="prefer-spotify"
                                        value="spotify"
                                        checked={namePreference === "spotify"}
                                        onChange={() => {
                                          setNamePreference("spotify")
                                          setFieldValue("name", spotifyArtist.name);
                                        }}
                                      />{" "}
                                      <span className="mt-1">Spotify</span>
                                    </Label>
                                  </FormGroup>
                                }

                                {
                                  deezerArtist &&
                                  <FormGroup check className="mr-4">
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="prefer-deezer"
                                        value="spotify"
                                        checked={namePreference === "deezer"}
                                        onChange={() => {
                                          setNamePreference("deezer")
                                          setFieldValue("name", deezerArtist.name);
                                        }}
                                      />{" "}
                                      <span className="mt-1">Deezer</span>
                                    </Label>
                                  </FormGroup>
                                }

                                {
                                  amazonArtist &&
                                  <FormGroup check className="mr-4">
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="prefer-amazon"
                                        value="amazon"
                                        checked={namePreference === "amazon"}
                                        onChange={() => {
                                          setNamePreference("amazon")
                                          setFieldValue("name", amazonArtist.name);
                                        }}
                                      />{" "}
                                      <span className="mt-1">Amazon Music</span>
                                    </Label>
                                  </FormGroup>

                                }

                              </div>
                            </Col>
                          </FormGroup>
                        }

                        <FormGroup row>
                          <Label for="email" sm={2}>
                            Email
                          </Label>
                          <Col>
                            <Field
                              name="email"
                              type="text"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Col>
                        </FormGroup>

                        {currentUser.is_premium_user &&
                          <FormGroup className="select-search-wrap" row>
                            <Label for="subUser" sm={2}>
                              Sub-User
                            </Label>
                            <Col>
                              {
                                subUser ?

                                <div className="releaseFileRow">
                                  <p className="releaseFileRowName"> {subUser}</p>
                                  <Button className="btn btn-outline-info"
                                    onClick={() => {
                                      setSubUser('');
                                      setSubUserId(null);
                                      setSubUserEndpoint('');
                                    }
                                    }>
                                    Reset
                                  </Button>
                                </div>
                                :
                                <AsyncDropdownNormal
                                  fetchOptions={options}
                                  endpoint={`sub-users`}
                                  subUserEndpoint={subUserEndpoint}
                                  parentUserOnly={parentUserOnly}
                                  labelField="username"
                                  onChange={e => {
                                    let subUserId = e.value;
                                    let username = e.label;
                                    if(subUserId !== '') {
                                      setSubUser(username);
                                      setSubUserId(subUserId);
                                      setSubUserEndpoint(`sub-user/${subUserId}/`);
                                      setParentUserOnly('');
                                    } else {
                                      setSubUser('');
                                      setSubUserId(null);
                                      setSubUserEndpoint('');
                                      setParentUserOnly('&parent_user_only=true');
                                    }
                                  }}
                                  placeholder="Select Sub-user..."
                                />
                              }
                            <FormText color="muted">
                              Note: Only use this dropdown if you want to Add Artist for any of your Sub-users otherwise leave un-selected.
                            </FormText>
                            </Col>
                          </FormGroup>
                        }

                        <FormGroup row>
                          <Col>
                            <Button color="success" disabled={errorDupl || errorNameMismatch || errorProfileUnmatched}>Submit</Button>
                          </Col>
                        </FormGroup>

                        <Modal isOpen={profileMissingModalVisible} centered={true}>
                          <ModalHeader>
                            Profile Not Present Declaration
                          </ModalHeader>
                          <ModalBody>
                            I confirm that this Artist's profile is not present on <strong>{missingStoreName}</strong> and that the profile of this Artist be created freshly on <strong>{missingStoreName}</strong>.
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="secondary"
                              onClick={() => {
                                setProfileMissingModalVisible(false);
                                setMissingStoreName("");
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              color="success"
                              onClick={() => {
                                if(missingStoreName === "Spotify") {
                                  setHasSpotifyProfile(false);
                                  setSpotifyArtist(null);
                                  setFieldValue("spotifyId", "");
                                }
                                if(missingStoreName === "Deezer") {
                                  setHasDeezerProfile(false);
                                  setDeezerArtist(null);
                                  setFieldValue("deezerId", "");
                                }
                                if(missingStoreName === "Amazon Music") {
                                  setHasAmazonProfile(false);
                                  setAmazonArtist(null);
                                  setFieldValue("amazonId", "");
                                }
                                if(missingStoreName === "Apple Music") {
                                  setHasAppleProfile(false);
                                  setFieldValue("appleId", "");
                                }
                                setProfileMissingModalVisible(false);
                                setMissingStoreName("");
                              }}
                            >
                              Confirm
                            </Button>
                          </ModalFooter>
                        </Modal>
                        
                        <Modal isOpen={newArtistModalVisible} centered={true}>
                          <ModalHeader>
                            New Artist Declaration
                          </ModalHeader>
                          <ModalBody>
                            I confirm that this Artist's profile is not present on any of the following stores and that the profile of this Artist be created freshly on these stores.<br/><br/>
                            <ul>
                              <li><b>Amazon Music</b></li>
                              <li><b>Apple Music</b></li>
                              <li><b>Deezer</b></li>
                              <li><b>Spotify</b></li>
                            </ul>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="secondary"
                              onClick={() => setNewArtistModalVisible(false)}
                            >
                              Cancel
                            </Button>
                            <Button
                              color="success"
                              onClick={() => {
                                setFieldValue("name", "")
                                setFieldValue("amazonId", "")
                                setFieldValue("deezerId", "")
                                setFieldValue("spotifyId", "")
                                setFieldValue("appleId", "")
                                setImageBig("");
                                setImageSmall("");
                                setAmazonArtist(null)
                                setHasAmazonProfile(true)
                                setDeezerArtist(null)
                                setHasDeezerProfile(true)
                                setSpotifyArtist(null)
                                setHasSpotifyProfile(true)
                                setHasAppleProfile(true)
                                setErrorProfileUnmatched(false)
                                setNewArtistModalVisible(false)
                                setIsNewArtist(true)
                              }}
                            >
                              Confirm
                            </Button>
                          </ModalFooter>
                        </Modal>
                    </Form>
                  );
                }}
              />
              {success && <Alert color="success">Artist has been added!</Alert>}
              {redirect ? <Redirect to="/artists" /> : null}
              {error && (
                <Alert color="danger">
                  Something went wrong! Please refresh page and try again!
                </Alert>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ArtistsAdd;