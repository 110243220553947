const subuserHelpItemsData = [
  {
    id: 1,
    heading: "Do I have a contract with Move Music or with {parent_company}?",
    text: "You do not have a contract with Move Music. {parent_company} does have a valid distribution and delivery contract with Move Music. You should have a valid contract with {parent_company}."
  },
  {
    id: 2,
    heading: "I have some questions, how to get help?",
    text: "The first line of help is our rich document base called Help Center provided by Move Music. If you have any questions regarding your music, project, label, general workflow in the Move Music app or any other questions, please contact only {parent_company} via email: {parent_email}."
  },
  {
    id: 3,
    heading: "Can I contact Move Music directly or via social networks?",
    text: "You should only contact {parent_company}. As an aggregator and delivery platform, Move Music supports {parent_company}."
  },
  {
    id: 4,
    heading: "Am I getting any financial disadvantage by being distributed by {parent_company} and not directly by Move Music?",
    text: "Not really, {parent_company} has a special b2b distribution deal with Move Music which ensures them and you better conditions due to the large catalog utilization. Such deals and technical arrangements are not viable for smaller catalog sizes."
  },
  {
    id: 5,
    heading: "Who is responsible for my monthly reports and payments?",
    text: "Only and solely {parent_company}. They are getting the monthly financial statements and payments for their whole company by Move Music. {parent_company} generates automated financial reports for your account and they should pay your part of the royalties according to your arrangement with them."
  },
  {
    id: 6,
    heading: "Can I switch directly to Move Music and ditch my distributor {parent_company}?",
    text: "No, you can not. For the duration of your contract with {parent_company}, you can not switch directly to Move Music. Having in mind your catalog size, you would not benefit financially from such a move, additionally, we do support or do any hostile customer takeovers from our own customers."
  }
];

export default subuserHelpItemsData;