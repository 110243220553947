import { combineReducers } from 'redux';
import settings from './settings/reducer';
import landings from './landings/reducer';
import musicLinks from './musicLinks/reducer';

const reducers = combineReducers({
    settings,
    landings,
    musicLinks
});

export default reducers;
