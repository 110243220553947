import axios from "axios";
import Trigger from "../../validations/es6/plugins/Trigger";
import Bootstrap from "../../validations/es6/plugins/Bootstrap";
import SubmitButton from "../../validations/es6/plugins/SubmitButton";
import Declarative from "../../validations/es6/plugins/Declarative";
import Sequence from "../../validations/es6/plugins/Sequence";
import { getCookie } from "../../jwt/_helpers/cookie";

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const validUrlRegex = /^(?:https?|http):\/\/(?:[^\s\/]+\.)?[^\s\/]+\.[^\s\/]+(?:\/[^\/\s]*)*$/;

const amazonUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?(?:amazon\.[a-z.]+|music\.amazon\.[a-z.]+)\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const appleMusicUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?music\.apple\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const beatportUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?beatport\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const deezerUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?deezer\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const spotifyUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?open\.spotify\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const traxsourceUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?traxsource\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const youtubeUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?youtube\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const tidalUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?(?:tidal\.com|listen\.tidal\.com)(?:\/[^\s\/]*)*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const soundCloudUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?soundcloud\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;
const junoDownloadUrlRegex = /^(?:https?|http):\/\/(?:[a-z]+\.)?junodownload\.com\/[^\s\/]*(?:\/[^\/\s]*)*(?:\?[^\/\s]*)?$/;

const opt = {
  fields: {
    release_name: {
        validators: {
          notEmpty: {
            message: "Release Name is required"
          },
        }
      },
    ean: {
        validators: {
          notEmpty: {
            message: "EAN/UPC is required"
          },
        }
    },
    artist_name: {
      validators: {
        notEmpty: {
          message: "Artist Name is required"
        },
      }
    },
    url_part1: {
      validators: {
        notEmpty: {
          message: "Url Part 1 is required"
        },
      }
    },
    url_part2: {
        validators: {
          notEmpty: {
            message: "Url Part 2 is required"
          },
          stringLength: {
            min: 0,
            max: 10,
            message: "Url Part 2 can not be longer than 10 characters",
          },
          promise: {
            message: "Oops! This url is already associated with another music link. Please try another url.",
            promise: function(urlPartTwo) {
              return new Promise(function(resolve, reject) {
                let urlPartOne = document.getElementById("updateMusicLinkForm").querySelector('[name="url_part1"]').value || "";
                let urlPartTwoInitial = document.getElementById("updateMusicLinkForm").querySelector('[name="url_part2_initial"]').value || "";
                if(urlPartTwo.value !== "" && urlPartTwo.value !== urlPartTwoInitial) {
                  const token = getCookie("token");
                  const requestBody = {
                    url_part1: urlPartOne,
                    url_part2: urlPartTwo.value,
                  };
                  axios({
                    method: "POST",
                    mode: 'cors',
                    url: `${API_URL}musiclinks_urlparts/validate-urlparts/`,
                    data: requestBody,
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "x-api-key": X_API_KEY,
                    }
                  }).then(function(response) {
                    if (response.status === 200) {
                      const valid = response.data.valid;
                      const message = response.data.status;
  
                      valid ? resolve({ valid: true, message: "Url is available" }) : resolve({ valid: false, message: message });
                    } else {
                      reject({valid: false, message: "Url is invalid"});
                    }
                  });
                }
                else {
                  resolve({ valid: true, message: "Url is available" })
                }
              });
            }
          },
        }
      },
      amazonmusic_url: {
        validators: {
            notEmpty: {
                message: "Amazon Music Url is required"
            },
            regexp: {
                regexp: amazonUrlRegex,
                message: 'Please add a valid Amazon Music Url.',
            },
        }
    },
    applemusic_url: {
        validators: {
            notEmpty: {
                message: "Apple Music Url is required"
            },
            regexp: {
                regexp: appleMusicUrlRegex,
                message: 'Please add a valid Apple Music Url.',
            },
        }
    },
    beatport_url: {
        validators: {
            notEmpty: {
                message: "Beatport Url is required"
            },
            regexp: {
                regexp: beatportUrlRegex,
                message: 'Please add a valid Beatport Url.',
            },
        }
    },
    deezer_url: {
        validators: {
            notEmpty: {
                message: "Deezer Url is required"
            },
            regexp: {
                regexp: deezerUrlRegex,
                message: 'Please add a valid Deezer Url.',
            },
        }
    },
    junodownload_url: {
        validators: {
            notEmpty: {
                message: "Junodownload Url is required"
            },
            regexp: {
                regexp: junoDownloadUrlRegex,
                message: 'Please add a valid Junodownload Url.',
            },
        }
    },
    soundcloud_url: {
        validators: {
            notEmpty: {
                message: "Soundcloud Url is required"
            },
            regexp: {
                regexp: soundCloudUrlRegex,
                message: 'Please add a valid Soundcloud Url.',
            },
        }
    },
    spotify_url: {
        validators: {
            notEmpty: {
                message: "Spotify Url is required"
            },
            regexp: {
                regexp: spotifyUrlRegex,
                message: 'Please add a valid Spotify Url.',
            },
        }
    },
    tidal_url: {
        validators: {
            notEmpty: {
                message: "Tidal Url is required"
            },
            regexp: {
                regexp: tidalUrlRegex,
                message: 'Please add a valid Tidal Url.',
            },
        }
    },
    traxsource_url: {
        validators: {
            notEmpty: {
                message: "Traxsource Url is required"
            },
            regexp: {
                regexp: traxsourceUrlRegex,
                message: 'Please add a valid Traxsource Url.',
            },
        }
    },
    youtube_url: {
        validators: {
            notEmpty: {
                message: "Youtube Url is required"
            },
            regexp: {
                regexp: youtubeUrlRegex,
                message: 'Please add a valid Youtube Url.',
            },
        }
    },
    other_url: {
        validators: {
            regexp: {
                regexp: validUrlRegex,
                message: 'Please add a valid Url.',
            },
        }
    },
  },
  plugins: {
    trigger: new Trigger(),
    bootstrap: new Bootstrap(),
    submitButton: new SubmitButton(),
    declarative: new Declarative({
      html5Input: true
    }),
    sequence: new Sequence({
      enabled: true
    }),
  }
};

export { opt };