import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const generateRandomHex = (length) => {
  return [...Array(length)]
  .map(() => Math.floor(Math.random() * 16).toString(16))
  .join('');
}

export const tradeRegistryExportUpload = (file, token) => {
  const fileName = generateRandomHex(10);
  const fileType = "application/pdf";
  const fileExtension = "pdf";

  return axios({
    method: "GET",
    url: `${API_URL}obtain-signed-url-for-upload/?filename=media/users/business_certificates/${fileName}.${fileExtension}&filetype=${fileType}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": X_API_KEY,
    }
  }).then(function(response) {
    const URL = response.data.signed_url.url;
    const full_URL= response.data.signed_url.fields.key;
    let split = full_URL.split('/');
    split.shift();
    let transformedURL = split.join('/');

    const signedOpts = response.data.signed_url.fields;
    var options = {
      mode: 'cors',
      headers: {
        "Content-Type": fileType,
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        document.getElementById("uploadProgress_utre").style.width = percentCompleted + '%';
        document.getElementById("uploadProgress_text_utre").innerHTML = percentCompleted + '%'; 
      },
    };
    var postData = new FormData();
    for (let i in signedOpts) {
      postData.append(i, signedOpts[i]);
    }
    postData.append("file", file);
    
    return axios
      .post(URL, postData, options)
      .then(result => {
        return transformedURL;
      })
      .catch(error => {
        return null
      });
  })
}