import React, { useState } from "react";
import {
    Row,
    Col,
    Label,
    Button,
    FormGroup,
    CustomInput,
} from "reactstrap";

const StepZero = ({ jumpToStep }) => {
    const [isChecked, setIsChecked] = useState(false);

    return (
        <div className="step step0 mt-5">
            <Row className="justify-content-md-center">
                <Col lg="6">
                    <p>By providing this information, you acknowledge and agree that it will be provided to the best of your knowledge and ability. We strive to ensure that all information is accurate and truthful.</p>
                    <p>Please be aware that providing false information or misrepresenting the truth can have significant consequences. We urge all users to verify the information they provide and to use this platform responsibly.</p>
                    <p>The liability for any misinformation or errors lies solely with the individual providing the information. We assume no responsibility for the consequences of incorrect or misleading information provided by users.</p>
                    <p>By clicking <b>"Next"</b>, you consent to these terms and affirm that you are fully aware of the implications of providing inaccurate or false information.</p>
                    <FormGroup>
                        <Label check>
                           <CustomInput
                                type="checkbox"
                                name="terms_agreement"
                                id="terms_agreement"
                                checked={isChecked}
                                onChange={() => setIsChecked(!isChecked)}
                            />
                                I fully agree and understand the terms above.
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
            <Button
                className="btn btn-next btn-primary pull-right"
                style={{ color: "#ffffff", backgroundColor: "#000a60" }}
                type="submit"
                onClick={() => {
                    if (isChecked) {
                        jumpToStep(1);
                    }
                }}
                disabled={!isChecked}
            >
                Next
            </Button>
        </div>
    );
};

export default StepZero;