import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import QCNote from "./qcNote";
import { 
  Alert,
  Col,
  Button,
  Row,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit";
import { useFetch } from "../utils/fetchHook";
import {  useLocation, Link } from "react-router-dom";
import { getCookie } from "../jwt/_helpers/cookie";
import TableHelper from "../components/tableHelper";
import { StateContext } from "../utils/context";
import TracksUpdate from "../views/tracks/tracksUpdate";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const pagination = paginationFactory({
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ]
});

const TracksTable = () => {
  const { SearchBar, ClearSearchButton } = Search;
  const {currentUser} = useContext(StateContext);
  const token = getCookie("token");
  const location = useLocation();
  const currentID = location.pathname.split("/")[2];

  const [selectedTrackToEdit, setTrackToEdit] = useState(false);
  const [successPATCH, successPATCHset] = useState(false);

  const [forceUpdate, forceUpdateSET] = useState(0);;

  const [data, error, loading] = useFetch("GET",`releases/${currentID}/`,token,false,forceUpdate);
  const [dataGenres] = useFetch( "GET", `genres/`, token, false);
  const [appVariables] = useFetch("GET", "app-variables/", token, false, forceUpdate);
  const [contributorRoles] = useFetch( "GET", `contributor-roles/`, token, false);
  const [shareholderRoles] = useFetch( "GET", `shareholder-roles/`, token, false);

  const [allTracksReady, setAllTracksReady] = useState(false);
  const [releaseStatus, setReleaseStatus] = useState("");
  const [openDistribute, setOpenDistribute] = useState(false);
  const [openFinishEditing, setOpenFinishEditing] = useState(false);
  const [todUrl, setTodUrl] = useState('#');
  const [touUrl, setTouUrl] = useState('#');
  const [ppUrl, setPpUrl] = useState('#');
  const [redirect, setRedirect] = useState(false);

  function rowHighlightStyle(row, rowIndex) {
    let hasQCFeedback = !row.qc_passed && !row.edited_after_qc;
    if (hasQCFeedback) {
      return "qc-issues-alert";
    }
    return "";
  }

  const setSuccessEdit = () => {
    successPATCHset(true);
    setTimeout(() => {
      successPATCHset(false);
    }, 3000);
  };

  const updateState = {
    forceUpdate,
    forceUpdateSET
  };
  const onEditClick = id => {
    setTrackToEdit(id);
    [].forEach.call(document.querySelectorAll("tbody tr"), function(el) {
      el.classList.remove("activeRow");
    });
    document
      .querySelector(`#rowEdit_${id}`)
      .closest("tr")
      .classList.add("activeRow");
  };

  const actionFormatter = (cell, row) => {
    return (
      <Button
        outline
        color="primary"
        id={`rowEdit_${row.id}`}
        onClick={() => onEditClick(row.id)}
      >
        Edit
      </Button>
    );
  };
  const mixFormatter = (cell, row) => (cell ? cell : "-");
  const nameFormatter = (cell, row) => <span className="fontWeight700">{cell}</span>;

  const lenghtFormatter = (cell, row) => {
    if (!cell) {
      return '';
    }
    let base = cell;
    let splited = base.split(":");
    splited[splited.length -1]  = splited[splited.length -1 ].substring(0,2)
    const newCell = splited.join(":");
    return newCell;
  };


  const trackCompleteFormatter = cell => {
    return (
      <>
        {cell ? (
          <Button className="btn-status" disabled color="success">
            Ready
          </Button>
        )
        :
          (
          <Button className="btn-status" disabled color="warning">
            Not Ready
          </Button>
        )}
      </>
    );
  };

  const trackAccStatusFormatter = cell => {
    return (
      <>
        {cell ? (
          <span className="p-4">
            <i className="fas fa-2x fa-check-circle text-success"></i>
          </span>
        )
        :
          (
            <span className="p-4">
              <i className="fas fa-2x fa-times-circle text-danger"></i>
            </span>
        )}
      </>
    );
  };

  const audioPlayerFormatter = (cell, row) => {
    const trackRes = cell && cell.length > 0 ? cell : row.resource;
    const trackType = cell && cell.length > 0 ? "audio/mp3" : "audio/wav";
    return (
      <audio
        controls
        id={'track_play_' + row.id}
        controlsList="nodownload"
        onPlay={() => handlePlayClick(row.id)}
      >
        <source src={trackRes} type={trackType} />
      Your browser does not support the audio element.
      </audio>
    )
  };

  const handlePlayClick = (id) => {
    const trArray = document.querySelectorAll('[id^="track_play_"]');
    trArray.forEach((audio) => {
      if ( !audio.paused && audio.id !== `track_play_${id}`){
          audio.pause();
      }
    });
  };

  const qcNoteFormatter = (cell, row) => {
    return (
      <QCNote {...row} noteType={"Track"}></QCNote>
    );
  };

  const distributeRelease = (ID) => {
    axios({
      method: "patch",
      mode: 'cors',
      url: `${API_URL}releases/${ID}/`,
      data: {
        status: "approval"
      },
      headers: {
        Authorization: `JWT ${token}`,
        "x-api-key": X_API_KEY,
        "Content-Type": "application/json"
      }
    }).then(function(response) {
      if (response.status === 200) {
        setOpenDistribute(false);
        setRedirect(true);
      }
    });
  };

  const submitRedeliveryEditing = (ID) => {
    axios({
      method: "patch",
      mode: 'cors',
      url: `${API_URL}releases/${ID}/`,
      data: {
        status: "re_delivery-delivery"
      },
      headers: {
        Authorization: `JWT ${token}`,
        "x-api-key": X_API_KEY,
        "Content-Type": "application/json"
      }
    }).then(function(response) {
      if (response.status === 200) {
        setOpenFinishEditing(false);
        setRedirect(true);
      }
    });
  };

  const patchRelease = (ID, status) => {
    axios({
      method: "patch",
      mode: 'cors',
      url: `${API_URL}releases/${ID}/`,
      data: {
        status: status
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": X_API_KEY,
        "Content-Type": "application/json"
      }
    }).then(function(response) {
      if (response.status === 200) {
        forceUpdateSET(forceUpdate + 1);
      }
    });
  };

  useEffect(() => {
    if(data.all_tracks_ready) {
      setAllTracksReady(true);
    }
    if (data.all_tracks_ready && data.status === "offline") {
      patchRelease(data.id, "ready");
    }
    if(data.status) {
      setReleaseStatus(data.status);
    }
  }, [data]);

  useEffect(() => {
    if (appVariables) {
      if(appVariables) {
        setTodUrl(appVariables['terms-of-distribution-url']);
        setTouUrl(appVariables['terms-of-use-url']);
        setPpUrl(appVariables['privacy-policy-url']);
      }
    }
  }, [appVariables]);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: nameFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "190px" };
      }
    },
    {
      dataField: "mix_name",
      text: "Mix Name",
      sort: true,
      formatter: mixFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "135px" };
      }
    },
    {
      dataField: "track_lenght",
      text: "Mix Length",
      sort: true,
      formatter: lenghtFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "135px" };
      }
    },
    {
      dataField: "mp3_resource",
      text: "Player",
      sort: false,
      csvExport: false,
      formatter: audioPlayerFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "333px" };
      }
    },
    {
      dataField: "track_data_complete",
      text: "Status",
      sort: true,
      csvExport: false,
      formatter: trackCompleteFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "120px" };
      }
    },
    {
      dataField: "track_ready_for_accounting",
      text: "Acc Status",
      sort: true,
      csvExport: false,
      formatter: trackAccStatusFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "135px" };
      }
    },
    {
      dataField: "qc",
      text: "QC",
      formatter: qcNoteFormatter,
      sort: false,
      csvExport: false,
      headerStyle: (colum, colIndex) => {
        return { width: "80px" };
      }
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
      sort: false,
      csvExport: false,
      headerStyle: (colum, colIndex) => {
        return { width: "111px" };
      }
    },
  ];

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <CardTitle tag="h4">Edit Tracks</CardTitle>
              {loading ? (
                <TableHelper loading />
              ) : error ? (
                <TableHelper error />
              ) : (
                <ToolkitProvider
                  keyField={"order"}
                  data={data.tracks}
                  columns={columns}
                  search={{
                    searchFormatted: true
                  }}
                  exportCSV
                >
                  {props => (
                    <div className="tracksTable">
                      <Row>
                        <Col>
                          <div className="customSearch">
                            <SearchBar {...props.searchProps} />
                            <ClearSearchButton
                              className="customClear"
                              text="x"
                              {...props.searchProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <BootstrapTable
                        rowClasses={rowHighlightStyle}
                        {...props.baseProps}
                        bordered={ false }
                        pagination={pagination}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      { !selectedTrackToEdit &&
        <Row className="float-right mr-1 mb-5">
          <Button className="btn btn-primary" onClick={setRedirect}>Go To Releases</Button>
          {
            releaseStatus === "ready" && <>
              <Button
                id={`restrdis-tooltip-${currentID}`}
                className="btn btn-success ml-2"
                disabled={!allTracksReady || (!currentUser.is_sub_user && !currentUser.identity_checked)}
                onClick={setOpenDistribute}>
                  Distribute
              </Button>
              {(!allTracksReady || (!currentUser.is_sub_user && !currentUser.identity_checked)) && (
              <UncontrolledTooltip
                placement="top"
                trigger="hover"
                target={`restrdis-tooltip-${currentID}`}
              >
                {
                  (!currentUser.is_sub_user && !currentUser.identity_checked) && <>
                    You have not yet provided your complete information, therefore you can't distribute the releases. In order to do so kindly Submit Your Information.<br/><br/>
                  </>
                }

                {
                  !allTracksReady && <>
                    Please submit the metadata for all Tracks in order to Distribute the Release.
                  </>
                }
              </UncontrolledTooltip>
            )}
            </>
          }
          {
            releaseStatus === "re_delivery-editing" && <>
              <Button
                id={`restrse-tooltip-${currentID}`}
                className="btn btn-success ml-2"
                disabled={!allTracksReady}
                onClick={setOpenFinishEditing}>
                  Submit Editing
              </Button>
              {!allTracksReady && (
                <UncontrolledTooltip
                  placement="top"
                  trigger="hover"
                  target={`restrse-tooltip-${currentID}`}
                >
                  Please submit the metadata for all tracks, in order to Submit the Edits.
                </UncontrolledTooltip>
              )}
            </>
          }
        </Row>
      }

      <Modal isOpen={openDistribute} centered={true}>
        <ModalHeader>Are you sure all information entered is valid and represents this release fully?</ModalHeader>
        <ModalBody>
          <p>After this action, your release will be checked and if the information is correct it will be distributed on the official and exclusive distribution dates.</p>
          <p>By confirming and accepting this form, you are fully accepting and complying with the <a href={todUrl} target="blank" rel="noopener noreferrer">Terms Of Distribution</a>, <a href={touUrl} target="blank" rel="noopener noreferrer">Terms Of Use</a> and <a href={ppUrl} target="blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
          <p>By confirming and accepting this form, you are undisputedly confirming that submitted content release with belonging assets, audio files, and metadata, either belongs exclusively to you or it is fully licensed to your label.</p>
          <p>Hereby you fully accept that you will solely be responsible for any misleading information, unlicensed usage, illegal activity or any kind of dispute. </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setOpenDistribute(false)}>
            Cancel
          </Button>
          <Button color="success" onClick={() => distributeRelease(currentID)}>
          Confirm and Accept
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openFinishEditing} centered={true}>
        <ModalHeader>Are you sure all information entered is valid and represents this release fully?</ModalHeader>
        <ModalBody>
        <p>After this action, your release will be checked and if the information is correct it will be re-distributed to the DSP partners. <b>NOTE, only METADATA will be sent to DSP-s, content such as IMAGE and AUDIO files can not be updated this way.</b></p><p> By confirming and accepting this form, you are fully accepting and complying with the <Link to={"/terms-and-conditions"} target="_blank">Terms Of Use</Link> and Terms of our Exclusive Distribution Agreement.</p><p> By confirming and accepting this form, you are fully accepting and complying with the <Link to={"/privacy-policy"} target="_blank">Privacy Policy</Link>.</p><p>By confirming and accepting this form, you are undisputedly confirming that submitted content release with belonging assets, audio files, and metadata, either belongs exclusively to you or it is fully licensed to your label.</p><p>Hereby you fully accept that you will solely be responsible for any misleading information, unlicensed usage, illegal activity or any kind of dispute. </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setOpenFinishEditing(false)}>
            Cancel
          </Button>
          <Button color="success" onClick={() => submitRedeliveryEditing(currentID)}>
          Confirm and Accept
          </Button>
        </ModalFooter>
      </Modal>

      {selectedTrackToEdit && (
        <TracksUpdate
          releaseData={data}
          id={selectedTrackToEdit}
          data={{
            dataGenres: dataGenres,
            contributorRoles,
            shareholderRoles
          }}
          updateState={updateState}
          setTrackToEdit={() => setTrackToEdit()}
          setSuccessEdit={() => setSuccessEdit()}
        />
      )}

      {successPATCH && (
        <Alert style={{ paddingTop: 20 }} color="success">
          Track has been updated!
        </Alert>
      )}
      {redirect ? <Redirect to="/releases" /> : null}
    </>
  );
};

export default TracksTable;
