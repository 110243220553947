import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import axios from "axios";
import { getCookie } from "../jwt/_helpers/cookie";
import LoaderInner from "./LoaderInner";

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const SubscriptionTableModalRetryPayment = ({
  openModal,
  setToggleModal,
  values,
  apiURL,
  subID,
}) => {
  const tokenAPI = getCookie("token");

  const updateData = {
    "id": subID,
    "action": "retry_payment",
  }

  const [inProgress, setInProgress] = useState(false);

  const handleUpdate = () => {
    const options = {
      method: "POST",
      data: updateData,
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${tokenAPI}`,
        "x-api-key": X_API_KEY,
        "Content-Type": "application/json"
      }
    };

    setInProgress(true);

    axios(`${API_URL}${apiURL}/`, options)
    .then(res => {
      setInProgress(false);
      setToggleModal(false);
      values.setSuccess(true);
      values.setForce(values.forceUpdate + 1);
    })
    .catch(err => {
      let errorMsg = " Something went wrong! Please refresh page and try again!";
      if (err.response) {
        if (err.response.status === 400) {
          let errorData = err.response.data;
          errorMsg = errorData["error_message"];
        }
      }
      values.setError(true);
      values.setErrorMsg(errorMsg);
      setInProgress(false);
      setToggleModal(false);
    });
  
  };
  return (
    <>
        <LoaderInner show={ inProgress}/>
        <Modal isOpen={openModal} centered={true}>
            <ModalHeader>Retry Subscription Payment</ModalHeader>
            <ModalBody>
                Are you sure that you want to retry the payment for subscription?
                <br/><br/>
                <b>Note:</b> Latest subscription pricing will apply!

            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => setToggleModal(false)} disabled={inProgress}>
                Cancel
                </Button>
                <Button color={"success"} onClick={() => handleUpdate()} disabled={inProgress}>
                Retry
                </Button>
            </ModalFooter>
        </Modal>
    </>
  );
};

export default SubscriptionTableModalRetryPayment;