import React, { useState, useEffect, useMemo } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FormText } from "reactstrap";
import * as Yup from "yup";
import axios from "axios";
import AsyncDropdownSpotify from "../../components/asyncDropdownSpotify";
import AsyncDropdownDeezer from "../../components/asyncDropdownDeezer";
import AsyncDropdownAmazon from "../../components/asyncDropdownAmazon";
import AsyncSelect from "react-select/async";
import ProfileImg from "../../assets/images/users/1.jpg";

import {
  Input,
  Card,
  Row,
  CardBody,
  Col,
  FormGroup,
  Label,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { useFetch } from "../../utils/fetchHook";
import { getCookie } from "../../jwt/_helpers/cookie";
import TableHelper from "../../components/tableHelper";
import { Redirect } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;


const ArtistsUpdate = ({ match, name }) => {
  const token = getCookie("token");
  const options = {
    method: "GET",
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": X_API_KEY,
      "Content-Type": "application/json"
    }
  };
  const currentID = match.url.split("/")[2];
  const [userData, error, loading] = useFetch(
    "GET",
    `artists/${currentID}/`,
    token
  );

  const [success, setSuccess] = useState(false);
  const [errorPut, setErrorPut] = useState(false);
  const [errorNameMismatch, setErrorNameMismatch] = useState(false);
  const [errorAmazonNameMismatch, setErrorAmazonNameMismatch] = useState(false);
  const [errorDeezerNameMismatch, setErrorDeezerNameMismatch] = useState(false);
  const [errorSpotifyNameMismatch, setErrorSpotifyNameMismatch] = useState(false);
  const [errorProfileUnmatched, setErrorProfileUnmatched] = useState(false);
  const [errorDupl, setErrorDupl] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [profileMissingModalVisible, setProfileMissingModalVisible] = useState(false);
  const [profileResetModalVisible, setProfileResetModalVisible] = useState(false);

  const [hasSpotifyProfile, setHasSpotifyProfile] = useState(true);
  const [hasDeezerProfile, setHasDeezerProfile] = useState(true);
  const [hasAmazonProfile, setHasAmazonProfile] = useState(true);
  const [hasAppleProfile, setHasAppleProfile] = useState(true);
  const [spotifyArtist, setSpotifyArtist] = useState(null);
  const [deezerArtist, setDeezerArtist] = useState(null);
  const [amazonArtist, setAmazonArtist] = useState(null);
  const [missingStoreName, setMissingStoreName] = useState("");
  const [resetStoreName, setResetStoreName] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [imageBig, setImageBig] = useState("");
  const [imageSmall, setImageSmall] = useState("");

  const [namePreference, setNamePreference] = useState(null);

  const enabledProfilesCount = [hasSpotifyProfile, hasDeezerProfile, hasAmazonProfile].filter(Boolean).length;
  const selectedArtistsCount = [spotifyArtist, deezerArtist, amazonArtist].filter(Boolean).length;

  const CustomOption = useMemo(() => {
    return React.memo(({ innerProps, data, isFocused }) => (
      <div {...innerProps} style={{ 
          padding: '3px',
          backgroundColor: isFocused ? '#f5f5f5' : 'transparent',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}>
        <img
          src={data.image_small || ProfileImg}
          alt={data.label}
          style={{
            padding: "5px",
            height: '65px',
            width: '65px',
            borderRadius: '30%',
            marginRight: "7px",
          }}
        />
        <b>{data.label}</b>
      </div>
    ));
  }, []);

  useEffect(() => {
    if(missingStoreName) {
      setProfileMissingModalVisible(true);
    }
  }, [missingStoreName])

  useEffect(() => {
    if(resetStoreName) {
      setProfileResetModalVisible(true);
    }
  }, [resetStoreName])

  useEffect(() => {
    let amazonArtistName = amazonArtist ? amazonArtist.name.toLowerCase() : null;
    let deezerArtistName = deezerArtist ? deezerArtist.name.toLowerCase() : null;
    let spotifyArtistName = spotifyArtist ? spotifyArtist.name.toLowerCase() : null;
    let artistName = userData.name;
    if(artistName) {
      artistName = artistName.toLowerCase();
    }

    const artistNames = [amazonArtistName, deezerArtistName, spotifyArtistName].filter(name => name !== null);
    const allNamesMatch = artistNames.every(name => name === artistNames[0]);

    if (artistNames.length > 0) {
      setErrorNameMismatch(!allNamesMatch);
    }

    if(spotifyArtist && spotifyArtistName !== artistName) {
      setErrorSpotifyNameMismatch(true);
    }

    if(deezerArtist && deezerArtistName !== artistName) {
      setErrorDeezerNameMismatch(true);
    }

    if(amazonArtist && amazonArtistName !== artistName) {
      setErrorAmazonNameMismatch(true);
    }

    if (
      (hasSpotifyProfile && !spotifyArtist) ||
      (hasDeezerProfile && !deezerArtist) ||
      (hasAmazonProfile && !amazonArtist)
    ) {
      setErrorProfileUnmatched(true);
    } else {
      setErrorProfileUnmatched(false);
    }
  }, [amazonArtist, deezerArtist, spotifyArtist, hasSpotifyProfile, hasDeezerProfile, hasAmazonProfile]);
    

  useEffect(() => {
    setImageBig(userData.image_big);
    setImageSmall(userData.image_small);
    setDefaultQuery(userData.name);

    if(userData.spotify_details) {
      let spotifyDetails = userData.spotify_details;
      let spotifyImageBig = null;
      let spotifyImageSmall = null;
      if (spotifyDetails.images && spotifyDetails.images.length > 0) {
        spotifyImageBig = spotifyDetails.images[0].url;
        if (spotifyDetails.images.length > 2) {
          spotifyImageSmall = spotifyDetails.images[2].url;
        }
        else if (spotifyDetails.images.length > 1) {
          spotifyImageSmall = spotifyDetails.images[1].url;
        }
        else {
          spotifyImageSmall = null;
        }
      }
      setSpotifyArtist({
        value: spotifyDetails["id"],
        name: spotifyDetails["name"],
        label: spotifyDetails["name"],
        popularity: spotifyDetails["popularity"], 
        followers: spotifyDetails["followers"]["total"],
        image_big: spotifyImageBig,
        image_small: spotifyImageSmall,
      });
    }

    if(userData.deezer_details) {
      let deezerDetails = userData.deezer_details;
      setDeezerArtist({
        value: deezerDetails["id"],
        name: deezerDetails["name"],
        label: deezerDetails["name"],
        nb_album: deezerDetails["nb_album"],
        nb_fan: deezerDetails["nb_fan"],
        image_small: deezerDetails["picture_small"] || null,
        image_medium: deezerDetails["picture_medium"] || null,
        image_big: deezerDetails["picture_big"] || null,
        image_xl: deezerDetails["picture_xl"] || null,
      });
    }

    if(userData.amazon_music_details) {
      let amazonDetails = userData.amazon_music_details;
      let artist = amazonDetails["data"]["artist"];
      let images = artist["images"] ? artist["images"].filter(image => 
        image.imageType === 'PROFILE'
      ) : [];
      images.sort((a, b) => (b.height * b.width) - (a.height * a.width));
      let image_big = images[0]?.url || null;
      let image_small = images.length === 1 ? image_big : images[images.length - 1]?.url || null;
  
      setAmazonArtist({
        value: artist["id"],
        name: artist["name"],
        label: artist["name"],
        image_big: image_big,
        image_small: image_small,
      });
    }
  }, [userData])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    spotifyId: Yup.string()
      .matches(
        /^[0-9A-Za-z_-]{22}$/,
        "Please add a valid Spotify ID. Example: 6eUKZXaKkcviH0Ku9w2n3B . Check our Help Center for more info."
      ),
    appleId: hasAppleProfile
      ? Yup.string()
          .required("Apple ID is required")
          .matches(
            /^[0-9]*$/,
            "Please add a valid Apple ID. Example: 635806095 . Check our Help Center for more info."
          )
      : Yup.string().notRequired(),
  });

  return (
    <div>
      {loading ? (
        <TableHelper loading />
      ) : error ? (
        <TableHelper error />
      ) : (
        <>
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <h3>Edit Artist</h3>
                  <Formik
                    initialValues={{
                      name: userData.name,
                      email: userData.email,
                      spotifyId: userData.spotify_identifier,
                      deezerId: userData.deezer_identifier,
                      amazonId: userData.amazon_music_identifier,
                      appleId: userData.apple_identifier
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(
                        { name, email, spotifyId, appleId, deezerId, amazonId },
                        { setStatus, setSubmitting }
                      ) => {
                        setStatus();
                        setErrorNameMismatch(false);

                        const options = {
                          method: "PATCH",
                          mode: 'cors',
                          headers: {
                            Authorization: `Bearer ${token}`,
                            "x-api-key": X_API_KEY,
                            "Content-Type": "application/json"
                          }
                        };

                        axios
                        .patch(
                          `${API_URL}artists/${currentID}/`,
                          {
                            "name": name,
                            "email": email,
                            "spotify_identifier": spotifyId,
                            "apple_identifier": appleId,
                            "deezer_identifier": deezerId,
                            "amazon_music_identifier": amazonId,
                            "image_big": imageBig,
                            "image_small": imageSmall,
                            "followers": spotifyArtist ? spotifyArtist.followers : 0,
                            "popularity": spotifyArtist ? spotifyArtist.popularity : 0,
                          },
                          options
                        )
                        .then(res => {
                          setSuccess(true);
                          setTimeout(() => setRedirect(true), 1000);
                        })
                        .catch(err => {
                          setErrorPut(true);
                        });
                    }}
                    render={({
                      errors,
                      status,
                      touched,
                      isSubmitting,
                      setFieldValue
                    }) => (
                      <Form>
                        { hasSpotifyProfile &&
                        <>
                          <FormGroup className="select-search-wrap" row>
                            <Label for="Spotify Profile" sm={2}>
                              Spotify Profile
                            </Label>
                            <Col sm={8}>
                              {spotifyArtist?
                                <AsyncSelect
                                  defaultOptions={[spotifyArtist]}
                                  value={spotifyArtist}
                                  onChange={()=> {}}
                                  components={{
                                    Option: CustomOption,
                                  }}
                                  styles={{
                                    menu: (styles) => ({ ...styles, zIndex: 10 }),
                                  }}
                                />:
                                <>
                                  <AsyncDropdownSpotify
                                    fetchOptions={options}
                                    labelField="name"
                                    onChange={e => {
                                      let artistId = e.value;
                                      let artistObj = e;
                                      artistObj.name = e.label;
                                      setSpotifyArtist(artistObj);
                                      setFieldValue("spotifyId", artistId);
                                      setImageBig(artistObj.image_big);
                                      setImageSmall(artistObj.image_small);
                                    }}
                                    defaultQuery={defaultQuery}
                                    isDisabled={spotifyArtist? true: false}
                                    placeholder="Enter Artist Name i.e. The Weekend or Artist's Spotify ID i.e. 144HzhpLjcR9k37w5Ico9B"
                                  />
                                  <FormText color="muted">
                                  Note: Only use this dropdown if you want to add an Artist who is already on Spotify otherwise click on Not Found button.
                                  </FormText>
                                </>
                              }
                              {
                                errorSpotifyNameMismatch && <p style={{fontSize: "12px",color: "red",margin: "2px 0 0 0"}}>Spotify Profile Name must match with the Artist Name</p>
                              }
                            </Col>
                            <Col sm={2}>
                              {
                                spotifyArtist? 
                                <Button
                                  className="btn btn-outline-info"
                                  style={{width: '100%'}}
                                  onClick={() => {
                                    setResetStoreName("Spotify");
                                  }}
                                >
                                  Reset
                                </Button>:
                                <Button
                                  className="btn btn-outline-danger"
                                  style={{width: '100%'}}
                                  onClick={() => {
                                    if(enabledProfilesCount>=2) {
                                      setMissingStoreName("Spotify");
                                    }
                                  }}
                                >
                                  Not Found?
                                </Button>
                              }
                            </Col>
                          </FormGroup>

                          {
                            spotifyArtist &&
                            <FormGroup row>
                              <Label for="spotifyId" sm={2}>
                                Spotify ID
                              </Label>
                              <Col>
                                <Field
                                  name="spotifyId"
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                />
                              </Col>
                            </FormGroup>
                          }
                          {
                            (spotifyArtist && spotifyArtist.image_big) && 
                            <FormGroup row>
                              <Label sm={2}>Spotify Image</Label>
                              <Col xs={2}>
                                <img
                                  src={spotifyArtist.image_big}
                                  style={{ width: "150px", padding: "10px 0" }}
                                  alt={"Spotify Artist DP"}
                                />
                              </Col>
                            </FormGroup>
                          }
                        </>
                        }

                        { hasDeezerProfile &&
                          <>
                            <FormGroup className="select-search-wrap" row>
                              <Label for="Deezer Profile" sm={2}>
                                Deezer Profile
                              </Label>
                              <Col sm={8}>
                                {deezerArtist?
                                <AsyncSelect
                                  defaultOptions={[deezerArtist]}
                                  value={deezerArtist}
                                  onChange={()=> {}}
                                  components={{
                                    Option: CustomOption,
                                  }}
                                  styles={{
                                    menu: (styles) => ({ ...styles, zIndex: 10 }),
                                  }}
                                />:
                                <>
                                  <AsyncDropdownDeezer
                                    fetchOptions={options}
                                    labelField="name"
                                    onChange={e => {
                                      let artistId = e.value;
                                      let artistObj = e;
                                      artistObj.name = e.label;
                                      setDeezerArtist(artistObj);
                                      setFieldValue("deezerId", artistId);
                                      if(!spotifyArtist && !amazonArtist) {
                                        setImageBig(artistObj.image_big);
                                        setImageSmall(artistObj.image_small);
                                      }
                                    }}
                                    defaultQuery={defaultQuery}
                                    isDisabled={deezerArtist? true: false}
                                    placeholder="Enter Artist Name i.e. The Weekend or Artist's Deezer ID i.e. 1406580"
                                  />
                                  <FormText color="muted">
                                    Note: Only use this dropdown if you want to add an Artist who is already on Deezer otherwise click on Not Found button.
                                  </FormText>
                                </>
                                }
                                {
                                  errorDeezerNameMismatch && <p style={{fontSize: "12px",color: "red",margin: "2px 0 0 0"}}>Deezer Profile Name must match with the Artist Name</p>
                                }
                              </Col>
                              <Col sm={2}>
                                {
                                  deezerArtist? 
                                  <Button
                                    className="btn btn-outline-info"
                                    style={{width: '100%'}}
                                    onClick={() => {
                                      setResetStoreName("Deezer");
                                    }}
                                  >
                                    Reset
                                  </Button>:
                                  <Button
                                    className="btn btn-outline-danger"
                                    style={{width: '100%'}}
                                    onClick={() => {
                                      if(enabledProfilesCount>=2) {
                                        setMissingStoreName("Deezer");
                                      }
                                    }}
                                  >
                                    Not Found?
                                  </Button>
                                }
                              </Col>
                            </FormGroup>

                            {
                              deezerArtist &&
                              <FormGroup row>
                                <Label for="deezerId" sm={2}>
                                  Deezer ID
                                </Label>
                                <Col>
                                  <Field
                                    name="deezerId"
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                  />
                                </Col>
                              </FormGroup>
                            }
                            {
                              (deezerArtist && deezerArtist.image_big) && 
                              <FormGroup row>
                                <Label sm={2}>Deezer Image</Label>
                                <Col xs={2}>
                                  <img
                                    src={deezerArtist.image_big}
                                    style={{ width: "150px", padding: "10px 0" }}
                                    alt={"Deezer Artist DP"}
                                  />
                                </Col>
                              </FormGroup>
                            }
                          </>
                        }

                        { hasAmazonProfile &&
                          <>
                            <FormGroup className="select-search-wrap" row>
                              <Label for="Amazon Music Profile" sm={2}>
                                Amazon Music Profile
                              </Label>
                              <Col>
                                {amazonArtist?
                                <AsyncSelect
                                  defaultOptions={[amazonArtist]}
                                  value={amazonArtist}
                                  onChange={()=> {}}
                                  components={{
                                    Option: CustomOption,
                                  }}
                                  styles={{
                                    menu: (styles) => ({ ...styles, zIndex: 10 }),
                                  }}
                                />:
                                <>
                                  <AsyncDropdownAmazon
                                    fetchOptions={options}
                                    labelField="name"
                                    onChange={e => {
                                      let artistId = e.value;
                                      let artistObj = e;
                                      artistObj.name = e.label;
                                      setAmazonArtist(artistObj);
                                      setFieldValue("amazonId", artistId);
                                      if(!spotifyArtist && !deezerArtist) {
                                        setImageBig(artistObj.image_big);
                                        setImageSmall(artistObj.image_small);
                                      }
                                    }}
                                    defaultQuery={defaultQuery}
                                    isDisabled={amazonArtist? true: false}
                                    placeholder="Enter Artist Name i.e. The Weekend or Artist's Amazon ASIN i.e. B005GI0T2U"
                                  />
                                  <FormText color="muted">
                                    Note: Only use this dropdown if you want to add an Artist who is already on Amazon Music otherwise click on Not Found button.
                                  </FormText>
                                </>
                                }
                                {
                                  errorAmazonNameMismatch && <p style={{fontSize: "12px",color: "red",margin: "2px 0 0 0"}}>Amazon Profile Name must match with the Artist Name</p>
                                }
                              </Col>
                              <Col sm={2}>
                                {
                                  amazonArtist? 
                                  <Button
                                    className="btn btn-outline-info"
                                    style={{width: '100%'}}
                                    onClick={() => {
                                      setResetStoreName("Amazon Music");
                                    }}
                                  >
                                    Reset
                                  </Button>:
                                  <Button
                                    className="btn btn-outline-danger"
                                    style={{width: '100%'}}
                                    onClick={() => {
                                      if(enabledProfilesCount>=2) {
                                        setMissingStoreName("Amazon Music");
                                      }
                                    }}
                                  >
                                    Not Found?
                                  </Button>
                                }
                              </Col>
                            </FormGroup>

                            {
                              amazonArtist &&
                              <FormGroup row>
                                <Label for="amazonId" sm={2}>
                                  Amazon ID
                                </Label>
                                <Col>
                                  <Field
                                    name="amazonId"
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                  />
                                </Col>
                              </FormGroup>
                            }
                            {
                              (amazonArtist && amazonArtist.image_big) && 
                              <FormGroup row>
                                <Label sm={2}>Amazon Music Image</Label>
                                <Col xs={2}>
                                  <img
                                    src={amazonArtist.image_big}
                                    style={{ width: "150px", padding: "10px 0" }}
                                    alt={"Amazon Music Artist DP"}
                                  />
                                </Col>
                              </FormGroup>
                            }
                          </>
                        }

                        { hasAppleProfile &&
                          <FormGroup row>
                            <Label for="appleId" sm={2}>
                              Apple ID
                            </Label>
                            <Col sm={8}>
                              <Field
                                name="appleId"
                                type="text"
                                placeholder="Please enter a valid Apple ID. Example: 635806095"
                                className={
                                  "form-control" +
                                  (errors.appleId && touched.appleId
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <FormText color="muted">
                                Note: Only use this field if you want to add an Artist who is already on Apple Music otherwise click on Not Found button.
                              </FormText>
                              <ErrorMessage
                                name="appleId"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                            <Col sm={2}>
                                <Button
                                  className="btn btn-outline-danger"
                                  style={{width: '100%'}}
                                  onClick={() => {
                                    setMissingStoreName("Apple Music");
                                  }}
                                >
                                  Not Found?
                                </Button>
                            </Col>
                          </FormGroup>
                        }

                        <FormGroup row>
                          <Label for="name" sm={2}>
                            Name
                          </Label>
                          <Col>
                            <Field
                              name="name"
                              type="text"
                              disabled={true}
                              className={
                                "form-control" +
                                ((errorNameMismatch || errors.name)  && touched.name ? " is-invalid" : "")
                              }
                            />
                            {
                              errorDupl && <p style={{fontSize: "12px",color: "red",margin: "2px 0 0 0"}}>Artist already exists</p>
                            }
                            {
                              errorNameMismatch && <p style={{fontSize: "12px",color: "red",margin: "2px 0 0 0"}}>Artist name must match for all stores</p>
                            }
                          </Col>
                        </FormGroup>
                        
                        { (selectedArtistsCount>1 && !(errorSpotifyNameMismatch || errorDeezerNameMismatch || errorAmazonNameMismatch)) &&
                          <FormGroup row>
                            <Label for="Name Preference" sm={2}>
                              Name Preference
                            </Label>
                            <Col>
                              <div style={{ display: "flex", justifyContent: "normal" }} >
                                {
                                  spotifyArtist &&
                                  <FormGroup check className="mr-4">
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="prefer-spotify"
                                        value="spotify"
                                        checked={namePreference === "spotify"}
                                        onChange={() => {
                                          setNamePreference("spotify")
                                          setFieldValue("name", spotifyArtist.name);
                                        }}
                                      />{" "}
                                      <span className="mt-1">Spotify</span>
                                    </Label>
                                  </FormGroup>
                                }

                                {
                                  deezerArtist &&
                                  <FormGroup check className="mr-4">
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="prefer-deezer"
                                        value="spotify"
                                        checked={namePreference === "deezer"}
                                        onChange={() => {
                                          setNamePreference("deezer")
                                          setFieldValue("name", deezerArtist.name);
                                        }}
                                      />{" "}
                                      <span className="mt-1">Deezer</span>
                                    </Label>
                                  </FormGroup>
                                }

                                {
                                  amazonArtist &&
                                  <FormGroup check className="mr-4">
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="prefer-amazon"
                                        value="amazon"
                                        checked={namePreference === "amazon"}
                                        onChange={() => {
                                          setNamePreference("amazon")
                                          setFieldValue("name", amazonArtist.name);
                                        }}
                                      />{" "}
                                      <span className="mt-1">Amazon Music</span>
                                    </Label>
                                  </FormGroup>

                                }

                              </div>
                            </Col>
                          </FormGroup>
                        }

                        <FormGroup row>
                          <Label for="email" sm={2}>
                            Email
                          </Label>
                          <Col>
                            <Field
                              name="email"
                              type="text"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col>
                            <Button color="success" disabled={errorDupl || errorNameMismatch || errorProfileUnmatched} type="submit">
                              Submit
                            </Button>
                          </Col>
                        </FormGroup>

                        <Modal isOpen={profileMissingModalVisible} centered={true}>
                          <ModalHeader>
                            Profile Not Present Declaration
                          </ModalHeader>
                          <ModalBody>
                            I confirm that this Artist's profile is not present on <strong>{missingStoreName}</strong> and that the profile of this Artist be created freshly on <strong>{missingStoreName}</strong>.
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="secondary"
                              onClick={() => {
                                setProfileMissingModalVisible(false);
                                setMissingStoreName("Spotify");
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              color="success"
                              onClick={() => {
                                if(missingStoreName === "Spotify") {
                                  setHasSpotifyProfile(false);
                                  setSpotifyArtist(null);
                                  setFieldValue("spotifyId", "");
                                }
                                if(missingStoreName === "Deezer") {
                                  setHasDeezerProfile(false);
                                  setDeezerArtist(null);
                                  setFieldValue("deezerId", "");
                                }
                                if(missingStoreName === "Amazon Music") {
                                  setHasAmazonProfile(false);
                                  setAmazonArtist(null);
                                  setFieldValue("amazonId", "");
                                }
                                if(missingStoreName === "Apple Music") {
                                  setHasAppleProfile(false);
                                  setFieldValue("appleId", "");
                                }
                                setProfileMissingModalVisible(false);
                                setMissingStoreName("");
                              }}
                            >
                              Confirm
                            </Button>
                          </ModalFooter>
                        </Modal>

                        <Modal isOpen={profileResetModalVisible} centered={true}>
                          <ModalHeader>
                            Profile Reset Declaration
                          </ModalHeader>
                          <ModalBody>
                            I am aware that by resetting this Artist's <strong>{resetStoreName}</strong> profile will remove the <strong>{resetStoreName} ID</strong> from everywhere in the system and by doing so, it is likely to cause disruptions in the stores and artists to mismatch.
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="secondary"
                              onClick={() => {
                                setProfileResetModalVisible(false);
                                setResetStoreName("");
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              color="success"
                              onClick={() => {
                                if(resetStoreName === "Spotify") {
                                  setSpotifyArtist(null);
                                  setErrorNameMismatch(false);
                                  setErrorSpotifyNameMismatch(false);
                                  setErrorDupl(false);
                                  setFieldValue("spotifyId", "");
                                  if(!deezerArtist && !amazonArtist) {
                                    setImageBig("");
                                    setImageSmall("");
                                  } else if(namePreference === "spotify") {
                                    if(deezerArtist) {
                                      setNamePreference("deezer");
                                      setImageBig(deezerArtist.image_big);
                                      setImageSmall(deezerArtist.image_small);
                                    } else if (amazonArtist) {
                                      setNamePreference("amazon");
                                      setImageBig(amazonArtist.image_big);
                                      setImageSmall(amazonArtist.image_small);
                                    }
                                  }
                                }
                                if(resetStoreName === "Deezer") {
                                  setDeezerArtist(null);
                                  setErrorNameMismatch(false);
                                  setErrorDeezerNameMismatch(false);
                                  setErrorDupl(false);
                                  setFieldValue("deezerId", "");
                                  if(!spotifyArtist && !amazonArtist) {
                                    setImageBig("");
                                    setImageSmall("");
                                  } else if(namePreference === "deezer") {
                                    if(spotifyArtist) {
                                      setNamePreference("spotify");
                                      setImageBig(spotifyArtist.image_big);
                                      setImageSmall(spotifyArtist.image_small);
                                    } else if (amazonArtist) {
                                      setNamePreference("amazon");
                                      setImageBig(amazonArtist.image_big);
                                      setImageSmall(amazonArtist.image_small);
                                    }
                                  }
                                }
                                if(resetStoreName === "Amazon Music") {
                                  setAmazonArtist(null);
                                  setErrorNameMismatch(false);
                                  setErrorAmazonNameMismatch(false);
                                  setErrorDupl(false);
                                  setFieldValue("amazonId", "");
                                  if(!spotifyArtist && !deezerArtist) {
                                    setImageBig("");
                                    setImageSmall("");
                                  } else if(namePreference === "amazon") {
                                    if(spotifyArtist) {
                                      setNamePreference("spotify");
                                      setImageBig(spotifyArtist.image_big);
                                      setImageSmall(spotifyArtist.image_small);
                                    } else if (deezerArtist) {
                                      setNamePreference("deezer");
                                      setImageBig(deezerArtist.image_big);
                                      setImageSmall(deezerArtist.image_small);
                                    }
                                  }
                                }
                                setProfileResetModalVisible(false);
                                setResetStoreName("");
                              }}
                            >
                              Confirm
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </Form>
                    )}
                  />
                  {success && (
                    <Alert color="success">
                      Artist of name "{userData.name}" has been updated!
                    </Alert>
                  )}
                  {redirect ? <Redirect to="/artists" /> : null}
                  {errorPut && (
                    <Alert color="danger">
                      Something went wrong! Please refresh page and try again!
                    </Alert>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default ArtistsUpdate;
