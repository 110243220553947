import React, { useState, useEffect, useRef } from "react";
import {
    Form,
    FormText,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Row,
    Col,
    Button,
} from "reactstrap";
import Select from "react-select";
import Countries from "../../utils/countriesExtended.json";
import { optStepTwo } from "./userDataFormOpt";
import formValidation from "../../validations/es6/core/Core";
import vat from "./customVATValidator";
import { getCookie } from "../../jwt/_helpers";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { tradeRegistryExportUpload } from "./helperFunctions";

import "./userDataForm.scss";

const companyNameValidators = {
    validators: {
        notEmpty: {
            message: 'Company Name is required'
        },
    }
};

const ceoFirstNameValidators = {
    validators: {
        notEmpty: {
            message: 'CEO First Name is required'
        },
    }
};

const ceoLastNameValidators = {
    validators: {
        notEmpty: {
            message: 'CEO Last Name is required'
        },
    }
};

const businessStreetValidators = {
    validators: {
        notEmpty: {
            message: 'Street And Number is required'
        },
    }
};

const businessCityValidators = {
    validators: {
        notEmpty: {
            message: 'City is required'
        },
    }
};

const businessZipValidators = {
    validators: {
        notEmpty: {
            message: 'Zip Code is required'
        },
    }
};

const businessCountryValidators = {
    validators: {
        notEmpty: {
            message: 'Country is required'
        },
    }
};

const tradeRegistryExportValidators = {
    validators: {
        notEmpty: {
            message: ''
        },
        file: {
            extension: 'pdf',
            type: 'application/pdf',
            maxSize: 5 * 1024 * 1024, // 5 MB
            message: 'Please upload a valid PDF file (max size 5 MB).'
        }
    }
};

const tradeRegistryExportURLValidators = {
    validators: {
        notEmpty: {
            message: 'Trade Registry Export is required'
        },
    }
};

const tradeRegistryCompanyUrlValidators = {
    validators: {
        notEmpty: {
            message: 'Trade Registry Company URL is required'
        },
    }
};

const euCountryCodes = ["AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "EL", "ES", "FI", "FR", "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PL", "PT", "RO", "SE", "SI", "SK", "GB", "GR"];

const isAnEuCountry = (countryCode) => {
  countryCode = countryCode.toUpperCase();
  return euCountryCodes.includes(countryCode);
};


const StepTwo = ({formData, setFormData, personalCountry, ceoFirstName, ceoLastName, jumpToStep }) => {
    const token = getCookie().token;

    const fvRef = useRef();

    const [countrySelected, setCountrySelected] = useState("");    
    const [countryFieldTouched, setCountryFieldTouched] = useState(false);
    const [isCountryFieldValid, setIsCountryFieldValid] = useState(false);

    const [businessTypeSelected, setBusinessTypeSelected] = useState("");
    const [businessTypeFieldTouched, setBusinessTypeFieldTouched] = useState(false);
    const [isBusinessTypeFieldValid, setIsBusinessTypeFieldValid] = useState(false);
    const [vatCountryCode, setVATCountryCode] = useState("");

    const isEuCountry = isAnEuCountry(vatCountryCode);

    const [fileUploadDone, fileUploadDoneSET] = useState(false);
    const [fileUploadInProgress, fileUploadInProgressSET] = useState(false);

    const businessTypeOptions = [
        { value: 0, label: "Sole proprietorship"},
        { value: 1, label: "Limited liability company"},
        { value: 2, label: "Partnership organized"},
        { value: 3, label: "Public limited company by shares"},
        // { value: 4, label: "Other" },
    ];

    const revalidateField = (name) => {
        if(fvRef.current) {
            let fv = fvRef.current;
            clearValidationErrors("businessInfoForm", name);
            fv.revalidateField(name);
        }
    }

    const addCompanyValidators = () => {
        if(fvRef.current) {
          let fv = fvRef.current;
          fv.addField("companyName", companyNameValidators);
          fv.addField("ceoFirstName", ceoFirstNameValidators);
          fv.addField("ceoLastName", ceoLastNameValidators);
          fv.addField("businessStreet", businessStreetValidators);
          fv.addField("businessCity", businessCityValidators);
          fv.addField("businessZip", businessZipValidators);
          fv.addField("businessCountry", businessCountryValidators);
          fv.addField("tradeRegistryExport", tradeRegistryExportValidators);
          fv.addField("tradeRegistryExportURL", tradeRegistryExportURLValidators);
          fv.addField("tradeRegistryCompanyUrl", tradeRegistryCompanyUrlValidators);
        }
      }
    
      const removeCompanyValidators = () => {
        if(fvRef.current) {
          let fv = fvRef.current;
          fv.removeField("companyName");
          fv.removeField("ceoFirstName");
          fv.removeField("ceoLastName");
          fv.removeField("businessStreet");
          fv.removeField("businessCity");
          fv.removeField("businessZip");
          fv.removeField("businessCountry");
          fv.removeField("tradeRegistryExport");
          fv.removeField("tradeRegistryExportURL");
          fv.removeField("tradeRegistryCompanyUrl");
        }
      }

    const clearValidationErrors = (formName, validatorName) => {
        let formID = document.getElementById(formName);
        if(formID) {
        const messages = [].slice.call(formID.querySelectorAll('[data-field="' + validatorName + '"][data-validator]'));
        messages.forEach((messageEle) => {
            messageEle.style.display = 'none';
        });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
    
      const handleCountryChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            businessCountry: e
        }));
        revalidateField("vatNumber");
        revalidateField("businessCountry");
      }
    
      const handleBusinessTypeChange = (e) => {
        if(e.value !== formData.businessType.value)
        {
            setFormData(prevState => ({
                ...prevState,
                businessType: e
            }));
            if(e.value === 0) {
                removeCompanyValidators();
            }
            revalidateField("businessType");
            revalidateField("vatNumber");
        }
      }

      const handleTradeRegistryExportChange = (fileUrl) => {
        fileUploadInProgressSET(false);
        fileUploadDoneSET(true);
        setFormData(prevState => ({
            ...prevState,
            tradeRegistryExport: fileUrl
        }));
        revalidateField("tradeRegistryExport");
        revalidateField("tradeRegistryExportURL");
      }

      useEffect(() => {
        let formID = document.getElementById("businessInfoForm");
    
        let fv = formValidation(formID, optStepTwo);

        fv.on("core.form.validating", e => {
            if(!countryFieldTouched) {
                setCountryFieldTouched(true)
            }
            if(!businessTypeFieldTouched) {
                setBusinessTypeFieldTouched(true)
            }
        })
        .on("core.element.validated", e => {
            const item = e.field;
    
            if(item === 'businessCountry') {
              if(!e.valid) {
                setIsCountryFieldValid(false)
              } else {
                setIsCountryFieldValid(true)
              }
            }

            if(item === 'businessType') {
                if(!e.valid) {
                    setIsBusinessTypeFieldValid(false)
                } else {
                    setIsBusinessTypeFieldValid(true)
                }
            }

            if (item === 'tradeRegistryExport' && e.valid) {
                const file = e.element.files[0];
                if (file && !fileUploadDone) {
                    fileUploadInProgressSET(true);
                    revalidateField("tradeRegistryExportURL");
                    tradeRegistryExportUpload(file, token).then(r => handleTradeRegistryExportChange(r))
                }
            }
        
            if (e.valid) {
                const messages = [].slice.call(formID.querySelectorAll('[data-field="' + e.field + '"][data-validator]'));
                messages.forEach((messageEle) => {
                  messageEle.style.display = 'none';
                });
                return;
              }
            }
        )
        .on("core.validator.validated",  e => {
            let offset = e.result.valid? 0: 1;
            const messages = [].slice.call(formID.querySelectorAll('[data-field="' + e.field + '"][data-validator]'));
            for(let i = 0; i < messages.length - offset; i++) {
                const messageEle = messages[i];
                messageEle.style.display = 'none';
            }
        })
        .on('core.form.valid', async e => {
            jumpToStep(3);
        });
        
        fv.registerValidator("vat", vat);

        fvRef.current = fv;

      }, [formData, fileUploadDone])


      useEffect(() => {
        if(formData.businessCountry === null) {
            setCountrySelected('');
        } else {
            setCountrySelected('yes');
        }

        if(countryFieldTouched) {
            revalidateField("businessCountry")
        }

        if(formData.businessType.value === -1) {
            setBusinessTypeSelected('');
        } else {
            setBusinessTypeSelected('yes');
        }

        if(businessTypeFieldTouched) {
            revalidateField("businessType")
        }

        if(formData.businessType.value !== 0 && formData.businessType.value !== -1) {
            addCompanyValidators();
        }

        if(formData.businessType.value === 0) {
            let personalCountryCode = personalCountry? personalCountry.toUpperCase(): "";
            setVATCountryCode(personalCountryCode);
        } else {
            let businessCountryCode = formData.businessCountry ? formData.businessCountry.value.toUpperCase() : "";
            setVATCountryCode(businessCountryCode);
        }
      }, [formData])


      useEffect(() => {
        if(formData.businessType.value === -1) {
            addCompanyValidators();
        }

        if(formData.businessType.value === 0 || formData.businessType.value === -1) {
            let personalCountryCode = personalCountry? personalCountry.toUpperCase(): "";
            setVATCountryCode(personalCountryCode);
        } else {
            let businessCountryCode = formData.businessCountry ? formData.businessCountry.value.toUpperCase() : "";
            setVATCountryCode(businessCountryCode);
        }
      }, [])
      

    return (
        <div className="step step2 mt-5">
            <Row className="justify-content-md-center">
                <Col lg="6">
                    <Form id="businessInfoForm" className="form-horizontal mt-2">
                        <FormGroup className="content form-block-holder">
                            <Label className="control-label"><b>Business Type</b></Label>
                            <Select
                                components={{ IndicatorSeparator:() => null }}
                                options={businessTypeOptions}
                                name="bizType"
                                placeholder="Select Business Type"
                                styles={{
                                menu: styles => ({ ...styles, zIndex: 10 }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: businessTypeFieldTouched ? (isBusinessTypeFieldValid ? "#2dce89": "#f62d51"): baseStyles.borderColor,
                                    boxShadow: state.isFocused || state.isHovered 
                                    ? (isCountryFieldValid ? "0 0 0 0.2rem rgba(45, 206, 137, 0.25)" : "0 0 0 0.2rem rgba(246, 45, 81, 0.25)") 
                                    : "0 0 0 1px #e9ecef",
                                    borderRadius: "2px",
                                    }),
                                }}
                                onFocus={e => {
                                    setBusinessTypeFieldTouched(true)
                                    revalidateField("businessType");
                                }}
                                onChange={handleBusinessTypeChange}
                                value={formData.businessType}
                            />
                            <input className="hiddenInput" type="text" id="businessType" name="businessType" value={businessTypeSelected} readOnly/>
                            <input className="hiddenInput" type="text" id="isSoleProprietor" name="isSoleProprietor" value={(formData.businessType.value === 0)? "yes": "no"} readOnly/>
                        </FormGroup>
                        

                        {(formData.businessType?.value !== 0) && (
                            <>
                                <FormGroup className="content form-block-holder">
                                    <Label className="control-label"><b>Company Name</b></Label>
                                    <Input type="text" name="companyName" value={formData.companyName} onChange={handleChange} onFocus={() => {revalidateField("companyName")}} />
                                    <FormText color="muted">
                                        Company name should be consistent with what was initially submitted in Stripe. Only change if it differs from what was initially submitted.
                                    </FormText>
                                </FormGroup>

                                <FormGroup className="content form-block-holder">
                                    <Label className="control-label"><b>Street and Number</b></Label>
                                    <Input type="text" name="businessStreet" value={formData.businessStreet} onChange={handleChange} onFocus={() => {revalidateField("businessStreet")}} />
                                </FormGroup>

                                <FormGroup className="content form-block-holder">
                                    <Label className="control-label"><b>City</b></Label>
                                    <Input type="text" name="businessCity" value={formData.businessCity} onChange={handleChange} onFocus={() => {revalidateField("businessCity")}} />
                                </FormGroup>

                                <FormGroup className="content form-block-holder">
                                    <Label className="control-label"><b>ZIP Code</b></Label>
                                    <Input type="text" name="businessZip" value={formData.businessZip} onChange={handleChange} onFocus={() => {revalidateField("businessZip")}} />
                                </FormGroup>

                                <FormGroup className="content form-block-holder">
                                    <Label className="control-label"><b>Country</b></Label>
                                    <Select
                                        components={{ IndicatorSeparator:() => null }}
                                        options={Countries}
                                        id="country"
                                        name="country"
                                        placeholder="Select Country"
                                        styles={{
                                        menu: styles => ({ ...styles, zIndex: 10 }),
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: countryFieldTouched ? (isCountryFieldValid ? "#2dce89": "#f62d51"): baseStyles.borderColor,
                                            boxShadow: state.isFocused || state.isHovered 
                                            ? (isCountryFieldValid ? "0 0 0 0.2rem rgba(45, 206, 137, 0.25)" : "0 0 0 0.2rem rgba(246, 45, 81, 0.25)") 
                                            : "0 0 0 1px #e9ecef",
                                            borderRadius: "2px",
                                            }),
                                        }}
                                        onFocus={e => {
                                            setCountryFieldTouched(true)
                                            revalidateField("businessCountry");
                                        }}
                                        onChange={handleCountryChange}
                                        value={formData.businessCountry}
                                    />
                                    <input className="hiddenInput" type="text" id="businessCountry" name="businessCountry" value={countrySelected} readOnly/>
                                </FormGroup>

                                <FormGroup>
                                    <input className="hiddenInput" type="text" id="countryCode" name="countryCode" value={vatCountryCode} readOnly/>
                                </FormGroup>

                                <FormGroup>
                                    <Label className="control-label"><b>Legal Representative / CEO First Name</b></Label>
                                    <Input type="text" name="ceoFirstName" disabled={true} value={ceoFirstName} onChange={handleChange} onFocus={() => {revalidateField("ceoFirstName")}} />
                                </FormGroup>

                                <FormGroup>
                                    <Label className="control-label"><b>Legal Representative / CEO Last Name</b></Label>
                                    <Input type="text" name="ceoLastName" disabled={true} value={ceoLastName} onChange={handleChange} onFocus={() => {revalidateField("ceoLastName")}} />
                                </FormGroup>

                                <FormGroup className="content form-block-holder">
                                    <Label className="control-label"><b>Trade Registry Export</b></Label>
                                    <CustomInput
                                        type="file"
                                        id="tradeRegistryExport"
                                        name="tradeRegistryExport"
                                        onFocus={() => {revalidateField("tradeRegistryExport")}}
                                        accept=".pdf"
                                    />
                                    <div className="tradeRegistryExportUploadContainer">
                                        <div className="tradeRegistryExportUpload" id={`uploadProgress_utre`}></div>
                                        <div className="tradeRegistryExportUploadProgressDiv">
                                        <span id={`uploadProgress_text_utre`}></span>
                                        </div>
                                    </div>
                                    <input className="hiddenInput" id="tradeRegistryExportURL" name="tradeRegistryExportURL" type="text" value={(fileUploadInProgress || fileUploadDone) ? "yes" : ""} onChange={(e) => e}/>
                                    <FormText color="muted">
                                        Please provide an official trade registry export of your company. PDF only, max file size 5 MB. If you need help with finding your trade registry export, please contact the registry in your country. An unofficial list of the registries can be found <Link to={{pathname: "https://docs.google.com/document/d/1kpJez_rxoMT9lx8RvHX2kJeAaHcfXWwzCXkHtEKPKqs/edit"}} target="_blank" rel="noopener noreferrer"><i className="fa fa-external-link-alt fa-lg store-link-icon" style={{color: '#4f5467'}} aria-hidden="true"></i></Link>.
                                    </FormText>
                                </FormGroup>

                                <FormGroup className="content form-block-holder">
                                    <Label className="control-label"><b>Trade Registry Company URL</b></Label>
                                    <Input
                                        type="text"
                                        name="tradeRegistryCompanyUrl"
                                        value={formData.tradeRegistryCompanyUrl}
                                        onChange={handleChange}
                                        onFocus={() => {revalidateField("tradeRegistryCompanyUrl")}}
                                    />
                                    <FormText color="muted">
                                        Please provide your company URL at your country's trade registry. If your registry does not support this, please insert a registry URL only.
                                    </FormText>
                                </FormGroup>
                            </>
                        )}

                        <FormGroup>
                            <Label className="control-label"><b>VAT Number</b></Label>
                            <Input type="text" name="vatNumber" value={isEuCountry? formData.vatNumber: ""} onChange={handleChange} onFocus={() => {revalidateField("vatNumber")}} disabled={!isEuCountry} />
                        </FormGroup>

                        <FormGroup className="content form-block-holder">
                            <Label className="control-label"><b>Tax Number</b></Label>
                            <Input type="text" name="taxNumber" value={formData.taxNumber} onFocus={() => {revalidateField("taxNumber")}} onChange={handleChange} />
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
            
            <Button 
                className="btn btn-prev btn-primary pull-left prevButton"
                style={{color: "#ffffff", backgroundColor: "#000a60"}}
                disabled={fileUploadInProgress}
                onClick={() => {
                    jumpToStep(1)
                }}
            >
                Previous
            </Button>

            <Button
                className="btn btn-next btn-primary pull-right"
                style={{color: "#ffffff", backgroundColor: "#000a60"}}
                type="submit"
                disabled={fileUploadInProgress}
                onClick={() => {
                    if(fvRef.current) {
                        fvRef.current.validate();
                    }
                }}
            >
                Next
            </Button>

        </div>
    );
};

export default StepTwo;